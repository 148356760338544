@import "base";
@import "~compass-mixins/lib/compass/utilities/general/clearfix";
@import "~compass-mixins/lib/compass/typography/links/link-colors";
@import "~compass-mixins/lib/compass/css3/inline-block";
@import "~compass-mixins/lib/compass/css3/box-sizing";
@import "~compass-mixins/lib/compass/css3/border-radius";
@import "~compass-mixins/lib/compass/css3/box-shadow";
@import "~compass-mixins/lib/compass/css3/transition";
@import "~compass-mixins/lib/compass/css3/opacity";
@import "~compass-mixins/lib/compass/css3/images";

.reveal-modal-bg {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: none;
  top: 0;
  left: 0;
  background: url("~images/overlay.png");
  background-image: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.725));
  @include opacity(0);
  -webkit-backface-visibility: hidden;
}

.reveal-modal-loader {
  position: fixed;
  background: rgba(0, 0, 0, 0.5) url("~images/reveal-loader.gif") center 20px no-repeat;
  display: none;
  color: #fff;
  padding: 70px 20px 15px;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  z-index: 101;
  @include border-radius(10px);
  @include opacity(0);
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-backface-visibility: hidden;
}

.uploader-bg {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: none;
  top: 0;
  left: 0;
  background: url("~images/overlay.png");
  background-image: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.725));
  @include opacity(0);
  -webkit-backface-visibility: hidden;
}

.uploader-progress {
  position: fixed;
  background: rgba(0, 0, 0, 0.7) url("~images/reveal-loader.gif") center 20px no-repeat;
  display: none;
  color: #fff;
  padding: 70px 20px 15px;
  top: 50%;
  left: 50%;
  margin-top: -80px;
  margin-left: -150px;
  z-index: 101;
  @include border-radius(10px);
  @include opacity(0);
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-backface-visibility: hidden;
  min-width: 300px;
  text-align: center;
  .progressbar-wrapper {
    height: 10px;
    @include border-radius(5px);
    border: 1px solid #eee;
    margin-top: 10px;
    padding: 1px;
  }
  .progressbar {
    background: #fff;
    height: 6px;
    @include border-radius(3px);
    @include single-transition(width, 0.3s, ease-out);
  }
  .progress-info {
    margin-top: 5px;
    text-transform: none;
  }
}

.reveal-modal .close-reveal-modal {
  font-size: 22px;
  line-height: 0.5;
  position: absolute;
  top: 8px;
  right: 11px;
  color: #aaa;
  text-shadow: 0 -1px 1px rbga(0, 0, 0, 0.6);
  font-weight: bold;
  cursor: pointer;
}

.nimbu-modal {
  -webkit-backface-visibility: hidden;
  @include opacity(0);
  top: 50%;
  left: 50%;
  position: fixed;
  z-index: 101;
  @include border-radius(8px);
  @include box-shadow(0 0 20px rgba(0, 0, 0, 0.6), inset 0 0 2px rgba(255, 255, 255, 0.6));
  background-color: #737f83;
  padding: 5px;

  &:focus {
    outline: none;
  }

  &.is-replaced {
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }

  .container {
    margin-left: -25px;
    margin-right: -25px;
  }

  .row {
    min-width: 0px;

    .columns {
      input[type="text"] {
        min-width: 0px;
      }
    }
  }

  .subheader {
    border-bottom: 1px solid #e6e6e6;
    margin: 0 0 30px;
    padding: 0 0 4px 0;
    color: #000;
    font-size: 13px;
    font-weight: 700;
  }

  .nimbu-modal-header {
    position: relative;
    @include border-top-radius(5px);
    background-image: linear-gradient(#3e4553, #434750);
    border-top: 1px solid #787b7d;
    h1 {
      color: #ffffff;
      font-family: $primaryFont;
      letter-spacing: -0.02em;
      font-size: 16px;
      line-height: 42px;
      font-weight: 500;
      padding: 0px 20px;
      text-shadow: 0 -1px 0 #000;
      margin: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      em {
        font-style: normal;
        color: lighten(#005279, 10%);
      }
    }
    .close {
      position: absolute;
      border-radius: 3px 3px 3px 3px;
      color: #404059;
      color: rgba(255, 255, 255, 0.25);
      font-family: Verdana, Tahoma, sans-serif;
      font-size: 15px;
      font-weight: bold !important;
      height: 15px;
      line-height: 15px;
      margin-top: -9px;
      padding: 0 0 0 2px;
      position: absolute;
      right: 10px;
      text-decoration: none;
      top: 50%;
      width: 17px;
      @include single-transition(color, 0.3s, ease-out, 0s);
      &:hover {
        color: lighten(#404059, 30%);
        color: rgba(255, 255, 255, 0.55);
      }
    }
  }
  .info {
    line-height: 18px;
    font-size: 11px;
    padding: 10px 20px;
    color: #7c4f00;
    background: #fffbe5;
    margin-bottom: 15px;
    border-bottom: 1px solid #ece52f;
  }
  .body.vertical {
    .info {
      margin: -30px -25px 30px -25px;
    }
  }

  select.selectizable {
    height: 36px;
  }
  form,
  .nimbu-body {
    background: #fff;
    @include border-bottom-radius(5px);
    margin: 0px;
    min-width: 300px;
    @media #{$media-medium-up} {
      min-width: 400px;
    }
    input[type="text"] {
      width: 100%;
      min-width: 250px;
    }
    .inline {
      input[type="text"] {
        width: 100%;
        min-width: 0px;
      }
    }
    p:last-child {
      margin-bottom: 0;
    }
    .large {
      min-width: 500px;
    }
    .wide,
    &.wide {
      input[type="text"] {
        width: 100%;
      }
    }
    label {
      margin-top: 0px;
    }
    .title-field input {
      height: 26px;
      max-height: 26px;
    }
    .input {
      margin: 0px 0px 15px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .fifty-fifty-wrapper {
      @include pie-clearfix;
      overflow: visible;
      .input {
        width: 48%;
        margin-right: 4%;
        &:last-child {
          margin-right: 0px;
        }
      }
      &:last-child {
        .input {
          margin-bottom: 0px;
        }
      }
    }
    .body {
      padding: 30px 25px;
      @include pie-clearfix;
      @include box-sizing(content-box);

      &.no-margin {
        padding: 0px;
      }

      &.wide {
        min-width: 900px;
      }
    }
    .hint {
      margin-top: 2px;
    }
    .actions {
      margin: 0px;
      width: 100%;
    }
    h4 {
      border-bottom: 1px solid #e6e6e6;
      //margin: 15px 20px 15px;
      padding: 0 0 4px 0;
      color: #000;
      font-size: 13px;
      font-weight: 700;
      span.note {
        font-size: 11px;
        color: #aaa;
        float: right;
        font-weight: normal;
      }
    }
  }
}

.json-viewer-wrapper {
  position: relative;
  margin-right: 0px;
  padding-right: 30px;

  .json-viewer {
    margin-right: -30px;
  }
}

.copy-textarea {
  position: relative;
  margin-bottom: 15px;
  width: 450px;
  textarea {
    @include box-sizing(border-box);
    background: #fafafa;
    border: 1px solid #cccccc;
    padding: 7px 30px 7px 7px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    font-family: "Courier New", Courier, monospace;
    &::-moz-selection {
      background: #ddd;
      color: #000;
      text-shadow: none;
    }
    &::selection {
      background: #ddd;
      color: #000;
      text-shadow: none;
    }
  }
  .clippy {
    position: absolute;
    top: 7px;
    right: 7px;
    display: block;
    overflow: hidden;
    width: 14px;
    height: 14px;
  }
}
.theme-selector {
  margin-bottom: 15px;
}

::-webkit-validation-bubble {
  color: #fff;
}
::-webkit-validation-bubble-arrow {
  background: #1d1f20;
  border-color: #393b3f #323337 #2a2b2d #2d2f31;
  -webkit-box-shadow: none;
}

::-webkit-validation-bubble-message {
  background-color: #1d1f20;
  padding: 10px;
  font-family: $primaryFont;
  font-weight: 300;
  color: #797b71;
  border-color: #393b3f #323337 #2a2b2d #2d2f31;
  box-shadow: none;
}

$modal-border-radius: 8px;

#export_orders {
  select {
    width: 100%;
  }
  .what {
    margin-bottom: 15px;
  }
  label {
    display: block;
  }
  .subrange {
    margin: 10px 10px 10px 25px;
    padding: 15px;
    background: #f9f9f9;
    border: 1px solid #eaeaea;
    label {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      & > span {
        display: inline-block;
        margin-bottom: 10px;
        font-weight: bold;
      }
    }
  }
  .date-select {
    padding-left: 25px;
    @include clearfix;
    .day,
    .month,
    .quarter,
    .year {
      float: left;
      margin-right: 10px;
    }
  }
  .subrange.date {
    .date-select {
      padding-left: 0px;
    }
  }
  .winegistics,
  .detailed {
    padding-top: 25px;
  }
  .errors {
    color: #b22816;
    text-align: center;
    margin-bottom: 15px;
  }
}

#import_site {
  .nimbu-body {
    max-width: 500px;
    text-align: center;
  }
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.page-settings-modal {
  .body, h1 {
    max-width: 350px;
  }

  form.nimbu .section-content .input.no-margin-bottom {
    margin-bottom: 0px;
  }

  fieldset {
    position: relative;
    padding-top: 25px;
    margin-top: 25px;
    border-top: 1px dotted #404040;

    & + fieldset {
      margin-top: 25px;
    }

    legend {
      padding: 0px 7px;
      margin: 0 auto;
      font-weight: 500;
      color: #999;
    }
  }
}

.nimbu-modal.confirm-modal {
  max-width: 375px;

  .nimbu-body {
    min-width: 0px;

    input[type="text"] {
      display: block;
      width: 100%;
    }

    p {
      font-size: 15px;
      line-height: 20px;

      &.verify-label {
        color: #555;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 10px;
      }
    }
  }
}

.edit-address-modal {
  min-width: 450px;
  .iti {
    display: block;
  }
}

.dropzone-wrapper {
  display: flex;
  min-height: 360px;
  max-height: 360px;

  .dropzone {
    flex-grow: 1;
  }
}

.import-from-excel {
  --color-excel: #1d6f42;
  --color-icon: #222;

  .subtitle {
    margin-bottom: 8px; 
    font-size: 15px;
    font-weight: 700;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--color-border-weak);
    color: var(--color-text);
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .deselect-unmapped {
    margin-left: auto;
    font-size: 13px;
    font-weight: 500;
    opacity: 0.5;
    color: var(--color-text);
    text-decoration: none;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
      border-bottom: 1px dashed var(--color-text);
    }
  }

  .titles {
    display: flex;
    align-items: center;

    .source, .target {
      flex: 1;
      width: 50%;
      font-size: 13px;
      font-weight: 700;
      color: var(--color-text);
      padding: 8px 0px
    }
    .source {
      padding-right: 16px;
    }
  }

  .mapping {
    display: flex;
    align-items: center;

    .source, .target {
      flex: 1;
      width: 50%;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 500;
      color: var(--color-text);
      padding: 8px 0px
    }

    .select-wrapper {
      flex-grow: 1;
      flex-shrink: 1;
    }

    select {
      position: relative;
      border: 1px solid var(--color-border);
      border-radius: 6px;
      box-shadow: 0px 2px 4px rgba(55 55 55 / 8%), 0px 1px 1px rgba(0 0 0 / 12%);
      color: var(--color-text);
      font-size: 12px;
      font-weight: 500;
      font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
      line-height: 1.15;
      padding: 6px 19px 6px 13px;
      appearance: none;
      height: 32px;
      background-image: url("data:image/svg+xml;utf8,%3Csvg%20width%3D'9'%20height%3D'6'%20viewBox%3D'0%200%209%206'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M2%202L4.5%204.14286L7%202'%20stroke%3D%22%236e7487%22%20stroke-width%3D'1.5'%20stroke-linecap%3D'square'%2F%3E%3C%2Fsvg%3E");
      background-color: #fff;
      background-position: right 8px top 50%;
      background-repeat: no-repeat;
      user-select: none;
      overflow: visible;
      flex: 1 1 0%;
      width: 100%;
    }

    .source {
      padding-right: 16px;
    }

    .icon {
      margin-left: auto;
    }

    .check {
      height: 20px;
      margin-right: 8px;
      --color-icon: #ccc;
    }

    &.importing {
      .deselected {
        display: none;
      }
      .selected {
        display: flex;
      }

      .source {
        opacity: 0.8;
      }

      &.target-selected {
        .source {
          opacity: 1;
        }

        .check {
          --color-icon: var(--color-excel);
        }
  
      }
    }

    &.skipped {
      .source {
        opacity: 0.3;
      }
      .target {
        opacity: 0.5;
      }
      .deselected {
        display: flex;
      }
      .selected {
        display: none;
      }
    }

    .add, .deselect {
      cursor: pointer;
      margin-left: 8px;
    }
  }

  .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 640px;
    border: 4px dashed var(--color-border-weak);

    &.dz-started {
      opacity: 0.5;
    }

    * {
      pointer-events: none;
    }

    h1 {
      font-weight: 600; 
      font-size: 20px; 
      text-align: center; 
      margin-top: 16px; 
      margin-bottom: 0px; 
      color: var(--color-text);
    }

    p {
      font-weight: 500;
      font-size: 14px;
      color: var(--color-text-secondary);
      text-align: center;
      margin-top: 8px;
    }
  }
}

.nimbu-modal form.import-from-excel {
  min-width: 550px;
}

.add-oauth-app {
  .info {
    margin-bottom: 0;
  }

  h3 {
    margin: 0 0 15px;
    font-size: 18px;
    font-weight: 500;

    strong, b {
      font-weight: 800;
    }
  }

  fieldset {
    position: relative;
    border: 1px solid #dee0e4;
    background: #f9fafb;
    border-radius: 8px;
    padding: 15px;
    
    legend {
      background: #dce0e3;
      border-radius: 10px;
      font-size: 13px;
      padding: 2px 10px;
    }
  }

  dl {
    dt {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 2px;
    }
    dd {
      font-size: 14px;
      margin-bottom: 15px;
      color: #616a75;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

#new_product_vendor, #new_product_type, #edit_product_vendor, #edit_product_type {
  max-width: 95%;
  
  @media #{$media-medium-up} {
    max-width: 800px;
    min-width: 800px;
  }  
}

.webhook-modal {
  max-width: 95%;
  
  @media #{$media-medium-up} {
    max-width: 500px;
    min-width: 500px;
  }
}
