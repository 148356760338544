@import "base";
@import "~compass-mixins/lib/compass/css3/user-interface";
@import "~compass-mixins/lib/compass/utilities/general/clearfix";
@import "~compass-mixins/lib/compass/typography/links/link-colors";
@import "~compass-mixins/lib/compass/css3/inline-block";
@import "~compass-mixins/lib/compass/css3/box-sizing";
@import "~compass-mixins/lib/compass/css3/border-radius";
@import "~compass-mixins/lib/compass/css3/box-shadow";
@import "~compass-mixins/lib/compass/css3/transition";
@import "~compass-mixins/lib/compass/css3/opacity";
@import "~compass-mixins/lib/compass/css3/images";
@import "~compass-mixins/lib/compass/css3/transform";

@mixin separator {
  position: relative;
  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    bottom: 5px;
    width: 1px;
    right: 0px;
  }
  &:after {
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.13),
      rgba(255, 255, 255, 0)
    );
  }
  &:before {
    right: 1px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  }
}
@mixin no-separator {
  position: relative;
  &:after,
  &:before {
    display: none;
  }
}

#globalheader {
  display: block;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: $mainBarWidth;
  background: #353c48;
  @include box-shadow(0 2px 3px rgba(0, 0, 0, 0.4));
  @include pie-clearfix;
  background-image: linear-gradient(to left, #39404d, #262c37);
  border-left: 1px solid #535d6d;
  border-right: 1px solid #2e343e;
  z-index: 23;
  .site-info {
    position: absolute;
    bottom: 0px;
    left: 0;
    height: $mainBarWidth;
    width: 650px;
    margin-left: 48px;
    line-height: $mainBarWidth;
    -webkit-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .signout-link {
    @include link-colors(#afb1b6, #f6f7f7, #afb1b6, #afb1b6, #f6f7f7);
    font-size: 125%;
    display: block;
    z-index: 2;
    position: absolute;
    left: 0px;
    text-align: center;
    width: $mainBarWidth;
    bottom: 23px;
  }
  .site-name {
    height: $mainBarWidth;
    line-height: $mainBarWidth;
    padding-left: 73px;
    padding-right: 30px;
    color: #ccc;
    font-size: 18px;
    font-weight: 600;
  }
  .links {
    position: absolute;
    top: 30px;
    left: 0;
    width: $mainBarWidth;
    z-index: 1;
    a {
      @include link-colors(#afb1b6, #f6f7f7, #afb1b6, #afb1b6, #f6f7f7);
      text-align: center;
      display: block;
      margin: 0 0 15px;
    }
  }
  .logged-in {
    a {
      font-size: 13px;
      height: $mainBarWidth;
      line-height: $mainBarWidth;
      @include link-colors(#afb1b6, #f6f7f7, #afb1b6, #afb1b6, #f6f7f7);
      @include single-transition(color, 0.3s, ease-out);
      &:hover {
        @include single-transition(color, 0.3s, ease-out);
      }
    }
  }
  .maintenance-mode-enabled {
    position: absolute;
    top: 0;
    left: 0px;
    bottom: 0;
    width: 4px;

    background: repeating-linear-gradient(45deg, #e2cf53, #e2cf53 10px, #b6aa5c 10px, #b6aa5c 20px);
  }
}
#globalnav {
  @include pie-clearfix;
  overflow: visible;
  line-height: 48px;
  li.logo {
    float: left;
    display: block;
    padding: 6px 5px 6px 20px;
  }
  & > ul {
    overflow: visible;
    & > li {
      & > a {
        float: left;
        display: block;
        padding: 0px 15px;
        @include single-transition(color, 0.3s, ease-out, 0s);
        @include link-colors(#c4d3e8, #fff, #fff, #c4d3e8, #fff);
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.9);
        text-decoration: none;
        font-weight: bold;
      }
      &.active {
        a {
          color: #fff;
        }
      }
      &.home {
        & > a {
          font-size: 22px;
          font-weight: 700;
          color: #fff;
          padding-left: 0;
          @include separator;
        }
      }
    }
  }
  #accountnav {
    @include pie-clearfix;
    ul {
      float: right;
      padding: 9px 12px;
    }
    li {
      float: left;
      font-size: 11px;
      &.identifier {
        @include separator;
        color: #d1e1ea;
        span {
          color: #fff;
        }
        margin-right: 15px;
      }
      a {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.8);
        padding: 0 10px 0 33px;
        font-weight: 400;
        letter-spacing: -0.03em;
        &.help {
          background: transparent url("~images/help.png") 10px center no-repeat;
        }
        &.logout {
          background: transparent url("~images/signout.png") 12px center no-repeat;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
    .dropdown-menu {
      float: left;
      padding: 0px;
      margin-left: -50%;
      li {
        float: none;
      }
      a {
        color: #333;
        padding: 6px !important;
      }
    }
  }
  .caret {
    border-top-color: #fff;
  }
}
#loading {
  display: none;
  color: #afb1b6;
  font-size: 11px;
  height: 20px;
  span {
    display: block;
    color: #afb1b6;
  }
}
#spinner {
  display: none;
  position: fixed;
  width: 35px;
  height: 35px;
  left: $sidebarWidth + $mainBarWidth + 22;
  top: 18px;
  background: #fff;
  z-index: 99;
  .wheel {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
body.staff-mode {
  #spinner {
    top: $staffWidth + 18px;
  }
}
#globalfooter {
  border-top: 1px solid #afb6bb;
  background: #ebecec;
  background-image: linear-gradient(to bottom, #ebecec, #dfe1e2);
  @include box-shadow(inset 0 1px 1px rgba(255, 255, 255, 0.9));
  .row {
    padding: 10px 40px;
    font-size: 12px;
  }
}
#sidebarbg {
  position: fixed;
  top: 0px;
  left: $mainBarWidth;
  bottom: 0px;
  width: $sidebarWidth;
  z-index: 21;
}
body.staff-mode {
  #sidebarbg {
    top: $staffWidth;
  }
}
#sidebar {
  position: fixed;
  left: $mainBarWidth;
  width: $sidebarWidth;
  top: 0;
  bottom: 0;
  overflow: auto;
  @include user-select(none);
  border-right: 1px solid #b9c1d0;
  background: #f1f3f5;
  z-index: 22;
  padding-top: 25px;
  transform: translate3d(0, 0, 0);
  section {
    padding: 0 0 25px;
    &.settings-link {
      margin-top: 10px;
      padding-bottom: 25px;
      padding-left: 25px;
      a {
        font-size: 11px;
        color: #042333;
        background-color: #ddeaef;
        font-weight: 500;
        @include border-radius(10px);
        padding: 5px 10px 5px 7px;
        text-shadow: 0 1px 0 #fff;
        i:before {
          color: #516065;
        }
        &:hover {
          background-color: #d3e4ea;
        }
      }
    }
  }
  i {
    color: #8496a4;
    display: inline-block;
    width: 20px;
    top: 1px;
    text-align: center;
    position: relative;
    margin-right: 8px;
    font-size: 100%;
    --fa-primary-opacity: 1;
    --fa-primary-color: #8496a4;
    --fa-secondary-opacity: 1;
    --fa-secondary-color: #b0bbc4;

    &.duo-inverted {
      --fa-primary-opacity: 1;
      --fa-primary-color: #b0bbc4;
      --fa-secondary-opacity: 1;
      --fa-secondary-color: #8496a4;
    }
  }
  .backlink {
    a {
      display: block;
      text-align: center;
      font-size: 11px;
      color: #222;
    }
  }
  .side-nav-title {
    padding: 0 30px;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #8592a6;
    font-size: 11px;
    font-weight: 700;
  }
  .side-nav {
    li {
      position: relative;
      margin: 0px;
      overflow: hidden;
      white-space: nowrap;
      a {
        margin: 0px;
        padding: 0px 30px;
        font-weight: 500;
        font-size: 13px;
        line-height: 28px;
        text-shadow: 0 1px 0 rgba(#fff, 0.7);
        display: block;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        @include link-colors(#373e4b, #000, #373e4b, #373e4b, #000);
        span.open {
          position: absolute;
          right: 15px;
          background: #f7f8fb;
          color: #657880;
          font-size: 12px;
          line-height: 12px;
          font-weight: 600;
          padding: 3px 7px;
          margin: 0;
          top: 6px;
          display: inline-block;
          border-radius: 3px;
        }
        &:hover {
          background-color: #e6edf3;
          border-color: #bcd3e9;
          color: #00598d;
          i {
            color: #00598d;

            --fa-primary-color: #00598d;
            --fa-secondary-color: #4687ad;

            &.duo-inverted {
              --fa-primary-color: #4687ad;
              --fa-secondary-color: #00598d;
            }

          }
          span.open {
            background: #f7f9fa;
            color: #657880;
          }
        }
      }
      &.active a {
        border-top: 1px solid #3189e4;
        border-bottom: 1px solid #1d56a5;
        background-image: linear-gradient(to bottom, #60aded, #3c94e6 5%, #2d78cb);
        @include box-shadow(#60aded 0 1px 0 inset, rgba(17, 46, 97, 0.15) 0 1px 0);
        text-shadow: 0 -1px 0px rgba(0, 0, 0, 0.3);
        color: #fff;
        &:before {
          font-weight: normal;
          text-shadow: 0 -1px 0px rgba(0, 0, 0, 0.3);
          color: #fff;
        }
        &:hover i {
          color: #fff;
          --fa-primary-color: #fff;
          --fa-secondary-color: #c2dbf4;
          &.duo-inverted {
            --fa-primary-color: #c2dbf4;
            --fa-secondary-color: #fff;
          }
        }
        span.open {
          background: #155b97;
          color: #fff;
        }
      }
      &.active i {
        color: #fff;
        --fa-primary-color: #fff;
          --fa-secondary-color: #c2dbf4;
          &.duo-inverted {
            --fa-primary-color: #c2dbf4;
            --fa-secondary-color: #fff;
          }
      }
    }
  }
}
#sidebar-gradient {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0;
  width: 2px;
  z-index: 10;
  pointer-events: none;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.075));
}
body.staff-mode {
  #globalheader {
    top: $staffWidth;
  }
  #sidebar {
    top: $staffWidth;
  }
}
#peek {
  display: block;
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  height: $staffWidth;
  color: #aaa;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
  border-top: 1px solid #141414;
  border-bottom: 1px solid #141414;
  z-index: 23;
  box-shadow: 2px 0 3px rgba(0, 0, 0, 1);
  @include pie-clearfix;

  &.production {
    background: #1f1f1f url("~images/production.gif") repeat 0 0;
  }

  &.staging {
    background: #1f1f1f url("~images/staging.gif") repeat 0 0;
  }

  &.development {
    background: #1f1f1f url("~images/development.gif") repeat 0 0;
  }

  a {
    @include link-colors(#afb1b6, #f6f7f7, #afb1b6, #afb1b6, #f6f7f7);
  }

  .wrapper {
    width: auto;
    text-align: center;
  }

  .view {
    float: none;
    display: inline-block;
  }

  .close {
    position: absolute;
    top: 0px;
    right: 15px;
    @include link-colors(#afb1b6, #f6f7f7, #afb1b6, #afb1b6, #f6f7f7);
  }

  .mission-control {
    position: absolute;
    top: 0px;
    left: 15px;
  }

  .bucket {
    &:hover .hidden {
      display: inline !important;
    }
  }
}
