@import 'media-queries';

/* The Grid ---------------------- */
.row {
  width: auto;
  max-width: 100%;
  min-width: $screen-small;
  margin: 0 auto;
  .row {
    width: auto;
    max-width: none;
    min-width: 0;
    margin: 0 (-($column_gutter * 0.5));
    &.narrow {
      //margin: 0 -2%;
      & > .column,
      & > .columns {
        &:first-child {
          padding-left: 25px;
        }
        &:last-child {
          padding-right: 25px;
        }
      }
    }
  }

  &.collapse {
    .column,
    .columns {
      padding: 0;
    }
  }
  .row {
    width: auto;
    max-width: none;
    min-width: 0;
    margin: 0 (-($column_gutter * 0.5));
    &.collapse {
      margin: 0;
    }
  }
}

.column,
.columns {
  float: left;
  min-height: 1px;
  padding: 0 ($column_gutter * 0.5);
  position: relative;
  &.centered {
    float: none;
    margin: 0 auto;
  }
  &.narrow {
    padding: 0 2%;
  }
}

.small-gutters {
  .column,
  .columns {
    padding-left: 5px;
    padding-right: 5px;

    &:first-child {
      padding-left: $column_gutter * 0.5;
    }

    &:last-child {
      padding-right: $column_gutter * 0.5;
    }
  }
}

[class*='column'] + [class*='column']:last-child {
  float: right;
}
[class*='column'] + [class*='column'].end {
  float: left;
}

// Creating .row-# classes
@for $i from 1 through $total_columns {
  .row {
    .#{convert-number-to-word($i)} {
      width: grid-calc($i, $total_columns);
    }
  }
}

// Creating .row.offset-by-# classes
@for $i from 1 through $total_columns - 2 {
  .row {
    .offset-by-#{convert-number-to-word($i)} {
      margin-left: grid-calc($i, $total_columns);
    }
  }
}

// Creating .row.push-# classes
@for $i from 2 through $total_columns - 2 {
  .push-#{convert-number-to-word($i)} {
    left: grid-calc($i, $total_columns);
  }
  .pull-#{convert-number-to-word($i)} {
    right: grid-calc($i, $total_columns);
  }
}

img,
object,
embed {
  max-width: 100%;
  height: auto;
}
img {
  -ms-interpolation-mode: bicubic;
}
#map_canvas img,
.map_canvas img {
  max-width: none !important;
}

/* Nicolas Gallagher's micro clearfix */
.row:before,
.row:after,
.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}
.row:after,
.clearfix:after {
  clear: both;
}
.row,
.clearfix {
  zoom: 1;
}

/* Mobile Grid and Overrides ---------------------- */

//@media #{$media-small} {
//  body { -webkit-text-size-adjust: none; -ms-text-size-adjust: none; width: 100%; min-width: 0; margin-left: 0; margin-right: 0; padding-left: 0; padding-right: 0; }

//  .row { width: auto; min-width: 0; margin-left: 0; margin-right: 0; }
//  .column, .columns { width: auto !important; float: none;  }
//  .column:last-child, .columns:last-child { float: none; }
//  [class*="column"] + [class*="column"]:last-child { float: none; }
//  .column:before, .columns:before, .column:after, .columns:after { content:""; display:table; }
//  .column:after, .columns:after { clear: both; }

//  // Silent Placeholder classes for mobile grid overrides
//  %no-left-margin { margin-left: 0 !important; }
//  %left-auto { left: auto; }
//  %right-auto { right: auto; }

//  @for $i from 1 through $total_columns - 2 {
//    .offset-by-#{convert-number-to-word($i)} { @extend %no-left-margin; }
//  }
//  @for $i from 2 through $total_columns - 2 {
//    .push-#{convert-number-to-word($i)} { @extend %left-auto; }
//    .pull-#{convert-number-to-word($i)} { @extend %right-auto; }
//  }

//  /* Mobile 4-column Grid */
//  @for $i from 1 through $mobile_total_columns {
//    .row {
//      .mobile-#{convert-number-to-word($i)} { width: grid-calc($i, $mobile_total_columns); float: left; padding: 0 ($column_gutter * 0.5);
//        &:last-child { float: right; }
//      }
//    }
//  }
//  @for $i from 1 through $mobile_total_columns - 1 {
//    .push-#{convert-number-to-word($i)}-mobile { left: grid-calc($i, $mobile_total_columns); }
//    .pull-#{convert-number-to-word($i)}-mobile { right: grid-calc($i, $mobile_total_columns); }
//  }
//}

/* Block Grids ---------------------- */

/* These are 2-up, 3-up, 4-up and 5-up ULs, suited
for repeating blocks of content. Add 'mobile' to
them to switch them just like the layout grid
(one item per line) on phones

For IE7/8 compatibility block-grid items need to be
the same height. You can optionally uncomment the
lines below to support arbitrary height, but know
that IE7/8 do not support :nth-child.
-------------------------------------------------- */

.block-grid {
  display: block;
  overflow: hidden;
  padding: 0;

  & > li {
    display: block;
    height: auto;
    float: left;
  }
}

.block-grid.two-up {
  margin: 0 -15px;
}
.block-grid.two-up > li {
  width: 50%;
  padding: 0 15px 15px;
}
/*  .block-grid.two-up>li:nth-child(2n+1) {clear: left;} */

.block-grid.three-up {
  margin: 0 -12px;
}
.block-grid.three-up > li {
  width: 33.33%;
  padding: 0 12px 12px;
}
/*  .block-grid.three-up>li:nth-child(3n+1) {clear: left;} */

.block-grid.four-up {
  margin: 0 -10px;
}
.block-grid.four-up > li {
  width: 25%;
  padding: 0 10px 10px;
}
/*  .block-grid.four-up>li:nth-child(4n+1) {clear: left;} */

.block-grid.five-up {
  margin: 0 -8px;
}
.block-grid.five-up > li {
  width: 20%;
  padding: 0 8px 8px;
}
/*  .block-grid.five-up>li:nth-child(5n+1) {clear: left;} */

///* Mobile Block Grids */
//@media #{$media-small} {
//  .block-grid.mobile { margin-left: 0; }
//  .block-grid.mobile > li { float: none; width: 100%; margin-left: 0; }
//}
