@import "base";
@import "~compass-mixins/lib/compass/utilities/general/clearfix";
@import "~compass-mixins/lib/compass/typography/links/link-colors";
@import "~compass-mixins/lib/compass/css3/inline-block";
@import "~compass-mixins/lib/compass/css3/box-sizing";
@import "~compass-mixins/lib/compass/css3/border-radius";
@import "~compass-mixins/lib/compass/css3/box-shadow";
@import "~compass-mixins/lib/compass/css3/transition";
@import "~compass-mixins/lib/compass/css3/opacity";
@import "~compass-mixins/lib/compass/css3/images";

body#minimal {
  font-family: $primaryFont;
  background: #f3f7fb;
  background: linear-gradient(
    151deg,
    rgba(#f3f7fb, 1) 0%,
    rgba(#b5d1ec, 1) 100%
  );
  background-size: cover;
  min-height: 100%;
  height: 100%;
  .page-wrapper {
    padding: 15px;
    background: radial-gradient(
      circle,
      rgba(#f3f7fb, 0.5) 0%,
      rgba(#f3f7fb, 0) 100%
    );
    background-position: 50% 50%;
    background-size: cover;
    min-height: 100%;
    height: 100%;
    display: flex;
    overflow: auto;
  }
  h1 {
    font-family: $primaryFont;
    font-weight: 300;
    text-align: center;
    font-size: 32px;
    line-height: 32px;
    margin-top: 0px;
    margin-bottom: 25px;
    &.no-margin {
      margin-bottom: 5px;
    }
    &.bold {
      font-family: $primaryFont;
      font-weight: 600;
    }
  }
  h2 {
    font-family: $primaryFont;
    font-weight: 300;
    text-align: center;
    font-size: 25px;
    line-height: 32px;
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 25px;
  }
  #notification {
    padding: 15px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    font-weight: 500;
    &.error {
      color: $alertColor;
    }
    &.success,
    &.notice {
      color: darken($successColor, 15%);
    }
  }
  #login {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  #login,
  #centered,
  #next {
    $width: 400px;
    $height: 300px;
    width: $width;
    margin: auto;
    padding: 30px;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // margin-top: -$height/2;
    // margin-left: -$width/2;

    &.protected {
      $width: 500px;
      width: $width;
      // margin-left: -$width/2;
    }

    form {
      margin: 0;
      &.inline {
        padding: 40px;
      }
      &.no-padding {
        padding: 0;
      }
    }

    strong {
      font-weight: 600;
    }
    
    form.inline .inputs {
      display: block;
      overflow: visible;
      input {
        background: none;
        border: none;
        margin-bottom: 0px;
        @include box-shadow(none);
        width: 100%;
      }
      .input {
        border: 1px solid rgba(#4b5c66, 0.3);
        @include single-transition(box-shadow, 0.6s, ease-out);
        @include box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 1px #fff);
        @include pie-clearfix;
        background: rgba(#fff, 0.4);
        overflow: visible;
        margin-bottom: 15px;
        .wrapper {
          overflow: visible;
          display: block;
          &:before {
            position: absolute;
            left: 15px;
            top: 12px;
            color: lighten(#4b5c66, 25%);
            font-size: 16px;
            @include single-transition(color, 0.3s, ease-out);
          }
        }
        label {
          top: 12px;
          left: 46px;
          font-size: 13px;
          text-transform: lowercase;
          color: #98a2a8;
          @include transition(opacity 0.6s ease-out, color 0.6s ease-out);
        }
        input {
          padding: 12px 12px 12px 44px;
          font-size: 13px;
          height: auto;
          &:hover {
            @include box-shadow(0 0 2px #26a3d1);
          }
        }
        &.focus {
          @include single-transition(box-shadow, 0.3s, ease-out);
          @include box-shadow(
            inset 0 1px 2px rgba(0, 0, 0, 0.1),
            0 1px 1px rgba(#fff, 0.3),
            0 0 7px rgba(#26a3d1, 0.2)
          );
          label {
            color: #4b5c66;
            @include transition(opacity 0.3s ease-out, color 0.3s ease-out);
          }
          input {
            @include box-shadow(0 0 2px #26a3d1);
          }
        }
        &.hastext.prefilled {
          background: #fafec4 !important;
          label {
            display: none;
          }
          input {
            color: #4b5c66 !important;
            @include transition(opacity 0.6s ease-out, color 0.6s ease-out);
          }
        }
        .help-inline {
          padding: 5px;
          background-color: #ffebeb;
        }
      }
    }
    form.vertical {
      .inputs {
        .input {
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        label {
          font-weight: bold;
          margin-bottom: 5px;
          display: block;
        }
      }
      .action {
        padding-top: 40px;
        margin-top: 40px;
        border-top: 1px solid #ebeeff;
      }
    }
    .action {
      input {
        font-size: 15px;
        font-weight: 800;
        line-height: 36px;
        height: 39px;
        display: block;
        width: 100%;
      }
    }
    .forgot-password,
    .back,
    .twofa-options {
      margin-top: 25px;
      text-align: center;
      a {
        span {
          $main: $mainColor;
          $hover: darken($mainColor, 5%);
          @include link-colors($main, $hover, $main, $main, $hover);
        }
        color: #444;
        font-weight: 500;
        font-size: 12px;
      }
    }
    .twofa-options {
      label {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
        float: left;
        color: #444;
        font-weight: 500;
        font-size: 12px;
      }
      a {
        float: right;
      }
    }

    .form-header {
      padding: 20px 0;
      h1,
      h2 {
        margin-bottom: 0;
      }
    }
    .form-section {
      padding: 40px;
      &:first-child {
        border-radius: 3px 3px 0 0;
      }

      &.form-header {
        padding: 20px 0;
      }

    }
    .form-section ~ .form-section {
      border-top: 1px solid #ebeeff;
    }
    &.bordered {
      background-color: #ffffff;
      border-radius: 3px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    &.wide {
      width: 600px;
      margin-left: -300px;
    }
    &.centered {
      text-align: center;
    }
    &.align-left {
      text-align: left;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  #next {
    position: relative;
    top: 0;
    left: 0;
    max-width: 600px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    .logo {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 30px;
      img {
        width: 220px;
        max-width: 100%;
      }
    }
  }
  #new_signup {
    .form-section {
      text-align: left;
    }
  }
  #logo {
    position: absolute;
    left: 50%;
    top: 75px;
    margin-left: -75px;
    width: 150px;
    max-width: 100%;
  }
}
