@import "base";
@import "../vendor/icons/font-awesome-pro/scss/_functions.scss";
@import "../vendor/icons/font-awesome-pro/scss/_variables.scss";
@import "../vendor/icons/font-awesome-pro/scss/_mixins.scss";

@import "~compass-mixins/lib/compass/css3/user-interface";
@import "~compass-mixins/lib/compass/layout/sticky-footer";
@import "~compass-mixins/lib/compass/utilities/general/clearfix";
@import "~compass-mixins/lib/compass/typography/links/link-colors";
@import "~compass-mixins/lib/compass/css3/inline-block";
@import "~compass-mixins/lib/compass/css3/box-sizing";
@import "~compass-mixins/lib/compass/css3/border-radius";
@import "~compass-mixins/lib/compass/css3/box-shadow";
@import "~compass-mixins/lib/compass/css3/transition";
@import "~compass-mixins/lib/compass/css3/transform";
@import "~compass-mixins/lib/compass/css3/opacity";
@import "~compass-mixins/lib/compass/css3/images";

html,
body {
  height: 100%;
}
div {
  font-size: inherit;
}
body {
  font-family: $primaryFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  background: #fff;
  font-size: 13px;

  &.modal-opened {
    // block scroll for mobile;
    // causes underlying page to jump to top;
    // prevents scrolling on all screens
    overflow: hidden;
    position: fixed;
  }

  --color-text: #222;
  --color-text-secondary: #777;
  --color-border:#dfdfdf;
  --color-border-weak:#EBEBEB;
  --white: #fff;
}

html.is-desktop {
  body.modal-opened {
    // block scroll for desktop;
    // will not jump to top;
    // will not prevent scroll on mobile
    position: absolute;
    width: 100%;
  }
}
@include sticky-footer(0px, "#layout", "#push", "#globalfooter");
ol,
ul,
li {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
a:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none;
}


.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 700;
}

.h3 {
  font-size: 1.5em;
}

.h4 {
  font-size: 1.2em;
}

.h5 {
  font-size: 1.1em;
}

#main {
  @include pie-clearfix;
  padding-left: $sidebarWidth + $mainBarWidth;
}
/* Remove button padding in FF */
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: 0px;
  padding: 0px;
}
.content-wrapper {
  padding: 69px 0px 30px;
}
body.staff-mode #main {
  padding-top: $staffWidth;
}
#fadeout {
  position: fixed;
  pointer-events: none;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 45px;
  z-index: 2;
  background-image: linear-gradient(to bottom, rgba(251, 252, 252, 0), rgba(251, 252, 252, 0.99));
  @include single-transition(background, 0.3s, ease-out);
}
.page-header {
  position: fixed;
  z-index: 20;
  left: $sidebarWidth + $mainBarWidth;
  top: 0;
  right: 0;
  background: #fff;
  padding: 20px 25px 0;
  h1,
  h2,
  h3 {
    border-bottom: none;
    font-weight: 300;
    font-size: 26px;
    padding-bottom: 15px;
    margin-bottom: 0px;
    position: relative;
    white-space: nowrap;
    color: #222;
    & > i.ss-icon:before {
      color: #383f4c;
      font-size: 65%;
      margin-right: 10px;
    }
    & > i {
      &.fad,
      &.fas,
      &.fab,
      &.far {
        color: #195383;
        --fa-primary-color: #195383;
        --fa-secondary-color: #195383;
        font-size: 85%;
        margin-right: 10px;
      }
    }
    &:before {
      position: relative;
    }
    & > a {
      color: #222;

      &:hover {
        text-decoration: underline;
      }
    }
    span.separator {
      color: #aaaaaa;
    }
    .collection-link {
      color: #aaaaaa;
    }
    a.collection-link {
      color: #aaaaaa;
      &:hover {
        text-decoration: underline;
      }
    }
    .less-important {
      color: #777;
    }
    a.less-important {
      color: #777;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &.with-underline {
    h1,
    h2,
    h3 {
      border-bottom: 1px solid #e6e6e6;
    }
  }
  &.scrolling {
    & > h1,
    & > h2,
    & > h3 {
      border-color: transparent;
    }
  }
}
body.staff-mode {
  .page-header,
  .page-header {
    top: $staffWidth;
  }
}
.action-link,
.action-links {
  position: absolute;
  background: #fff;
  top: -25px;
  right: -25px;
  white-space: nowrap;
  margin-top: 5px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0px;
  padding: 18px 25px 0px 0;
  &:before {
    content: "";
    display: block;
    width: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -15px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
  a,
  input,
  button {
    font-size: 13px;
    line-height: 28px;
    i:before {
      @include inline-block;
      margin: 0px 7px 0px -2px;
      font-size: 13px;
      line-height: 13px;
      position: relative;
      top: 1px;
    }
  }
  .focus {
    color: #fff;
    span {
      color: #fff;
    }
    border-color: darken(#88949a, 5%) darken(#88949a, 7%) darken(#88949a, 10%);
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    background: #88949a;
    background-image: linear-gradient(to bottom, #88949a, darken(#88949a, 5%));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#88949a', endColorstr='#7a888e', GradientType=0);
    &:hover {
      background: #6b7376;
      border-color: #646b6e;
      @include box-shadow(0 1px 1px rgba(0, 0, 0, 0.3));
      background-image: linear-gradient(to bottom, #7d888c, darken(#7d888c, 5%));
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7d888c', endColorstr='#707b7f', GradientType=0);
    }
    &:active {
      background-image: linear-gradient(to bottom, #6b7376, darken(#6b7376, 5%));
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6b7376', endColorstr='#5f6669', GradientType=0);
      @include box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.3));
    }
  }
  .colored-label {
    line-height: 28px;
    padding: 0 15px;
    font-size: 13px;
  }
}
h1 > .colored-label {
  line-height: 24px;
  padding: 0 10px;
  font-size: 13px;
  letter-spacing: 0px;
  margin-left: 15px;
}
.custom-fields {
  margin: 0 15px;
  & > li {
    padding: 7px;
    border-bottom: 1px dotted #d5d5d5;
    &:hover {
      background-color: #ecf7ff;
    }
    .edit a {
      margin: 2px 10px;
      float: left;
      @include inline-block;
      background-color: #f0f0f0;
      @include border-radius(10px);
      font-size: 10px;
      padding: 0px 5px;
      border: 1px solid #c9c9c9;
      color: #474747;
      text-decoration: none;
    }
    .definition {
      margin-left: 65px;
      .name {
        span.label {
          color: #000;
          font-weight: bold;
          width: 200px;
          @include inline-block;
        }
        span.kind,
        span.alias {
          @include inline-block;
          width: 200px;
          text-align: center;
          color: #666;
          font-weight: normal;
          padding-left: 15px;
          font-size: 11px;
          em {
            font-weight: bold;
            font-style: normal;
          }
        }
        span.required {
          width: 80px;
          text-align: center;
          @include inline-block;
          color: #c90000;
          font-size: 11px;
        }
        span.auto-expand {
          color: #006400;
          font-size: 11px;
        }
      }
    }
    .drag-handle {
      overflow: hidden;
      float: left;
      cursor: move;
    }
  }
}
.help-inline {
  margin-top: 4px;
  font-size: 11px;
  line-height: 16px;
  color: #a70000;
  display: block;
  font-family: $primaryFont;
  font-weight: normal;
}

select,
input,
textarea {
  font: $primaryFont;
}
select {
  width: auto;
}

section.info {
  line-height: 18px;
  font-size: 12px;
  padding: 15px 25px;
  color: #7c4f00;
  background: #fffbe5;
  margin-bottom: 25px;
  margin-bottom: 0px;
  border-top: 1px solid #bfa679;
  border-bottom: 1px solid #bfa679;
  &.no-border {
    border-bottom: none;
  }
  .right {
    float: right;
  }
}

section.fullscreen {
  min-height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;

  .centered {
    margin: auto;
  }
}

section.content {
  padding: 30px 20px;
}
section.warning {
  line-height: 18px;
  font-size: 12px;
  padding: 15px 20px;
  color: #7c4f00;
  background: #fffbe5;
  border-top: 1px solid #ededdc;
  border-bottom: 1px solid #ededdc;
  display: flex;
  align-items: center;

  .warning-sign {
    font-size: 24px;
    padding: 0px 20px 0 0;
  }
  .message {
    float: left;
    flex-grow: 1;
  }
  .action {
    margin-top: 5px;
    float: right;
  }
}

section.search {
  border-top: 1px solid #c4cdd3;
  border-bottom: 1px solid #c4cdd3;
  background: #eff0f5;
  margin-bottom: -1px;
  position: relative;
  padding: 12px;
  @include pie-clearfix;
  background-image: linear-gradient(to bottom, #f1f3f5, #eff0f5);

  .search-box {
    .input {
      background: #fff;
    }
    i {
      position: absolute;
      color: #455860;
      left: 13px;
      top: 11px;
      font-size: 16px;
    }
    .input {
      label {
        left: 38px;
        top: 9px;
        color: #666;
      }
      input {
        padding: 3px 15px 0px 36px;
        border-color: #b6c3d1;
        color: #111;
        @include box-shadow(rgba(0, 0, 0, 0.1) 0 1px 1px inset, rgba(255, 255, 255, 0.7) 0 1px 0);
        @include border-radius(0px);
        &:focus {
          @include box-shadow(
            rgba(90, 159, 233, 0.41) 0 1px 2px inset,
            rgba(146, 193, 244, 0.15) 0 0 3px inset,
            rgba(#2388da, 0.6) 0 0 4px
          );
          border-color: #4b99ed #69acf6 #87c0ff;
        }
      }
    }
  }
}
section.sub-header {
  border-top: 1px solid #b5bfc7;
  border-bottom: 1px solid #b5bfc7;
  background-image: linear-gradient(to bottom, #f9fafa, #e9edf0 100%);
  position: relative;
  line-height: 18px;
  font-size: 12px;
  padding: 15px 20px;
  @include pie-clearfix;
  .name {
    font-weight: bold;
  }
  .links {
    float: right;
    a {
      @include link-colors(#566174, #222, #566174, #566174, #222);
      text-decoration: underline;
    }
  }
}
.site-switcher {
  white-space: nowrap;
  position: relative;
  a {
    text-decoration: none;
    @include link-colors(#eaf1f5, #fff, #fff, #eaf1f5, #fff);
    @include single-transition(color, 0.3s, ease-out);
  }
  padding-right: 25px;
  &:hover,
  &:active {
    span.updown {
      @include opacity(1);
    }
  }
  .dropdown {
    @include box-shadow(0 0 8px rgba(0, 0, 0, 0.35), 0 0 1px rgba(0, 0, 0, 0.35));
    text-shadow: none;
    background: #fff;
    font-size: 11px;
    min-width: 160px;
    color: #444444;
    @include border-radius(5px);
    display: block;
    position: absolute;
    top: 49px;
    padding: 5px;
    font-size: 12px;
    line-height: 12px;
    font-family: $primaryFont;
    letter-spacing: normal;
    word-spacing: normal;
    font-weight: normal;
    left: 9px;
    margin-left: 0;
    z-index: 999;
    display: none;
    ul {
      @include border-radius(3px);
      display: block;
    }

    li {
      &.header {
        color: #bbbbbb !important;
        padding: 4px 6px;
        cursor: default;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
      }
      &.new {
        color: #999;
      }
      &:first-child {
        @include border-top-radius(3px);
      }
      &:last-child {
        @include border-bottom-radius(3px);
      }
      &.separator {
        background: none repeat scroll 0 0 #e7e7e7;
        border: medium none;
        height: 1px;
        line-height: 1px;
        margin: 5px -5px !important;
        padding: 0 !important;
        text-indent: -99999em;
      }
      & > a {
        color: #444444;
        display: block;
        padding: 4px 6px;
        border: 1px solid transparent;
        @include border-radius(3px);
        @include single-transition(none);
        &:hover,
        &:active {
          color: #fff;
          color: #ffffff;
          cursor: pointer;
          text-shadow: 0 1px 0 rgba(0, 0, 0, 0.6);

          border-color: lighten(#102838, 25%) lighten(#102838, 20%) lighten(#102838, 10%);
          @include box-shadow(0 1px 0 lighten(#4c95c5, 5%) inset);
          background-image: linear-gradient(to bottom, #4d96c6, #286891 49%, #245f85 50%, #22577a 100%);
        }
        &:active {
          border-color: #0f2e62;
          @include box-shadow(0 1px 3px lighten(#4c95c5, 5%) inset);
          $percentage: 5%;
          background-image: linear-gradient(
            to bottom,
            darken(#4d96c6, $percentage),
            darken(#286891, $percentage) 49%,
            darken(#245f85, $percentage) 50%,
            darken(#22577a, $percentage) 100%
          );
        }
      }
    }
  }
}
.notification-wrapper {
  z-index: 300;
}
.notification {
  z-index: 300;
  @include pie-clearfix;
  @include box-sizing(border-box);
  color: #ffffff;
  background: #191919;
  background: rgba(#191919, 0.85);
  position: fixed;
  left: 0px;
  right: 0px;
  font-size: 24px;
  line-height: 87px;
  height: 87px;
  bottom: -87px;
  text-align: center;
  font-weight: 400;
  padding: 0px 12px;
  clear: both;
  p {
    margin-bottom: 0;
    color: inherit;
    color: #fff;
    font-size: 24px;
    line-height: 87px;
  }
  .close {
    display: none;
  }
  &.error,
  &.failure {
    background: #780000;
    background: rgba(120, 0, 0, 0.85);
  }
}
input:-webkit-autofill {
  color: #fff !important;
}

.copy-textarea {
  position: relative;
  margin-bottom: 15px;
  width: 450px;
  textarea {
    @include box-sizing(border-box);
    background: #fafafa;
    border: 1px solid #cccccc;
    padding: 7px 30px 7px 7px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    font-family: "Courier New", Courier, monospace;
    &::-moz-selection {
      background: #ddd;
      color: #000;
      text-shadow: none;
    }
    &::selection {
      background: #ddd;
      color: #000;
      text-shadow: none;
    }
  }
  .clippy {
    position: absolute;
    top: 7px;
    right: 7px;
    display: block;
    overflow: hidden;
    width: 14px;
    height: 14px;
  }
}
.theme-selector {
  margin-bottom: 15px;
}
.services {
  .service-item {
    background: lighten(#dee3e5, 6%);
    @include border-radius(5px);
    padding: 10px 14px;
    margin-bottom: 15px;
    @include pie-clearfix;
    label {
      float: left;
      color: #272d38;
      font-weight: 500;
      width: 200px;
      line-height: 22px;
    }
    span.selected-profile {
      font-size: 11px;
      line-height: 15px;
      padding: 3px 8px 4px;
      @include border-radius(5px);
      color: white;
      @include inline-block;
      text-decoration: none;
      background: #29ba26;
      margin-right: 5px;
    }
    a.authenticate-link {
      font-size: 11px;
      line-height: 15px;
      padding: 3px 8px 4px;
      @include border-radius(5px);
      color: white;
      @include inline-block;
      @include opacity(0.75);
      text-decoration: none;
      background: #22b6d3;
      margin-right: 5px;
      &:hover {
        @include opacity(1);
      }
    }
    a.configure-link {
      font-size: 11px;
      line-height: 15px;
      padding: 3px 8px 4px;
      @include border-radius(5px);
      color: white;
      @include inline-block;
      @include opacity(0.75);
      text-decoration: none;
      background: #fa3f41;
      margin-right: 5px;
      &:hover {
        @include opacity(1);
      }
    }
    a.delete-link {
      font-size: 11px;
      line-height: 15px;
      padding: 3px 8px 4px;
      @include border-radius(5px);
      color: #444851;
      @include inline-block;
      @include opacity(0.75);
      text-decoration: none;
      background: #cfd5d9;
      margin-right: 5px;
      &:hover {
        @include opacity(1);
      }
    }
  }
}
#analytics-select-profile {
  .nimbu-body {
    padding: 0px;
  }
  .actions {
    margin: 0px;
  }
  .profile {
    display: block;
    text-decoration: none;
    padding: 15px;
    border-top: 1px solid #eff2f2;
    &:hover {
      color: #2c333d;
      background-color: #fffbe5;
      border-top: 1px solid #fcf2c0;
    }
    &:first-child {
      border-top: none;
    }
    .name,
    .code {
      @include inline-block;
      width: 48%;
    }
    .name {
      font-weight: bold;
    }
    .code {
      font-size: 12px;
    }
  }
}
#site-switcher {
  .nimbu-body {
    padding: 0px;
    min-width: 450px;
  }
  .actions {
    margin: 0px;
  }
  .site {
    display: block;
    text-decoration: none;
    padding: 10px 15px;
    border-top: 1px solid #eaeaea;
    a {
      color: #333233;
      text-decoration: none;
    }
    &:nth-child(odd) {
      background-color: #f9f9f9;
    }
    &:hover {
      color: #2c333d;
      background-color: #fffed1;
      border-top: 1px solid #fcf2c0;
    }
    &:first-child {
      border-top: none;
    }
    .name,
    .code {
      @include inline-block;
      width: 48%;
    }
    .name {
      font-weight: bold;
    }
    .url {
      font-size: 12px;
      color: #454558;
    }
  }
  .new {
    padding: 5px;
    border-top: 1px solid #eaeaea;
    a {
      background-image: linear-gradient(to bottom, #45b8ce, #206e9f);
      @include border-radius(5px);
      border: 1px solid #4aa1b1;
      text-align: center;
      display: block;
      color: #ffffff;
      text-shadow: 0 -1px 0px rgba(0, 0, 0, 0.3);
      padding: 10px 15px;
      text-decoration: none;
      font-size: 15px;
    }
  }
}
.translation_missing {
  //color:#de1a1a !important;
  //background:#fce2e2 !important;
}
.pagination a {
  padding: 0px;
}
.chzn-search input {
  position: absolute !important;
  left: -9000px !important;
}
#debugbar {
  .navbar-inner {
    @include border-radius(0px);
  }
  li {
    padding: 8px 0;
    margin-right: 30px;
  }
}
#launchr {
  .setup {
    margin-left: 180px;
    .input {
      display: block;
      float: none;
    }
    textarea {
      width: 450px;
    }
  }
}
.nimbu-list {
  & > li {
    @include border-radius(5px);
    background-image: linear-gradient(to bottom, #f9fafb, #e5ebef 100%);
    @include box-shadow(0px 0px 2px #fff inset, 0px 1px 2px rgba(0, 0, 0, 0.2));
    @include pie-clearfix;
    display: block;
    margin: 15px;
    border: 1px solid #bdc3c5;
    border-color: #b9c8ce #b9c8ce #b9c8ce;
    padding: 15px;
    &.with-description {
      padding: 9px 15px;
      .links {
        padding-top: 8px;
      }
    }
  }

  .data {
    float: left;
    .title a {
      color: #0c1010;
      text-decoration: none;
      display: block;
      font-size: 16px;
      text-shadow: 0 1px 0 #fff;
    }
    .description {
      font-size: 11px;
      line-height: 11px;
      padding-top: 3px;
      span.title {
        font-weight: 700;
        padding-right: 5px;
      }
      a {
        color: #2b2d2e;
        text-decoration: none;
        @include inline-block;
        text-shadow: 0 1px 0 #fff;
        &:after {
          content: "•";
          padding: 0 5px;
        }
        &:last-child:after {
          content: none;
        }
      }
      li {
        color: #2b2d2e;
        text-decoration: none;
        @include inline-block;
        text-shadow: 0 1px 0 #fff;
        &:after {
          content: "•";
          padding: 0 5px;
        }
        &:last-child:after {
          content: none;
        }
      }
    }
  }
  .links {
    float: right;
    .count,
    .add-new,
    .edit,
    .delete {
      float: left;
      font-size: 11px;
      padding: 2px 14px;
      color: #365372;
      border-right: 1px solid #c2cad2;
      border-left: 1px solid #ffffff;
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
      a {
        @include link-colors(#2d7292, #005279, #005279, #2d7292, #005279);
      }
    }
  }
}
.nimbu-table {
  margin: 0px;
  padding: 0px;
  min-height: 400px;
  /*@include border-radius(5px);
  @include box-shadow(0px 0px 2px #fff inset, 0px 1px 2px rgba(0,0,0,0.2));*/
  table {
    width: 100%;
    max-width: 100%;
    @include border-bottom-radius(5px);
  }
  .table-top {
    display: block;
    height: 8px;
    border: 1px solid #b9c8ce;
    border-bottom: none;
    @include border-top-radius(5px);
    background-color: #f9fafb;
    background-image: linear-gradient(to bottom, #f9fafb, #f2f5f7 100%);
  }
  .table-bottom {
    display: block;
    height: 8px;
    border: 1px solid #b9c8ce;
    border-top: none;
    @include border-bottom-radius(5px);
    /*      background-color:#f9fafb;
    background-image: linear-gradient(to bottom, #f9fafb, #f2f5f7 100%);
*/
  }
  tr.nimbu-header {
    td {
      padding: 8px 12px 8px;
      background-image: linear-gradient(to bottom, #f2f5f7, #e5ebef 100%);
      color: #435258;
      font-weight: 500;
      font-size: 11px;
      text-transform: uppercase;
      border-bottom: 1px solid #b9c8ce;
    }
    /*td:first-child {
      border-left:1px solid #b9c8ce;
    }
    td:last-child {
      border-right:1px solid #b9c8ce;
    }*/
    td.date {
      width: 100px;
      text-align: center;
    }
    td.actions {
      width: 60px;
      text-align: center;
    }
  }
  tr.nimbu-entry {
    td {
      padding: 8px 12px 8px;
      border-right: 1px dotted #ebebeb;
      vertical-align: middle;
      border-bottom: 1px solid #ebebeb;
      border-top: 1px solid #fff;
      font-size: 12px;
    }
    /*td:first-child {
      border-left:1px solid #b9c8ce;
    }
    td:last-child {
      border-right:1px solid #b9c8ce;
    }*/
    td.date {
      text-align: center;
    }
    &.odd {
      td {
        background: #f9f9fa;
      }
    }
  }
}

.content-locale-picker {
  .button {
    width: 82px;
  }
  .language-flag {
    width: 15px;
    margin: 0px 6px 0px 0;
    padding: 0;
  }
  .dropdown-toggle {
    .text {
      font-size: 11px;
      display: inline-block;
      margin-right: 2px;
    }
    .all-languages-flag {
      i {
        width: 10px;
        margin: 0 4px 0 -6px;
        &:before {
          margin: 0;
        }
      }
    }

    .language-flag {
      margin: -2px 9px 0px -3px;
    }
  }
  .content-locale-dropdown {
    .all-languages-flag {
      i {
        width: 10px;
        margin: 0 7px 0 1px;
        &:before {
          margin: 0;
        }
      }
    }
  }
  .text {
    text-transform: uppercase;
    &.all {
      text-transform: none;
      margin-right: 5px;
    }
  }
  .text-long {
  }
}
section.show {
  p {
    color: #474747;
    margin-left: 180px;
  }
  h3 {
    background: none repeat scroll 0 0 transparent;
    border-top: 1px solid #e6e6e6;
    clear: both;
    color: #b0afb0;
    font-size: 11px;
    font-weight: normal;
    line-height: 20px;
    margin: 20px 0 20px 0;
    padding: 9px 0 0;
    text-transform: uppercase;
    &.first {
      border: none;
      padding: 0px;
    }
  }
  .field {
    @include pie-clearfix;
    overflow: visible;
    margin-bottom: 10px;
    p {
      color: #444444;
    }
    .value {
      margin-left: 180px;
      & > p {
        margin-left: 0px;
      }
    }
    .label {
      float: left;
      font-weight: bold;
      line-height: 16px;
      margin-right: 1%;
      text-align: right;
      width: 150px;
      em {
        color: #828282;
        font-style: normal;
      }
      display: block;
      &:after {
        content: ":";
      }
      &.no-colon:after {
        content: none;
      }
    }
    .on-sale {
      text-decoration: line-through;
      font-size: 11px;
    }
    .sold-out-behaviour {
      font-size: 11px;
      font-style: italic;
      margin-left: 15px;
    }
  }
}
table.fancy {
  width: 100%;
  border: none;
  position: relative;
  border-spacing: 0;
  border-collapse: separate;
  tr {
    vertical-align: middle;
    border-top: 1px solid #ececec;
    //padding: 10px 15px 10px 50px;
    zoom: 1;
    border-width: 1px 0;
    position: relative;
    z-index: 0;
    th {
      background: #f5f5f5;
      background-image: linear-gradient(to bottom, #fdfdfd, #e9edf0);
      @include box-shadow(0 1px 1px 0px rgba(0, 0, 0, 0.05));
      text-align: left;
      font-size: 11px;
      color: #303643;
      text-shadow: 0 1px 0 #fff;
      font-weight: 700;
      text-transform: uppercase;
      border-top: 1px solid #c3cdd3;
      border-bottom: 1px solid #c7c7c7;
      color: #062234;
      white-space: nowrap;
      .th-wrapper {
        display: block;
        position: relative;
        padding: 10px 15px;
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 2px;
          height: 22px;
          top: 50%;
          margin-top: -11px;
          left: -1px;
          background: transparent url("~images/separator.png") center center no-repeat;
        }
      }
      .sort-caret {
        line-height: 6px;
        padding-left: 5px;
      }
      &:first-child {
        border-left: 1px solid #d4d4d4;
        @include border-top-left-radius(4px);
        .th-wrapper:after {
          display: none;
        }
      }
      &:last-child {
        border-right: 1px solid #d4d4d4;
        @include border-top-right-radius(4px);
      }
      a {
        text-decoration: none;
        @include link-colors(#062234, darken(#062234, 10%), #062234, #062234, darken(#062234, 10%));
      }
    }
    td {
      text-align: left;
      padding: 10px 15px;
      font-size: 13px;
      border-top: 1px solid #ececec;
      vertical-align: middle;
    }
    &:first-child + tr td {
      border-top: none;
    }
    &:last-child {
      border-bottom: 1px solid #ececec;
    }
    th a {
      color: #303643 !important;
      @include link-colors(#303643, #303643, #303643, #303643, #303643);
    }
    &.no-links th {
      padding: 10px 15px;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 2px;
        height: 22px;
        top: 50%;
        margin-top: -11px;
        left: -1px;
        background: transparent url("~images/separator.png") center center no-repeat;
      }
      &:first-child:after {
        display: none;
      }
    }
  }
  &.with-hovering {
    tr:hover {
      background: #fffef8;
      z-index: 3;
      a {
        @include link-colors(#905c03, #523503, #523503, #905c03, #523503);
      }
      td {
        border-color: #d9c9a7;
      }
      & + tr {
        td {
          border-color: #d9c9a7;
        }
      }
      &:last-child {
        td {
          border-bottom: 1px solid #d9c9a7;
        }
      }
    }
    &.table-header + tr,
    &.table-header:hover + tr {
      td {
        border-top: none !important;
      }
    }
  }
  &.no-header {
    tr:first-child td {
      border-top: 1px solid transparent;
    }
    tr:first-child + tr td {
      border-top: 1px solid #ececec;
    }
    tr:last-child td {
      border-bottom: 1px solid transparent;
    }
    tr:hover {
      td {
        border-top: 1px solid #d9c9a7 !important;
      }
      & + tr {
        td {
          border-top: 1px solid #d9c9a7;
        }
      }
      &:last-child {
        td {
          border-bottom: 1px solid #d9c9a7;
        }
      }
    }
  }
  td.checkbox,
  th.checkbox {
    width: 1%;
  }
  .edit-links {
    float: right;
    font-size: 11px;
  }
  td.created-at {
    white-space: nowrap;
  }
  .invisible-column {
    display: none;
  }
  .centered {
    text-align: center;
  }
  .select-columns {
    .dropdown-toggle {
      @include link-colors(#303643, #303643, #303643, #303643, #303643);
      font-size: 13px;
      line-height: 10px;
      position: relative;
      top: 3px;
    }
    .dropdown-menu {
      text-transform: none;
      z-index: 10;
      top: 30px;
      right: 6px;
      padding: 0 !important;
      li {
        padding-top: 2px;
        margin-bottom: 2px;
      }
      a {
        padding: 2px 10px 2px 10px;
        font-size: 11px;
        font-weight: 600;
      }
      a.invisible-field {
        @include link-colors(
          #b8b8b8 !important,
          #777 !important,
          #b8b8b8 !important,
          #b8b8b8 !important,
          #777 !important
        );
      }
      a.visible-field {
        color: #303643 !important;
        @include link-colors(#303643, #303643, #303643, #303643, #303643);
      }
    }
  }
  &.mini {
    td {
      padding: 8px 15px;
      border-bottom: 1px solid #ececec;
      &:first-child {
        border-left: 1px solid #d4d4d4;
      }
      &:last-child {
        border-right: 1px solid #d4d4d4;
      }
    }
    th {
      height: 22px;
      padding: 5px 7px 5px 7px;
      white-space: nowrap;
      font-size: 11px;
      text-transform: none;
      font-weight: 500;
      color: #555;
      &:after {
        width: 1px;
        height: 70%;
        margin-top: 0;
        top: 15%;
        background-image: linear-gradient(
          to bottom,
          rgba(182, 196, 207, 0) 0%,
          #b6c4cf 50%,
          #b6c4cf 50%,
          #b6c4cf 80%,
          rgba(182, 196, 207, 0) 100%
        );
      }
    }
  }
  &.small {
    .th-wrapper {
      padding: 6px 15px;
      &::after {
        height: 16px;
        margin-top: -8px;
      }
    }
    .no-links th {
      padding: 6px 15px;
      position: relative;
      &::after {
        height: 16px;
        margin-top: -8px;
      }
    }
    th {
      &:first-child {
        @include border-left-radius(4px);
      }
      &:last-child {
        @include border-right-radius(4px);
      }
    }
  }
  span.note {
    color: #898989;
    font-size: 11px;
  }
}
table.widescreen-table {
  width: 100%;
  border: none;
  position: relative;
  border-spacing: 0;
  border-collapse: separate;
  tr {
    vertical-align: middle;
    border-top: 1px solid #ececec;
    padding: 0px 25px;
    zoom: 1;
    border-width: 1px 0;
    position: relative;
    th {
      background: #f5f5f5;
      background-image: linear-gradient(to bottom, #fdfdfd, #e9edf0);
      @include box-shadow(0 1px 1px 0px rgba(0, 0, 0, 0.05));
      text-align: left;
      font-size: 11px;
      color: #303643;
      text-shadow: 0 1px 0 #fff;
      font-weight: 700;
      text-transform: uppercase;
      border-top: 1px solid #c3cdd3;
      border-bottom: 1px solid #c7c7c7;
      color: #062234;
      white-space: nowrap;
      .th-wrapper {
        display: block;
        position: relative;
        padding: 10px 15px;
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 2px;
          height: 22px;
          top: 50%;
          margin-top: -11px;
          left: -1px;
          background: transparent url("~images/separator.png") center center no-repeat;
        }
      }
      .sort-caret {
        line-height: 6px;
        padding-left: 5px;
      }
      a {
        text-decoration: none;
        @include link-colors(#062234, darken(#062234, 10%), #062234, #062234, darken(#062234, 10%));
      }
      &:first-child {
        .th-wrapper {
          padding-left: 25px;
        }
        .th-wrapper:after {
          display: none;
        }
      }
    }
    td {
      text-align: left;
      padding: 10px 15px;
      font-size: 13px;
      border-top: 1px solid #ececec;
      vertical-align: middle;
      &:first-child {
        padding-left: 25px;
      }
      &:last-child {
        padding-right: 25px;
      }
    }
    &:first-child + tr td {
      border-top: none;
    }
    &:last-child {
      border-bottom: 1px solid #ececec;
    }
    th a {
      color: #303643 !important;
      @include link-colors(#303643, #303643, #303643, #303643, #303643);
    }
    &.jk-selected {
      td:first-child {
        border-left: 2px solid $mainColor;
        padding-left: 23px;
      }
    }
  }
  &.no-border-top {
    tr th {
      border-top: none;
    }
  }
  &.no-hover {
    tr:hover {
      a {
        color: $mainColor !important;
      }
      a:visited {
        color: $mainColor !important;
      }
      a:focus {
        color: darken($mainColor, 10%) !important;
      }
      a:hover {
        color: darken($mainColor, 10%) !important;
      }
      a:active {
        color: $mainColor !important;
      }
      td {
        border-color: #ececec;
      }
      background: inherit;
      z-index: 3;
      & + tr {
        td {
          border-color: #ececec;
        }
      }
      &:last-child {
        td {
          border-bottom: 1px solid #ececec;
        }
      }
    }
  }
  td.checkbox,
  th.checkbox {
    width: 1%;
  }
  .edit-links {
    float: right;
    font-size: 11px;
  }
  td.created-at {
    white-space: nowrap;
  }
  .invisible-column {
    display: none;
  }
  .centered {
    text-align: center;
  }
  .select-columns {
    overflow: clip;
    
    .dropdown-toggle {
      @include link-colors(#303643, #303643, #303643, #303643, #303643);
      font-size: 13px;
      line-height: 10px;
      position: relative;
      top: 3px;
    }
    .dropdown-menu {
      text-transform: none;
      z-index: 10;
      top: 30px;
      right: 6px;
      padding: 0 !important;
      overflow: hidden;
      max-height: 80vh;
      li {
        padding-top: 2px;
        margin-bottom: 2px;
      }
      a {
        padding: 2px 10px 2px 10px;
        font-size: 11px;
        font-weight: 600;
      }
      a.invisible-field {
        @include link-colors(
          #b8b8b8 !important,
          #777 !important,
          #b8b8b8 !important,
          #b8b8b8 !important,
          #777 !important
        );
      }
      a.visible-field {
        color: #303643 !important;
        @include link-colors(#303643, #303643, #303643, #303643, #303643);
      }
    }
    &.open {
      overflow: visible;

      .dropdown-menu {
        overflow: visible;
        max-height: unset;
      }
    }
  }
  tr.controls-sub-section {
    cursor: pointer;
    &:hover {
      position: relative;
      &:after {
        position: absolute;
        content: "";
        display: block;
        height: 1px;
        left: 0px;
        right: 0px;
        bottom: 1px;
        z-index: 99;
        background: #dbcaa8;
      }
    }
  }
  dl {
    dt {
      float: left;
      clear: left;
      width: 60px;
      color: #777;
      text-align: right;
    }
    dd {
      float: left;
      margin-left: 10px;
    }
  }
}
table.widescreen-table,
table.with-actions {
  tr {
    .actions-wrapper {
      position: relative;
    }
    .actions {
      position: absolute;
      right: -12px;
      top: -7px;
      width: 50px;
      text-align: right;
      display: none;
      a {
        @include inline-block;
        @include link-colors(#879ca8, #41515a, #879ca8, #879ca8, #41515a);
        width: 16px;
        height: 16px;
        margin-left: 4px;
        text-align: right;
      }
    }
    &:hover {
      a {
        @include link-colors(#905c03, #523503, #523503, #905c03, #523503);
      }
      td {
        border-top-color: #d9c9a7;
        background: #fffef8 !important;
      }
      background: #fffef8;
      & + tr {
        td {
          border-top-color: #d9c9a7;
        }
      }
      &:last-child {
        td {
          border-bottom: 1px solid #d9c9a7;
        }
      }
      .actions {
        display: block;
      }
    }
    &.table-header + tr,
    &.table-header:hover + tr {
      td {
        border-top: none !important;
      }
    }
  }
}

.roles-list {
  .description {
    font-size: 11px;
    color: #777;
    margin-top: 5px;
  }
}

.fancy-table-header {
  background: #f5f5f5;
  background-image: linear-gradient(to bottom, #fdfdfd, #e9edf0);
  @include box-shadow(0 1px 1px 0px rgba(0, 0, 0, 0.05));
  @include border-radius(4px);
  @include pie-clearfix;
  text-align: left;
  font-size: 11px;
  color: #303643;
  text-shadow: 0 1px 0 #fff;
  font-weight: 700;
  text-transform: uppercase;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #c7c7c7;
  border-right: 1px solid #d4d4d4;
  border-left: 1px solid #d4d4d4;
  color: #062234;
  white-space: nowrap;
  .th {
    display: block;
    float: left;
    position: relative;
    padding: 10px 15px;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 2px;
      height: 22px;
      top: 50%;
      margin-top: -11px;
      left: -1px;
      background: transparent url("~images/separator.png") center center no-repeat;
    }
    &:first-child:after {
      display: none;
    }
  }
  &.small {
    .th {
      padding: 6px 15px;
      &::after {
        height: 16px;
        margin-top: -8px;
      }
    }
  }
  .sort-caret {
    line-height: 6px;
    padding-left: 5px;
  }
  a {
    text-decoration: none;
    @include link-colors(#062234, darken(#062234, 10%), #062234, #062234, darken(#062234, 10%));
  }
}
table.simple {
  width: 100%;
  th {
    line-height: 17px;
    font-weight: 700;
    text-align: left;
    color: #444;
    font-size: 11px;
    text-transform: uppercase;
    padding: 0 10px 10px 10px;
    border-bottom: 1px solid #ececec;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  td {
    border-bottom: 1px dotted #ececec;
    padding: 6px 10px;
    padding-top: 14px;
    padding-bottom: 14px;
    background: transparent;
    vertical-align: middle;
  }
  tr:last-child td {
    border-bottom: none;
  }
  .centered {
    text-align: center;
  }
  &.wide {
    th:first-child,
    td:first-child {
      padding-left: 0;
    }
    th:last-child,
    td:last-child {
      padding-right: 0;
    }
  }
}
table.basic {
  width: 100%;
  tr {
    border-bottom: 1px solid #ececec;
  }
  th {
  }
}
.chzn-container-multi {
  width: 100% !important;
}
.redactor_toolbar {
  z-index: 1;
}

.redactor-editor {
  ul li {
    list-style-type: disc;
  }
  ol li {
    list-style-type: decimal;
  }
}
.CodeMirror pre {
  display: block;
}

/*
* === Analytics ===
*/
.chart-tooltip-wrapper {
  z-index: 99;
}
.chart-tooltip {
  position: relative;
  left: -50%;
  color: #fff;
  background: #062234;
  text-align: center;
  font-size: 11px;
  padding: 4px 7px;
  @include border-radius(3px);
  white-space: nowrap;
  strong {
    font-size: 12px;
    display: block;
  }
  &:after {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(6, 34, 52, 0);
    border-top-color: #062234;
    border-width: 8px;
    left: 50%;
    margin-left: -8px;
  }
}
#analytics {
  .welcome {
    margin-top: 30px;
    h1 {
      font-size: 36px;
      font-weight: 300;
      text-align: center;
      margin: 0 0 15px;
      color: #042333;
    }
    p {
      text-align: center;
      padding: 5px 0 0;
      margin: 0;
      color: #667881;
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        left: 0;
        right: 0;
        top: -1px;
        background-image: linear-gradient(to right, rgba(#eaeaea, 0) 10%, rgba(#eaeaea, 1) 50%, rgba(#eaeaea, 0) 90%);
      }
      strong {
        color: #042333;
      }
    }
  }
  .date-ranges {
    @include border-radius(3px);
    @include pie-clearfix;
    border: 1px solid #dde2eb;
    margin-bottom: 15px;
    text-align: center;
    .range {
      font-size: 11px;
      text-transform: uppercase;
      color: #667881;
      font-weight: 800;
      font-size: 12px;
      line-height: 15px;
      margin: 5px 0 0;
    }
    .firstly {
      color: #002133;
      font-weight: 600;
      font-size: 24px;
      padding: 8px 0 2px;
      margin: 0;
      small {
        font-weight: 600;
        color: #002133;
        line-height: 24px;
        font-size: 18px;
        position: relative;
        top: -3px;
      }
    }
    .secondly {
      color: #444;
      font-size: 12px;
      margin: 0;
      small {
        line-height: 12px;
        font-size: 12px;
      }
    }
    .date-range {
      float: left;
      width: 20%;
      padding: 15px;
      background: #f2f7fa;
      &:hover {
        background-color: #e0e9f1;
      }
      &.current {
        @include border-radius(3px);
        border: 1px solid #dde2eb;
        background: #fff;
        margin: -6px 0 -6px;
        padding: 20px 0 20px;
      }
    }
  }
  .summary {
    @include pie-clearfix;
    padding: 0px 6px;
  }
  .charts {
    margin: 30px 10px;
  }
  .kpi {
    margin-bottom: 15px;
    float: left;
    width: 20%;
    .icon {
      @include border-radius(4px);
      background: #e1e9f1;
      color: #fff;
      text-align: center;
      font-size: 20px;
      line-height: 45px;
      width: 40px;
      height: 40px;
      float: left;
      margin-right: 15px;

      i {
        position: relative;
        top: -3px;
      }
    }
    .label {
      text-transform: uppercase;
      color: #667881;
      font-weight: 800;
      font-size: 12px;
      line-height: 15px;
    }
    .number {
      text-transform: uppercase;
      color: #062234;
      font-weight: 800;
      font-size: 28px;
      line-height: 28px;
    }
    &.large {
      margin-bottom: 50px;
      .icon {
        font-size: 34px;
        line-height: 60px;
        width: 50px;
        height: 50px;
      }
      .label {
        font-size: 12px;
        line-height: 15px;
      }
      .number {
        font-weight: 800;
        font-size: 36px;
        line-height: 36px;
      }
    }
  }
  .active {
    .icon {
      background: #258ee1;
    }
  }
}

/*
* === Pages ===
*/

body.pages {
  .box {
    padding: 20px;
    margin-bottom: 30px;
    @include border-radius(4px);
    border: 1px solid #d7d7d9;
    background: #f7f8f8;
    background-image: linear-gradient(#f7f8f8, #e6e9ec);
    form {
      margin: 15px 0;
      label {
        margin-bottom: 10px;
        display: block;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  #homepage_id_chzn {
    @include inline-block;
  }
  footer {
    overflow: visible !important;
    label {
      @include inline-block;
      margin-right: 10px;
    }
  }
  .mercury-wrapper {
    position: relative;
  }
  .site-options {
    padding: 8px 0px 25px 25px;
  }
  .page-options {
    padding: 25px 25px 25px 0px;
  }
  .pane {
    min-height: 300px;
    padding: 15px;
  }
  .block {
    border: 1px solid #dfdfdf;
    @include border-radius(8px);
    @include box-sizing(border-box);
    padding: 4px;
    color: #85898f;
    font-size: 11px;
    margin-bottom: 25px;
    label {
      display: block;
      margin-bottom: 8px;
    }
    .input {
      margin-top: 8px;
    }
    p {
      color: #85898f;
      margin-top: 8px;
    }
    h1 {
      color: #525760;
      font-size: 13px;
      line-height: 18px;
      font-weight: bold;
      margin-bottom: 0px;
    }
    .toggle-settings {
      float: right;
      display: block;
      background: #94989d url("~images/toggle_settings.gif") center center no-repeat;
      @include border-radius(5px);
      overflow: hidden;
      text-indent: -9999px;
      width: 18px;
      height: 18px;
      cursor: pointer;
      @include opacity(0.25);
      @include single-transition(opacity, 0.5s, ease-out, 0s);
      &:hover {
        @include opacity(0.6);
        @include single-transition(opacity, 0.1s, ease-out, 0s);
      }
    }
    div.selector {
      width: 200px;
      span {
        width: 175px;
      }
      select {
        width: 210px;
      }
    }
    &.settings-hidden {
      .fields-wrapper {
        display: none;
      }
    }
    &.link {
      padding: 0px;
      @include transition(border-color 0.3s ease-out 0s);
      &.small {
        margin-bottom: 8px;
      }
      a {
        padding: 12px 15px 12px 45px;
        display: block;
        background: transparent url("~images/sprite_links.gif") center top no-repeat;
        text-decoration: none;
        color: #85898f;
        @include border-radius(4px);
        @include transition(color 0.3s ease-out 0s, background-color 0.3s ease-out 0s);
        &.view-page {
          background-position: center -68px;
        }
        &.new-page {
          background-position: center -68px;
        }
        &.view-site {
          background-position: center -33px;
        }
        &.change-settings {
          background-position: center -104px;
        }
        &:hover {
          background-color: #dee4e6;
          color: #525760;
          @include transition(color 0.3s ease-out 0s, background-color 0.3s ease-out 0s);
        }
      }
      &:hover {
        border-color: #eceff1;
        @include transition(border-color 0.3s ease-out 0s);
      }
    }
  }

  #main {
    .editor {
      @include box-sizing(border-box);
      .virbSkin table.mceLayout {
        border: none;
      }
      section {
        padding: 0;
      }
      @include clearfix;
      .main-content {
        padding: 25px;
      }
    }
    #slug_field {
      margin-top: 8px;
      
      #slug_edit_trigger {
        line-height: 22px;
        &:hover {
          span.click-to-edit {
            @include inline-block;
          }
        }
      }
      .input {
        @include inline-block;
        vertical-align: top;
        input {
          @include inline-block;
          width: 200px;
        }
        button {
          @include inline-block;
          margin: 0;
          padding: 0;
          border: 0;
          width: 16px;
          height: 16px;
          overflow: hidden;
          text-indent: -9999px;
          &.ok {
            background: transparent url("~images/ok.png") no-repeat center center;
          }
          &.cancel {
            background: transparent url("~images/close.png") no-repeat center center;
          }
        }
      }
      span.slug {
        background-color: #fffbcc;
      }
      span.click-to-edit {
        color: #ccc;
        font-size: 11px;
        display: none;
      }
    }
  }
  textarea {
    height: 100px;
  }
  .page-tree {
    padding: 0 0 0 25px;
  }
  .page-tree-root {
    margin: 15px 15px 5px 25px;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: -0.005em;
  }
  .page-fields {
    padding-top: 25px;
    .field {
      border: 1px solid #e5e6e7;
      background-color: #f8f9fa;
      @include border-radius(4px);
      margin-bottom: 15px;
      
      header {
        @include border-top-radius(4px);
        display: block;
        padding: 5px 12px;
        text-transform: uppercase;
        background: #eff2f3;
        color: #777777;
        font-size: 11px;
        line-height: 11px;
        font-weight: 700;
        text-shadow: 0 1px 0 #ffffff;
        border-color: #bfcfd6;
        
        label {
          margin: 0;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        &.repeatable-header {
          display: flex;
          align-items: center;

          label {
            white-space: nowrap;
          }

          .content-summary {
            min-width: 0; // hack to make text-overflow work: https://css-tricks.com/flexbox-truncated-text/
            flex: 1;
            cursor: pointer;
            flex-shrink: 1;
            padding-left: 5px;
            font-size: 12px;
            font-weight: 500;
            color: #777;
            text-transform: none;
            margin-left: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      section {
        padding: 0px;
        input {
          border: none;
          padding: 8px;
          margin: 0px;
          min-width: 100%;
          max-width: 100%;
          background-color: #fff;
          @include border-radius(0px);
        }
        .selectize-input {
          overflow: visible !important;
        }
      }
      &.file {
        section {
          @include display-flex;
          @include flex-direction(row);
          @include align-items(center);

          padding: 8px;
        }

        .uploader-info {
          margin-left: 15px;

          &:first-child {
            margin-left: 0;
          }
        }

        .preview-image,
        .uploader-wrapper,
        .link-wrapper,
        .remove-wrapper {
          @include inline-block;
        }
        .remove-wrapper {
          margin-left: 15px;

          label {
            font-weight: normal;
            display: inline-block;
            font-size: 12px;
            user-select: none;
            margin-bottom: 0;
          }
          .checker {
            display: inline-block;
          }
        }

        .warning {
          margin-top: 5px;
          font-size: 11px;
          color: #f10000;
        }
      }
      &.switch {
        section {
          background: #fff;
          padding: 10px;
        }
      }
      &.select,
      &.reference {
        overflow: visible;
        section {
          background: #fff;
          padding: 5px 10px 10px;
          label {
            display: none;
          }
        }
      }
      .input {
        margin-bottom: 15px;
      }
      .redactor-box {
        margin-bottom: 0;
      }
      .redactor-editor {
        border: none;
      }

      input[type=color] {
        min-height: 48px;
      }
    }
  }
  #cms-pages {
    margin: 0;
    border-left: 1px dotted #c0c0c0;

    ol,
    li {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      position: relative;
      line-height: 21px;

      div {
        padding-left: 26px;
        z-index: 1;
        position: relative;
        &:before {
          @include fa-icon;
          font-family: "Font Awesome 6 Pro";
          font-weight: 300;
          position: absolute;
          top: 6px;
          left: 5px;
          font-size: 15px;
          line-height: 10px;
          color: #b6b6b6;
          content: fa-content($fa-var-file-alt);
        }
      }
      &[rel="home"] > div:before {
        left: 2px;
        content: fa-content($fa-var-home);
      }
      &[rel="channel"] > div:before {
        left: 4px;
        content: fa-content($fa-var-copy);
      }

      .loading:before {
        left: 4px !important;
        top: 3px !important;
        font-weight: 900;
        font-size: 14px;
        display: inline-block;
        width: 14px;
        height: 14px;
        line-height: 14px;
        content: fa-content($fa-var-spinner) !important;
        animation: fa-spin 1s infinite steps(8);
      }

      &:before {
        content: "";
        position: relative;
        float: left;
        width: 11px;
        border-top: 1px dotted #c0c0c0;
        margin-top: 12px;
        margin-left: -1px;
        z-index: 9;
      }
    }

    ol,
    .cms-children {
      position: relative;

      li {
        margin-left: 20px;
        border-left: 1px dotted #c0c0c0;
        position: relative;
        z-index: 1;
        margin-top: 1px;
      }
    }

    li:last-child > ol:before,
    li:last-child > .cms-children:before {
      content: "";
      display: block;
      position: absolute;
      left: -6px;
      background: white;
      width: 10px;
      top: -12px;
      bottom: 0;
    }

    li:last-child:after {
      content: "";
      display: block;
      position: absolute;
      left: -6px;
      background: white;
      width: 10px;
      height: 10px;
      bottom: 0;
    }

    &.cms-search-results {
      border: none;

      li:before {
        display: none;
      }
    }

    .cms-page {
      cursor: move;
      margin-left: 10px;
      position: relative;
      border-radius: 3px;

      .label-small {
        visibility: hidden;
        opacity: 0;
        display: inline-block;
        @include transform(translate(10px, 0px));
      }

      &:hover {
        box-shadow: rgba(#424257, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
          rgba(#424257, 0.12) 0px 2px 5px 0px, rgba(#424257, 0.12) 0px 3px 9px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
        z-index: 20 !important;
        background: linear-gradient(270deg, #f5f5f1, #ffffff);

        &:before {
          color: $mainColor;
        }

        .label-small {
          visibility: visible;
          transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
          @include opacity(0.8);
          @include transform(translate(0, 0));
        }

        .cms-page-actions {
          visibility: visible;
          transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
          @include opacity(1);
          @include transform(translate(0, 0));
        }
      }

      .cms-count {
        transition: all 0.2s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
        @include transform(translate(5px, 0px));
        display: inline-block;
        visibility: hidden;
        opacity: 0;
        position: relative;
        top: -1px;
        color: #fff;
        margin-left: 5px;
        padding: 3px 5px;
        font-size: 9px;
        line-height: 9px;
        font-weight: 600;
        background-color: #c2ced6;
        border-radius: 5px;
      }

      .channel-page {
        font-size: 11px;
        opacity: 0.5;
      }

      & > a {
        text-decoration: none;
        @include link-colors(#444, #444, #444, #444, #444);
      }
      &:hover > a {
        @include link-colors(#3084ca, #25669c, #3084ca, #3084ca, #25669c);
      }
    }

    .cms-closed,
    .cms-closing {
      & > .cms-page > .cms-count {
        visibility: visible;
        transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
        @include opacity(1);
        @include transform(translate(0, 0));
      }
    }

    .cms-opening {
      & > .cms-page > .cms-count {
        visibility: visible;
        @include opacity(0);
        @include transform(translate(10px, 0));
      }
    }

    .cms-toggle {
      display: block;
      position: absolute;
      z-index: 15;
      cursor: pointer;
      left: -10px;
      top: 2px;
      width: 20px;
      height: 20px;
      transform-origin: 50% 50%;

      &:after {
        @include fa-icon;
        font-family: "Font Awesome 6 Pro";
        font-weight: 900;
        position: absolute;
        top: 6px;
        left: 8px;
        font-size: 15px;
        line-height: 10px;
        color: #2a527f;
        content: fa-content($fa-var-caret-right);
      }
    }

    .cms-open > .cms-toggle {
      transform: rotate(90deg);
    }
  }

  .cms-open > .cms-page {
    .colored-label {
      position: relative;
      left: -20px;
    }
  }

  .cms-page-actions {
    margin-left: 0;
    line-height: 20px;
    visibility: hidden;
    position: absolute;
    right: 5px;
    font-size: 11px;
    z-index: 1;
    opacity: 0;
    display: inline-block;
    @include transform(translate(-15px, 0px));
  }

  .cms-page-actions .cms-delete {
    color: #bc0b0b !important;
  }
  .cms-page-actions .cms-delete:hover {
    color: #f00 !important;
  }

  #cms-pages li.cms-closed > ol {
    display: none;
  }

  #cms-pages li.cms-open > ol {
    display: block !important;
  }

  .cms-content {
    margin-top: 10px;
    margin-left: 5px;
  }

  .ui-nestedSortable-error {
    background: #ffd7d7;
  }

  .ui-nestedSortable-error:before,
  .ui-nestedSortable-error:after,
  .ui-sortable-helper:before,
  .ui-sortable-helper:after {
    display: none !important;
  }
  .ui-sortable-helper {
    border-left: 0 !important;
  }

  .repeatables-list {
    padding: 0px 10px;
  }

  .repeatable-wrapper {
    @include pie-clearfix;

    position: relative;
    margin: 0px;

    & > .add-items-trigger, & > .add-single-repeatable-item {
      height: 15px;
      display: flex; 
      flex-direction: row;
      text-align: center;
      transition: height 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      padding: 2px 0px;
      align-items: center;

      &:before {
        content:'';
        opacity: 0;
        transform: translateX(20px);
        flex-grow: 1;
        border-bottom: 3px dotted rgba($mainColor, 0.1);
      }
      
      &:after {
        content:'';
        opacity: 0;
        flex-grow: 1;
        transform: translateX(-20px);
        border-bottom: 3px dotted rgba($mainColor, 0.1);
      }

      span {
        opacity: 0;
        display: inline-block;
        background-color: rgba($mainColor, 0.1);
        color: $mainColor;
        text-transform: uppercase;
        font-size: 10px;
        padding: 2px 10px;
        border-radius: 4px;
      }
      
      &:before, &:after, span {
        transition: opacity 0.2s linear, transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }

      &:hover {
        height: 30px;
        transition-delay: 0.2s;

        &:before, &:after, span {
          transition-delay: 0.2s;
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }

  .choose-repeatables-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    background: #f8f9fa;

    .grid-item {
      background-color: #fff;
      border-radius: 15px;
      padding: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      transition: background-color 0.1s linear;

      .title {
        color: #555;
        transition: color 0.1s linear;
      }

      i {
        transition: color 0.1s linear, opacity 0.2s linear;
        font-size: 20px;
        margin-bottom: 5px;
        color: #777777;
        opacity: 0.6;
      }

      i.repeatable-hint {
        font-size: 13px;
        margin-bottom: 0;
        position: relative
        top 2px;
      }

      &:hover {
        background-color: rgba($mainColor, 0.03);

        i {
          opacity: 1;
          color: $mainColor;
        }

        .title {
          color: $mainColor;
        }

        svg {
          *[fill] {
            transition: fill 0.1s linear;
          }
          path[fill], g[fill='#000'] {
            fill: $mainColor;
          }
        }
      }
    }
  }
  
  .repeatable-content {
    @include border-radius(5px);
    background: #fff;
    border: 2px dotted #dde2e7;
  }

  .repeatable-icons {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    .drag-handle,
    .move-up,
    .move-down,
    .clone,
    .remove {
      float: left;
      height: 26px;
      opacity: 0.6;

      & > i,
      a {
        display: block;
        height: 26px;
        width: 20px;
        line-height: 26px;
        margin: 0;
        padding: 0;
        color: #777;
      }

      &:hover {
        opacity: 1;

        & > i,
        a {
          color: $mainColor;
        }

        &.remove {
          a {
            color: $alertColor;
          } 
        }
      }
    }
    
    .drag-handle {
      cursor: move;
    }

    .remove a {
      margin: 0;
      height: 26px;
    }
  }

  .add-items-content {
    display: none;

    .nimbu-modal {
      display: none;
    }
  }

  .repeatable-content:hover {
    > .repeatable-header > .repeatable-icons {
      opacity: 1;
    }

    > .repeatable-header > .content-summary {
      padding-right: 55px;
    }
  }

  .editable-fields {
    padding: 10px;

    .field {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      // header {
      //   background: #f8f9fa;
      // }

      .warning {
        color: #f10000;
      }
    }
  }

  .editable-fields, .page-fields {
    .fancy-image-link {
      img {
        max-width: 80px;
        min-width: 10px;
      }
    }
    .field {
      &.error {
        border-color: #FAE1DC;
        background-color: #f7f0f0;
        
        header {
          background: #f7f0f0;
        }
        
        input {
          box-shadow: none;
        }

        .help-inline {
          margin: 0;
          padding: 5px 8px;
        }

        &.reference {
          .help-inline {
            margin: -5px 0 0;
            padding: 0px;
          }
        }

        &.select {
          .help-inline {
            margin: 5px 0 0;
            padding: 0px;
          }
        }
      }
    }
  }

  .repeatable-content > .hint {
    margin: 0;
    padding: 5px 10px;

    & + * {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
  }
  .canvas-wrapper > .hint {
    margin: 0;
    padding: 5px 10px;

    & + * {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
  }
  .editable-fields {
    .hint {
      margin: 0;
      padding: 5px 8px;

      &.repeatable-hint {
        margin-top: -5px;
        padding: 0 8px 5px;
        text-align: center;
      }
    }
  }

  .repeatable-header,
  .repeatable-header label {
    position: relative;
  }

  .repeatable-content {
    .content-summary {
      opacity: 0.6;
      transition: opacity 0.5s ease-out, padding-right 0.1s ease-out;

      &:hover {
        opacity: 0.8;
      }
    }

    &.open {
      > .repeatable-header > .content-summary {
        opacity: 0;
        transition: opacity 0.3s ease-out;
      }
    }

    & > .repeatable-header {
      > .repeatable-collapse-icons {
        .collapse,
        .open {
          float: left;
          height: 16px;
          a {
            display: block;
            height: 16px;
            width: 12px;
            line-height: 16px;
            margin: 0;
            padding: 0;
          }
          margin-right: 5px;
        }

        .collapse {
          display: none;
        }
        .open {
          display: initial;
        }
      }
      @include single-transition(background-color, 0.3s, ease-out, 0s);
      label {
        margin-right: 60px;
      }
      &.moved {
        background-color: #cadcef;
      }
    }
    > .editable-fields {
      max-height: 0;
      padding: 0;
      overflow: hidden;
    }
    &.open {
      > .repeatable-header {
        > .repeatable-collapse-icons {
          .open {
            display: none;
          }
          .collapse {
            display: initial;
          }
        }
      }
      > .editable-fields {
        max-height: 9999px;
        padding: 10px;
        overflow: visible;
      }
    }
  }

  .canvas-wrapper {
    header {
      position: relative;
      .canvas-icons {
        position: absolute;
        top: 0px;
        right: 5px;

        .collapse-all,
        .open-all {
          float: left;
          height: 26px;
          a {
            display: block;
            height: 26px;
            width: 20px;
            line-height: 26px;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }

  .add-repeatables {
    padding: 15px 10px 15px;

    .add-items-trigger, .add-single-repeatable-item {
      display: block;
      padding: 10px 15px;
      text-align: center;
      border-radius: 8px;
      background-color: rgba($mainColor, 0.015);
      border: 2px dotted rgba($mainColor, 0.4);
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 500;
      
      transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
      
      i {
        margin-right: 5px;
      }

      &:hover {
        background-color: rgba($mainColor, 0.08);
        border: 2px dotted rgba($mainColor, 1);
      }
    }
  }

  // page-item-groups
  .page-item-group-wrapper {
    @include pie-clearfix;

    @include border-radius(5px);
    background: #fff;
    border: 2px dotted #dde2e7;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }

    .page-item-group-items {
      padding: 10px;

      .field {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .page-item-group-content {
      > .page-item-group-header {
        border-top-right-radius: 4px;
        display: block;
        padding: 5px 12px;
        text-transform: uppercase;
        background: #ffffff;
        color: #777777;
        font-size: 11px;
        line-height: 11px;
        font-weight: 700;
        text-shadow: 0 1px 0 #ffffff;

        > .page-item-group-collapse-icons {
          .collapse,
          .open {
            float: left;
            height: 16px;
            a {
              display: block;
              height: 16px;
              width: 12px;
              line-height: 16px;
              margin: 0;
              padding: 0;
            }
            margin-right: 5px;
          }

          .collapse {
            display: none;
          }
          .open {
            display: initial;
          }
        }
        @include single-transition(background-color, 0.3s, ease-out, 0s);
        label {
          margin: 0;
          margin-right: 60px;
        }
      }
      > .page-item-group-items {
        max-height: 0;
        padding: 0;
        overflow: hidden;
      }
      &.open {
        > .page-item-group-header {
          border-bottom: 2px dotted #dde2e7;
          > .page-item-group-collapse-icons {
            .open {
              display: none;
            }
            .collapse {
              display: initial;
            }
          }
        }
        > .page-item-group-items {
          max-height: 9999px;
          padding: 10px;
          overflow: visible;
        }
      }
    }
  }
}
.page-hidden > .cms-page {
  @include opacity(0.6);
  &:hover {
    @include opacity(1);
  }
}
.page-hidden-label {
  font-size: 9px;
  color: #fff;
  background-color: #323a47;
  border-radius: 2px;
  position: relative;
  top: -2px;
  padding: 0 3px;
}
#i18n_table {
  td.key {
    width: 33%;
  }
  .cell {
    padding: 10px 15px;
  }
  .nimbu-entry {
    .key {
      font-family: "Courier New", Courier, monospace;
    }
    .no-translations {
      color: #aaa;
    }
    .locale {
      @include pie-clearfix;
      .code {
        text-transform: uppercase;
        color: #999;
        float: left;
        font-size: 11px;
        margin-bottom: 5px;
      }
      .value {
        margin-left: 34px;
        word-break: break-word;
      }
    }
  }
  td.translation {
    border-right: none;
  }
  td.actions a {
    display: none;
  }
  .nimbu-entry:hover {
    td.actions a {
      @include inline-block;
    }
  }
}

/*
* === Uploads ===
*/

.inline-file-upload-wrapper {
  position: relative;
}
.inline-file-upload {
  cursor: pointer !important;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;

  input {
    cursor: pointer !important;
    //font-size: 200px;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1100;
  }
}

body.media {
  #container {
    min-height: 100%;
  }
  #main {
    min-height: 100% !important;
  }
  .progress {
    float: right;
    width: 200px;
    height: 16px;
    margin-top: 4px;
    position: relative;
    border-radius: 2px;
    margin-right: 10px;
    .ui-progressbar {
      height: 100%;
      border: 1px solid #ddd;
      border-radius: 2px;
      box-sizing: border-box;
    }
    .ui-progressbar-value {
      background-color: #d0e3ec;
      border: 1px solid #fff;
      height: 88%;
      margin: 1px;
      border-radius: 2px;
      box-sizing: border-box;
    }
    .progress-label {
      text-align: center;
      font-size: 10px;
      position: absolute;
      left: 50%;
      top: 50%;
      color: #555;
      transform: translate(-50%, -50%);
      text-shadow: 0 0 2px rgba(255, 255, 255, 0.8);
    }
  }

  .file-list {
    span {
      @include inline-block;
    }
    .file-type {
      width: 30px;
      float: left;
    }
    .access {
      width: 50px;
      float: left;
    }
    .size {
      float: right;
      width: 50px;
      text-align: right;
      margin-right: 10px;
    }
    .legend {
      font-size: 11px;
      color: #a6a8ad;
      span {
        padding: 5px 10px 5px 0px;
        &.file-type {
          text-align: center;
        }
      }
    }
    .name a {
      color: $txtColor;
      &:hover {
        color: $mainColor;
      }
    }
    .actions {
      display: none;
      float: right;
      margin-top: 4px;
      font-family: "Lucida Grande", "Lucida Sans Unicode", Verdana, "Jamrul", sans-serif;
      font-size: 10px;
      line-height: 10px;
      font-weight: normal;
      a {
        @include border-radius(5px);
        @include opacity(0.75);
        padding: 3px 6px 4px;
        color: #fff;
        text-decoration: none;
        text-shadow: none;
        @include single-transition(opacity, 0.3s, ease-out, 0s);
        &:hover {
          @include opacity(1);
          @include single-transition(opacity, 0.3s, ease-out, 0s);
        }
      }
      .delete a {
        background-color: #da2c2f;
      }
      .link a {
        background-color: #52aae3;
      }
      .make-public a {
        background-color: #79be00;
      }
      .make-shared a {
        background-color: #ee9422;
      }
    }
    .file {
      border-top: 1px solid #e9e9ec;
      padding: 8px;
      @include pie-clearfix;
      .file-type,
      .access {
        i {
          text-align: center;
          font-size: 16px;
          color: #666;
        }
      }
      .name {
        @include inline-block;
      }
      .size {
        float: right;
        color: #85898f;
        font-size: 12px;
        width: 60px;
        text-align: right;
        white-space: nowrap;
        padding: 2px 0;
      }
      &:hover {
        background-color: #eff9fc;
        color: #005279;
        border-color: #aac7d4;
        .actions {
          display: block;
        }
        & + .file {
          border-color: #aac7d4;
        }
        &:last-child {
          border-bottom: 1px solid #aac7d4;
        }

        .progress {
          .ui-widget-content {
            background: #d5e2e6;
          }
          .ui-progressbar {
            border: 1px solid #aac7d4;
          }
          .ui-progressbar-value {
            background: #afd1db;
          }
          .progress-label {
            color: #005279;
          }
        }
      }
      &.uploading:hover {
        .actions {
          display: none;
        }
      }
    }
  }
}

/*
* === Menus ===
*/

.ui-sortable {
  position: inherit;
  .ui-sortable-helper {
    background: #fff;
    z-index: 9999;
  }
}
#menu_item_list {
  text-align: left;
  select {
    min-width: 0px;
  }
  .drag-handle-wrapper {
    width: 3%;
    padding: 11px 0px;
  }
  .drag-handle {
    display: flex;
    height: 36px;
    margin: 0;
    float: none;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    &:hover {
      opacity: 0.8;
    }
  }
  .item-header {
    border-bottom: 1px solid #ececec;
    @include pie-clearfix;
    & > div {
      float: left;
      padding: 0px 15px 7px 0;
      font-weight: 700;
    }
    .name {
      margin-left: 3%;
    }
  }
  .name,
  .target,
  .drag-handle-wrapper {
    float: left;
  }
  .item-list > li {
    text-align: left;
    border-bottom: 1px solid #ececec;
    &:last-child {
      border-bottom: none;
    }
    ol {
      margin-left: 15px;
      padding-left: 15px;
      position: relative;
      & > li:first-child:before {
        @include fa-icon;
        font-family: "Font Awesome 6 Pro";
        font-weight: 400;
        position: absolute;
        font-size: 15px;
        line-height: 10px;
        content: fa-content($fa-var-level-up-alt);
        transform: rotate(90deg);
        position: absolute;
        left: -7px;
        top: 23px;
        font-size: 12px;
        color: #a6a6a6;
      }
    }
  }
  .menu-item {
    width: 100%;
    position: relative;
    @include pie-clearfix;
    border-bottom: 1px solid #ececec;
    &:last-child {
      border-bottom: none;
    }
  }
  .input {
    &.string input {
      line-height: 35px;
    }
    label {
      display: none;
    }
    float: left;
    margin: 0;
    padding: 0;
  }
  .wrapper {
    margin: 0;
  }
  .name {
    width: 25%;
    padding: 10px 2% 10px 0;
    .input {
      width: 100%;
    }
  }
  .target {
    padding: 10px 0px 10px 0;
    width: 68%;
    .input {
      &.kind {
        width: 30%;
        margin-right: 2%;
      }
      &.url {
        width: 67%;
        margin: 0;
      }
      &.page_id {
        width: 68%;
        margin: 0;
      }
      &.channel_id {
        margin-top: 10px;
        width: 100%;
      }
    }
  }
  .remove {
    padding: 10px 0;
    float: right;
    width: 4%;
  }
  .remove a {
    overflow: hidden;
    float: right;
    display: block;
    margin-top: 8px;
    margin-right: 5px;
    color: #333;
    opacity: 0.5;
    &:hover {
      opacity: 0.8;
    }
  }
}
.menu-list {
  .menu {
    margin-bottom: 45px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .name {
    @include inline-block;
    width: 250px;
  }
  .level-1 .name {
    width: 230px;
  }
  .level-2 .name {
    width: 210px;
  }
  .level-3 .name {
    width: 190px;
  }
  .target {
    @include inline-block;
  }
  .menu-item-header {
    padding-left: 15px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .menu-item {
    margin: 0px 15px;
    padding: 5px 0;
    border-bottom: 1px dashed #dedede;
  }
  .submenu-items {
    margin-left: 20px;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      top: 10px;
      left: 12px;
      bottom: 0px;
      border-left: 1px dotted #ddd;
    }
    .menu-item {
      padding-left: 10px;
    }
  }
}
.menu-header {
  color: #2c3220;
  font-family: $primaryFont;
  font-weight: 300;
  word-spacing: -0.04em;
  border-bottom: 1px solid #cccccc;
  color: #77201f;
  font-size: 15px;
  margin-bottom: 15px;
  padding-bottom: 2px;
  span {
    float: right;
    letter-spacing: 0;
    word-spacing: 0;
    padding-left: 15px;
    padding-bottom: 2px;
    font-size: 11px;
    line-height: 15px;
    &.permalink {
      color: #999;
      b {
        font-weight: 500;
        color: #666;
      }
    }
    &.actions {
      float: right;
    }
  }
}

/*
* === Domains ===
*/

.domain-list {
  margin-bottom: 25px;
  .domain {
    @include pie-clearfix;
    border-bottom: 1px dotted #ccc;
    color: #949191;
    .globe {
      float: left;
      padding: 10px;
      font-size: 16px;
      position: relative;
      top: 2px;
      &:before {
        color: #949191;
      }
    }
    .alert {
      float: right;
      color: #b22222;
      padding: 10px;
      font-size: 16px;
      position: relative;
      top: 2px;
      &:before {
        color: #b22222;
      }
    }
    .name {
      padding: 10px;
      display: block;
      float: left;
      line-height: 24px;
      color: #777;
    }
    .controls {
      @include opacity(0);
      @include transition(margin 0.2s ease-out, opacity 0.3s ease-out);
      margin-left: -30px;
      padding: 10px;
      float: left;
      line-height: 24px;
      font-size: 11px;
      a {
        @include inline-block;
        margin-left: 10px;
      }
    }
    .created-at {
      @include opacity(0);
      margin-left: auto;
      font-size: 11px;
      color: #949191;
    }
    .note {
      color: #949191;
      font-size: 11px;
    }
    .host {
      a {
        text-decoration: none;
      }
    }
    a {
      @include link-colors(#777, #555, #777, #777, #555);
    }
    .header {
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        .controls {
          @include opacity(1);
          @include transition(margin 0.2s ease-out, opacity 0.3s ease-out);
          margin-left: 0px;
        }
        .created-at {
          @include opacity(1);
          @include transition(margin 0.2s ease-out, opacity 0.3s ease-out);
        }
      }
    }
    .details {
      margin-top: 0px;
      color: #505050;
    }
    &.primary {
      color: #222;
      .globe {
        color: #222;
      }
      .header {
        a {
          @include link-colors(#222, #000, #222, #222, #000);
        }
      }
      .primary {
        padding-left: 10px;
        color: #222;
      }
    }
    h4 {
      font-size: 16px;
      margin-bottom: 10px;
      color: #505050;
    }
  }
  p {
    font-size: 12px;
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0px !important;
    }
  }
  .domain-instructions {
    border-radius: 10px;
    background: #ffefcf;
    padding: 25px 15px;
    color: #8b4513;
    margin-bottom: 25px;
    h4,
    p {
      color: #8b4513;
    }
    .instructions {
      position: relative;
      margin-left: 52px;
    }
    .alert {
      float: left;
      color: #8b4513;
      font-size: 22px;
      i:before {
        color: #8b4513;
      }
    }
  }
  .google-domain-verification {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .verification-success-icon {
    float: left;
    padding-top: 6px;
  }
  .verification-success {
    margin-left: 35px;
  }
  .check-again {
    position: absolute;
    bottom: 6px;
    right: 3px;
  }
}
#force_primary_admin_form {
  .label {
    display: inline;
  }
  .switch {
    border: 1px solid lighten(#a4a1a1, 6%);
    @include inline-block;
    @include border-radius(3px);
    margin: 5px 20px;
  }
}

/*
* === Channels / Database ===
*/

.channel-list {
  .channel {
    @include border-radius(5px);
    @include clearfix;
    background: #f5f5f5;
    background-image: linear-gradient(to bottom, #fdfdfd, #e9edf0);
    @include box-shadow(0 1px 1px 0px rgba(0, 0, 0, 0.05));
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #c7c7c7;
    display: block;
    margin: 15px;
    border: 1px solid #bdc3c5;
    border-color: #b9c8ce #b9c8ce #b9c8ce;
    padding: 15px;
    &.with-description {
      padding: 9px 15px;
      .channel-links {
        padding-top: 8px;
      }
    }
  }

  .channel-data {
    float: left;
    .channel-title a {
      color: #0c1010;
      text-decoration: none;
      display: block;
      font-size: 16px;
      text-shadow: 0 1px 0 #fff;
    }
    .channel-description a {
      color: #2b2d2e;
      text-decoration: none;
      display: block;
      font-size: 11px;
      text-shadow: 0 1px 0 #fff;
    }
  }
  .channel-links {
    float: right;
    & > div {
      float: left;
      font-size: 11px;
      padding: 2px 14px;
      color: #365372;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: -1px;
        width: 1px;
        height: 100%;
        margin-top: 0;
        top: 0;
        background-image: linear-gradient(
          to bottom,
          rgba(182, 196, 207, 0) 0%,
          #b6c4cf 50%,
          #b6c4cf 50%,
          #b6c4cf 80%,
          rgba(182, 196, 207, 0) 100%
        );
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: -2px;
        width: 1px;
        height: 100%;
        margin-top: 0;
        top: 0;
        background-image: linear-gradient(to bottom, rgba(#fff, 0) 0%, #fff 50%, rgba(#fff, 0) 100%);
      }
      &:first-child {
        &:after {
          display: none;
        }
        &:before {
          display: none;
        }
      }
      a {
        @include link-colors(#2d7292, #005279, #005279, #2d7292, #005279);
      }
    }
  }
}
.no-entries td {
  color: #666;
  font-size: 13px;
  padding: 190px 0;
  text-align: center;
  border-left: 1px solid #b9c8ce;
  border-right: 1px solid #b9c8ce;
  font-style: italic;
}
.channel-entries {
  margin: 0px;
  padding: 0px;
  min-height: 400px;
  position: relative;
  overflow-y: auto;
  
  tr.channel-entry {
    cursor: pointer;
    position: relative;
    td {
      padding: 8px 12px 8px;
      vertical-align: middle;
      font-size: 12px;

      
      .fancy-image-link {
        img {
          max-width: 200px;
          max-height: 100px;
        }
      }
    }
    .inline-custom-image {
      float: left;
      img {
        background: #fff;
        padding: 2px;
        border: 1px solid #ccc;
        margin-right: 3px;
      }
    }
    td.date {
      text-align: center;
    }
    &:hover {
      td {
        background: #fffef8;
      }
    }
  }
}
.switch {
  margin-bottom: 10px;
}

.channel-definition {
  margin-bottom: 30px;
  a {
    text-shadow: none;
  }
  .channel-header {
    position: relative;
    color: #333333;
    background-color: #eeeeee;
    border-bottom: 1px solid #cccccc;
    padding: 4px 10px;
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: 700;
    a {
      text-decoration: none;
      color: #333333;
    }
  }
  .add-channel-entry {
    margin-top: 20px;
    border: 1px solid #ccc;
    border-left: none;
    border-right: none;
    a {
      border-top: 1px solid #fff;
      display: block;
      background-color: #f5f5f5;
      text-decoration: none;
      font-size: 12px;
      line-height: 13px;
      padding: 1px 10px 4px;
      @include link-colors(#3084ca, #333, #333, #3084ca, #333);
      &:hover {
        background-color: #ecf2f5;
      }
      span.plus {
        position: relative;
        top: 3px;
        font-family: Arial;
        font-size: 20px;
        font-weight: 700;
        color: #417b9f;
        padding-right: 5px;
      }
    }
  }
  .entries-labels {
    margin: 0 15px;
    border-bottom: 1px solid #eee;
    @include clearfix;
    padding: 0 8px 8px;
    span {
      font-weight: bold;
      font-size: 12px;
      &.title {
      }
      &.last_update {
        float: right;
        margin-right: 26px;
      }
    }
  }
  .item-list {
    li {
      padding: 8px;
      .remove a {
        margin: 0;
        float: right;
      }
      span.last_update {
        float: right;
        margin-right: 10px;
        font-size: 11px;
        padding-top: 2px;
      }
    }
  }
}
.channel-field-list {
  .field {
    @include clearfix;
    //border-bottom:1px dotted #ddd;
    padding: 30px 30px 30px 0;
    &:last-child {
      border-bottom: none;
    }
    border-bottom: 1px solid #e9e9e9;
    border-top: 1px solid #fff;
  }

  // Float labels left
  .label {
    padding-top: 5px;
    padding-right: 30px;
    font-size: 13px;
    line-height: 18px;
    float: left;
    width: 110px;
    text-align: right;
    font-weight: bold;
    color: #666666;
    abbr {
      display: none;
    }
  }
  .input {
    margin-left: 160px;
    padding-top: 5px;
  }
}
.channel-pagination {
  float: right;
  .page,
  .next,
  .last,
  .prev,
  .first,
  .gap {
    padding: 0px 3px;
    font-weight: bold;
    width: 24px;
    height: 24px;
    line-height: 24px;
    float: left;
    display: block;
    text-align: center;
    &.current {
      color: #f0f0f0;
      background: #6a6a6a;
      border: 1px solid #6a6a6a;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.7);
      text-align: center;
      padding: 0;
      @include border-radius(3px);
      @include box-shadow(0 1px 0 #fff, inset 0 1px 2px rgba(0, 0, 0, 0.3));
    }
    a {
      background-image: linear-gradient(to bottom, #f6f6f6, #eaeaea);
      @include border-radius(3px);
      @include box-shadow(inset 0 1px 0 #fff, 0 1px 2px rgba(0, 0, 0, 0.3));
      float: left;
      display: block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      color: #717171;
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
      text-decoration: none;
      background-color: #ebedef;
      border: 1px solid #afafaf;
      border-color: #cacaca #bcbcbc #afafaf;
    }
  }
}
.ui-timepicker-container {
  position: absolute;
  overflow: hidden;
}

.ui-timepicker {
  display: block;
  height: 200px;
  text-align: center;
  list-style: none outside none;
  overflow: auto;
  overflow-x: hidden; /* IE */
  margin: 0;
  padding: 0 0 0 1px;
}
.ui-timepicker-standard {
  /* .ui-widget */
  font-family: Verdana, Arial, sans-serif;
  font-size: 1.1em;
  /* .ui-widget-content */
  background-color: #fff;
  border: 1px solid #aaa;
  color: #222;
  /* .ui-menu */
  margin: 0;
  padding: 2px;
}
.ui-timepicker-standard a {
  /* .ui-widget-content a */
  color: #222;
}
.ui-timepicker-standard .ui-state-hover {
  /* .ui-state-hover */
  background-color: #dadada;
  border: 1px solid #999;
  font-weight: normal;
  color: #212121;
}
.ui-timepicker-standard .ui-menu-item {
  /* .ui-menu .ui-menu-item */
  /*clear: left;
    float: left;*/
  margin: 0;
  padding: 0;
  /*width: 100%;*/
}
.ui-timepicker-standard .ui-menu-item a {
  /* .ui-menu .ui-menu-item a */
  display: block;
  padding: 0.2em 0.4em;
  line-height: 1.5;
  text-decoration: none;
}
.ui-timepicker-standard .ui-menu-item a.ui-state-hover {
  /* .ui-menu .ui-menu-item a.ui-state-hover */
  font-weight: normal;
  margin: -1px -1px -1px -1px;
}
.ui-timepicker-corners,
.ui-timepicker-corners .ui-corner-all {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.ui-timepicker-hidden {
  /* .ui-helper-hidden */
  display: none;
}

/*
* === Themes ===
*/

.CodeMirror-completions {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  -webkit-box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
}
.CodeMirror-completions select {
  background: #fafafa;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: monospace;
}
.cm-liquid-tag {
  color: #0096ac;
  background: rgba(#0096ac, 0.03);
}

.cm-liquid-variable {
  color: #9200c1;
  background: rgba(#9200c1, 0.03);
}

.item-list-header {
  position: relative;
  color: #333333;
  background-color: #eeeeee;
  border-bottom: 1px solid #cccccc;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 700;
  a {
    text-decoration: none;
    color: #333333;
  }
}
.item-list-hint {
  background-color: #fffbe5;
  color: #8d786f;
  padding: 5px 10px;
  border-bottom: 1px solid #efebd5;
  text-shadow: none;
  font-size: 12px;
}
.assets {
  .item-list-add-item {
    margin-top: 15px;
  }
}

.view-asset {
  margin-bottom: 15px;
}

.code-editor {
  .actions {
    display: block;
    margin-bottom: 25px;
    li {
      @include inline-block;
      vertical-align: top;
      padding: 0px 5px;
      border-left: 1px solid #eee;
      &:first-child {
        padding-left: 0px;
        border: none;
      }
      &#rename-form {
        padding: 0px 10px;
        .input {
          @include inline-block;
        }
        label {
          display: none;
        }
        input {
          width: 150px;
        }
        span {
          @include inline-block;
          vertical-align: top;
          padding-left: 5px;
        }
      }
    }
  }

  #editor-wrapper {
    margin-right: 35px;
  }
  #editor {
    position: relative;
    height: 500px;
    width: 100%;
    @include box-sizing(border-box);
    margin-right: 50px;
    .ace_gutter {
      background-color: #f8f8f8;
      border-right: none;
    }
    .ace_scroller {
      border: 1px solid #d9d9d9;
    }
  }
}

#code_editor {
  position: fixed;
  top: 119px;
  bottom: 0;
  right: 0;
  left: $sidebarWidth + $mainBarWidth + 1;
  overflow: scroll;
  .asset-type {
    cursor: pointer;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    padding-left: 50px;
    font-size: 12px;
    &:first-child {
      margin-top: 0px;
    }
    &.open:before,
    &.closed:before {
      @include fa-icon;
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
      position: absolute;
      top: 6px;
      left: 10px;
      font-size: 15px;
      line-height: 10px;
      color: #97b8d3;
    }
    &.open:before {
      content: fa-content($fa-var-caret-down);
      left: 7px;
    }
    &.closed:before {
      content: fa-content($fa-var-caret-right);
    }
    .asset-title {
      position: relative;

      &:before,
      &:after {
        @include fa-icon;
        font-size: 16px;
        font-family: "Font Awesome 6 Duotone";
        font-weight: 900;
        position: absolute;
        left: -24px;
        top: 4px;
        color: #97b8d3;
      }
      &:before {
        font-family: "Font Awesome 6 Pro";
        content: fa-content($fa-var-folder);
      }
      &:after {
        content: fa-content($fa-var-folder);
      }
    }
    &.open .asset-title {
      &:before {
        content: fa-content($fa-var-folder-open);
      }
      &:after {
        content: fa-content($fa-var-folder-open);
      }
    }
  }
  .assets {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 8px;
      left: 11px;
      width: 2px;
      border-radius: 2px;
      background: rgba(#97b8d3, 0.15);
    }
  }
  .asset {
    a {
      font-size: 12px;
      padding-left: 40px;
      display: block;
      padding: 0px 20px 0 50px;
      line-height: 24px;
      color: #838b91;
      text-decoration: none;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;

      .item-name {
        position: relative;
        display: block;

        &:before,
        &:after {
          @include fa-icon;
          font-size: 16px;
          font-weight: 900;
          position: absolute;
          left: -24px;
          top: 4px;
          color: lighten(#97b8d3, 5%);
        }
        &:before {
          font-family: "Font Awesome 6 Pro";
        }
        &:after {
          font-family: "Font Awesome 6 Duotone";
        }
      }
    }
    &.asset-code,
    &.asset-layout,
    &.asset-template,
    &.asset-snippet {
      .item-name {
        &:before {
          font-weight: 300;
          content: fa-content($fa-var-file-code);
        }
      }
    }
    &.stylesheet a {
      .item-name {
        &:before {
          font-weight: 400;
          content: fa-content($fa-var-palette);
        }
      }
    }
    &.javascript a {
      .item-name {
        &:before {
          font-weight: 400;
          content: fa-content($fa-var-cubes);
        }
      }
    }
    &.image a {
      .item-name {
        &:before {
          font-weight: 400;
          content: fa-content($fa-var-file-image);
        }
      }
    }
    &.font a {
      .item-name {
        &:before {
          font-weight: 400;
          content: fa-content($fa-var-font);
        }
      }
    }
    &.media a {
      .item-name {
        &:before {
          font-weight: 300;
          content: fa-content($fa-var-photo-video);
        }
      }
    }
    &.ui-sortable-helper:before a {
      .item-name {
        &:before {
          font-weight: 300;
          content: fa-content($fa-var-file);
        }
      }
    }
    &:hover {
      background-color: #f5fafe;
      a {
        color: #464b4f;
      }
    }
    &.current {
      background-color: #f0fafe;
      a {
        color: #34383b;
      }
    }
  }
  .new-asset a {
    display: block;
    @include link-colors(#1782c8, #12649a, #1782c8, #1782c8, #12649a);
    text-decoration: underline;
    font-size: 12px;
    padding: 5px 0 5px 25px;
  }
  aside {
    padding: 10px 0;
    position: fixed;
    border-right: 1px solid #ced5db;
    top: 119px;
    overflow: scroll;
    bottom: 0px;
    width: 200px;
  }
  .asset-directory {
    margin-left: 0px;
    position: relative;
    .dir {
      display: block;
      padding: 0px 10px 0px 60px;
      line-height: 24px;
      background: #fff;
      color: #838b91;
      text-decoration: none;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      font-size: 12px;
    }
    &.open .dir:before,
    &.closed .dir:before {
      @include fa-icon;
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
      position: absolute;
      top: 6px;
      left: 20px;
      font-size: 15px;
      line-height: 10px;
      color: #97b8d3;
    }
    &.open .dir:before {
      content: fa-content($fa-var-caret-down);
      left: 17px;
    }
    &.closed .dir:before {
      content: fa-content($fa-var-caret-right);
    }
    .directory-title {
      display: block;
      position: relative;

      &:before,
      &:after {
        @include fa-icon;
        font-size: 14px;
        font-family: "Font Awesome 6 Duotone";
        font-weight: 900;
        position: absolute;
        left: -25px;
        top: 5px;
        color: lighten(#97b8d3, 5%);
      }
      &:before {
        font-family: "Font Awesome 6 Pro";
        content: fa-content($fa-var-folder);
      }
      &:after {
        content: fa-content($fa-var-folder);
      }
    }
    &.open .directory-title {
      &:before {
        content: fa-content($fa-var-folder-open);
      }
      &:after {
        content: fa-content($fa-var-folder-open);
      }
    }
    li {
      a {
        padding-left: 60px;
        background-position: 35px center !important;
      }
    }
  }
  .asset-directory-list {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 8px;
      left: 21px;
      width: 2px;
      border-radius: 2px;
      background: rgba(#97b8d3, 0.15);
    }
  }
}

body.staff-mode {
  #code_editor {
    top: 119px + $staffWidth;

    aside {
      top: 119px + $staffWidth;
    }
  }
}
#asset_preview {
  margin-left: 200px;
}
#raw_code_field {
  margin-left: 200px;
  position: relative;
  .wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0px;
  }
  label {
    display: none;
  }
  textarea {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    @include box-sizing(border-box);
    font-family: monospace;
    line-height: 1em;
    width: 100%;
    opacity: 1;
  }
  .CodeMirror {
    background-color: #fff;
    height: auto;
    @include box-sizing(border-box);
    .fullscreen {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;
      margin: 0;
      padding: 0;
      border: 0px solid #bbbbbb;
      opacity: 1;
      background-color: #fff;
      max-height: 9999px;
      min-height: 0px;
    }
  }
  .CodeMirror-scroll {
    height: auto;
    overflow-y: auto;
    overflow-x: auto;
    //max-height:800px;
    min-height: 500px;
  }
}
#container {
  .theme-assets {
    float: left;
    width: 219px;
    //border-right:1px solid #efefef;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      width: 4px;
      background: transparent url("~images/inner_shadow.png") right top repeat-y;
    }
    .asset-type {
      position: relative;
      h1 {
        font-size: 13px;
        line-height: 30px;
        color: #fbffff;
        padding-right: 35px;
        margin: 0px;
        cursor: pointer;
      }
      font-size: 13px;
      line-height: 30px;
      color: #fbffff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
      font-weight: bold;
      border-top: 1px solid #878e97;
      border-bottom: 1px solid #52555a;
      background: #5f6773;
      background-image: linear-gradient(to bottom, #717985, #505863);
      padding: 0px 0px 0px 10px;
      .asset-counter {
        float: right;
        padding: 2px 9px;
        font-size: 11px;
        @include border-radius(10px);
        margin-top: 7px;
        background: #474f5b;
        @include box-shadow(inset 0 0px 2px rgba(0, 0, 0, 0.3));
        line-height: 13px;
        margin-right: 7px;
      }
      .new-asset {
        float: right;
        position: absolute;
        top: 0px;
        right: 0px;
        a {
          display: block;
          text-decoration: none;
          color: rgba(#fbffff, 0.8);
          font-size: 18px;
          font-weight: 900;
          padding: 0px 10px 3px;
          line-height: 27px;
          border-left: 1px solid rgba(#fbffff, 0.1);
          @include box-shadow(-1px 0 0 rgba(0, 0, 0, 0.1));
          &:hover {
            color: #fbffff;
          }
        }
      }
      &.current {
        border-top: 1px solid #72d1f2;
        border-bottom: 1px solid #226293;
        @include box-shadow(0 1px 1px rgba(0, 0, 0, 0.4));
        background-image: linear-gradient(to bottom, #50c4f0, #3299e1);
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
        .asset-counter {
          background: #2b73ab;
        }
      }
    }
  }
  .with-assets-sidebar {
    margin-left: 220px;
    padding-bottom: 0px;
    background: #fff;
  }
}

/*
* === Settings ===
*/

.tabbable {
  background: #f2f2f2;
  &.tabs-left {
    .tab-nav,
    .tab-content {
      float: left;
    }
  }
  &.tabs-right {
    .tab-nav,
    .tab-content {
      float: right;
    }
  }
  .tab-nav {
    width: 25%;
    a {
      display: block;
      padding: 15px 20px;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      text-decoration: none;
      font-weight: bold;
      font-size: 12px;
      color: #999;
    }
    li:first-child a {
      border-top: none;
    }
    .active a {
      background: #fff;
      position: relative;
      left: -1px;
      border-color: #e2e2e2;
      color: #6597c7;
    }
  }
  .tab-content {
    width: 75%;
    .tab-pane {
      & > section {
        min-height: 300px;
        background: #ffffff;
        border-right: 1px solid #e2e2e2;
      }
    }
  }
}

/*
* === System ===
*/

.events-list {
  span {
    display: block;
    font-size: 11px;
  }
}

.user-list {
  .user {
    line-height: 40px;
    border-bottom: 1px dotted #dddddd;

    .image {
      @include inline-block;
    }
    .name {
      @include inline-block;
      margin-top: 10px;
      margin-left: 15px;
      .attributes {
        display: block;
        color: #bbb;
        font-size: 11px;
        padding-top: 3px;
        span.separator {
          padding: 0px 3px;
          color: #ddd;
        }
      }
    }
    .actions {
      float: right;
    }
    .edit,
    .remove {
      @include inline-block;
      float: left;
      font-size: 11px;
      line-height: 50px;
      padding: 0px 5px;
      a {
        @include link-colors(#bbb, #666, #bbb, #bbb, #666);
      }
    }
  }
}

.sites-list {
  .header {
    position: relative;
    color: #333333;
    background-color: #eeeeee;
    border-bottom: 1px solid #cccccc;
    padding: 4px 10px;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 700;
  }
  .labels {
    margin-left: 25px;
    span {
      display: inline;
      font-weight: bold;
      font-size: 12px;
      padding: 5px 15px 5px 0px;
      &.name {
        margin-right: 110px;
      }
      &.dns {
        margin-right: 50px;
      }
      &.actions {
        margin-right: 20px;
        float: right;
      }
    }
  }
  .domain {
    padding: 10px;
    &.primary {
      font-weight: bold;
    }
    .protocol {
      font-size: 11px;
      color: #777;
    }
    &.subdomain {
      input {
        display: inline;
        margin-left: 0px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #aeaeae;
        width: 120px;
      }
      .maindomain {
        display: inline;
        font-size: 11px;
        color: #777;
      }
      .no-remove {
        float: right;
        color: #ccc;
        font-size: 11px;
        font-style: italic;
      }
    }
  }
  .remove a {
    margin: 0px;
  }
  .add-new-site {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-left: none;
    border-right: none;
    a {
      border-top: 1px solid #fff;
      display: block;
      background-color: #f5f5f5;
      text-decoration: none;
      font-size: 12px;
      line-height: 13px;
      padding: 1px 10px 4px;
      @include link-colors(#3084ca, #333, #333, #3084ca, #333);
      &:hover {
        background-color: #ecf2f5;
      }
      span.plus {
        position: relative;
        top: 3px;
        font-family: Arial;
        font-size: 20px;
        font-weight: 700;
        color: #417b9f;
        padding-right: 5px;
      }
    }
  }
}
.section {
  border-bottom: 1px solid #e6e6e6;
  @include pie-clearfix;
  padding: 40px 0;

  &.super-user-only {
    position: relative;
    &::before {
      font-size: 11px;
      color: #ccc;
      content: "ZENJOY INTERNAL SETTINGS";
      position: absolute;
      background-color: #fff;
      top: -8px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .section-summary {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: #000;
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: 300;
    }
    p {
      color: #777;
      line-height: 18px;
      margin-bottom: 15px;
    }
  }
}
.aligned-with-icon .section {
  padding-left: 34px;
}
.aligned-and-centered .section {
  padding-left: 34px;
  padding-right: 34px;
}
.no-content {
  position: absolute;
  left: $sidebarWidth + $mainBarWidth + 1;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-40%);
  & > i {
    font-size: 125px;
    line-height: 100px;
    color: #e5e5e5;
  }
  span {
    display: block;
    font-size: 20px;
    font-weight: 300;
    margin-top: 35px;
    &.huge {
      font-size: 35px;
      font-weight: 600;
    }
    &.submessage {
      font-size: 14px;
      font-weight: normal;
      color: #999;
    }
    &.hint {
      margin-top: 15px;
      font-size: 13px;
      font-weight: normal;
      color: #aaa;
    }
  }
  .button.mini {
    margin-top: 15px;
    margin-right: 0;

    i {
      margin: 0 5px;
    }

    & + .button {
      margin-left: 10px;
    }
  }
  &.error-page {
    span.message {
      font-size: 28px;
    }
    span.submessage {
      font-size: 16px;
    }
  }
}
.no-content-wrapper {
  position: absolute;
  left: $sidebarWidth + $mainBarWidth + 1;
  right: 0;
  top: 300px;
  text-align: center;
  bottom: 0;
  min-height: 300px;
  .no-content {
    left: 0;

    span {
      margin-top: 25px;
    }
  }
  .spinner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
  }
}
.auto-truncate {
  overflow: hidden;
}

.encrypted-blurred {
  display: inline-block;
  user-select: none;
  color: #999;
  filter: blur(4px);
  padding: 1px 5px;
  transition: color 0.2s ease-out 0s;

  &.toggleable {
    cursor: pointer;
    &:hover {
      filter: blur(3px);
      color: #555;
    }
  }
}

.encrypted-explanation {
  font-size: 12px;
  color: #999;
}

.encrypted-field {
  white-space: nowrap;

  .auto-truncate {
    display: inline-block;

    .encrypted-blurred {
      padding-top: 0;
    }
  }
}

.resque-frame {
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  position: fixed;
  left: $sidebarWidth + $mainBarWidth + 1;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
}
.table-list-item {
  padding: 10px 12px;
  border-top: 1px solid #ececec;
  &:first-child {
    border-top: none;
  }
  &:hover {
    border-color: #dfe7ee;
    & + div {
      border-color: #dfe7ee;
    }
  }
}
.email-template-name {
  @include inline-block;
  font-weight: 500;
  width: 200px;
}
.email-template {
  .remove {
    display: none;
    float: right;
    a {
      margin-top: 4px;
    }
  }
  &:hover {
    .remove {
      display: block;
    }
  }
}
.email-template-description {
  font-size: 12px;
}
.html-email-field {
  margin-top: 15px;
}
.notifications-table {
  .template {
    width: 200px;
  }
}
.preview-link {
  @include link-colors(#999, #666, #666, #999, #666);
  text-decoration: underline;
  margin-top: 10px;
  display: block;
}
.alerts-list {
  .trigger {
    float: right;
    width: 200px;
    text-align: center;
  }
  &.fancy-table-header {
    .trigger {
      padding-right: 12px;
    }
  }
  .remove a {
    float: right;
    margin: 2px 0px 0px 0px;
    @include opacity(0.7);
    @include single-transition(opacity, 0.3s, ease-out, 0s);
    &:hover {
      @include opacity(1);
      @include single-transition(opacity, 0.3s, ease-out, 0s);
    }
  }
}
.attribute-list {
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .preview-image {
    padding: 2px;
    border: 1px solid #ccc;
    margin-right: 10px;
    flex-shrink: 0;
    width: 38px;
    height: 38px;
  }

  .slug {
    margin-left: 5px;
    font-size: 11px;
    color: #999;
  }

  .info {
    flex-shrink: 1;
    flex-grow: 1;
    width: 0px;
  }

  .description {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #999;
    font-size: 11px;
  }
  .actions {
    margin-left: auto;
    flex-shrink: 0;

    a {
      font-size: 13px;
      color: #333;
      transition: all 0.3s;
      margin: 0px 0px 0px 10px;
      opacity: 0.5;
      &:hover {
        transition: all 0.15s;
        opacity: 0.8;
      }
    }
  }
}

.webhooks-list {
  .table-list-item {
    @include pie-clearfix;
    padding-left: 0;
    & > span {
      padding: 0px 12px;
    }
  }
  .status {
    float: left;
    width: 100px;
    text-align: center;
  }
  .url {
    float: left;
  }
  .types {
    float: right;
    width: 300px;
    text-align: center;
  }
  &.fancy-table-header {
    .types {
      padding-right: 50px;
    }
  }
  .actions {
    float: right;
  }
  .remove a {
    float: left;
    margin: 2px 0px 0px 10px;
    @include opacity(0.7);
    @include single-transition(opacity, 0.3s, ease-out, 0s);
    &:hover {
      @include opacity(1);
      @include single-transition(opacity, 0.3s, ease-out, 0s);
    }
  }
  .edit a {
    float: left;
    margin-top: 2px;
    color: #333;
    overflow: hidden;
    display: block;
    @include opacity(0.7);
    @include single-transition(opacity, 0.3s, ease-out, 0s);
    &:hover {
      @include opacity(1);
      @include single-transition(opacity, 0.3s, ease-out, 0s);
    }
  }
}

.content-header {
  background: #eff0f5;
  @include pie-clearfix;
  border-top: 1px solid #b5bfc7;
  border-bottom: 4px solid #a5b7cf;
  position: relative;
  padding: 30px;
  //text-shadow: 0 1px 0 #fff;
  position: relative;
  z-index: 2;
  color: #56636e;
  &.no-border {
    border-bottom: none;
  }
  a {
    @include link-colors(#586570, #444e56, #586570, #586570, #444e56);
  }
  h1 {
    color: #444e57;
    text-shadow: 0 1px 0 #fff;
    font-weight: 600;
    font-size: 22px;
  }
  .status {
    @include inline-block;
    @include border-radius(3px);
    letter-spacing: 0;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 10px;
    padding: 3px;
    border: 1px solid #bac3d1;
    background: #f2f5fa;
    background-image: linear-gradient(to bottom, #fff, #f6f9fe 5%, #e9edf2 95%, #e1e4e9 100%);
    color: #9db5c4;
    &.status-active {
      background: #d0e4c8;
      color: #3e6330;
      border: 1px solid #77b261;
    }
    &.status-coming_soon {
      color: #fff;
      background-color: #8647e4;
      border: 1px solid #490198;
      text-shadow: 0px 1px 0px #630f79;
      @include box-shadow(inset 0px 1px 2px 0px #af61ef);
      background-image: linear-gradient(to bottom, #8647e4 0%, #801ad0 100%);
    }
    &.status-sold_out {
      background-color: #696969;
      border: 1px solid #343434;
      color: #fff;
      text-shadow: 0px 1px 0px #000;
      @include box-shadow(inset 0px 1px 2px 0px #5c5c5c);
      background-image: linear-gradient(to bottom, #696969 0%, #5c5c5c 100%);
    }
  }
  .scope {
    @include inline-block;
    @include border-radius(3px);
    letter-spacing: 0;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 10px;
    padding: 3px;
    border: 1px solid #bac3d1;
    background: #f2f5fa;
    background-image: linear-gradient(to bottom, #fff, #f6f9fe 5%, #e9edf2 95%, #e1e4e9 100%);
    color: #9db5c4;
    &.scope-retail,
    &.scope-mobile,
    &.scope-online {
      color: #fff;
      background-color: #47a4e4;
      border: 1px solid #015398;
      text-shadow: 0px 1px 0px #1851c6;
      @include box-shadow(inset 0px 1px 2px 0px #4598ec);
      background-image: linear-gradient(to bottom, #47a4e4 0%, #1a73d0 100%);
    }
  }
  .essentials {
    @include pie-clearfix;
    margin-left: 250px;
    .copy-url {
      position: relative;
      top: -4px;
      font-size: 15px;
    }
    .attribute {
      line-height: 22px;
      margin-left: 10px;
    }
    .amount {
      font-weight: bold;
      vertical-align: bottom;
    }
    .on-sale {
      vertical-align: bottom;
      font-size: 11px;
      text-decoration: line-through;
    }
    .label {
      vertical-align: middle;
    }
    i {
      color: #a4afb7;
      &:before {
        color: #a4afb7;
      }
      width: 20px;
      padding-right: 10px;
      position: relative;
      bottom: -2px;
    }
    &.no-image {
      margin-left: 0px;
    }
    .price {
      font-size: 16px;
    }
  }
  .photo {
    float: left;
    margin-right: 40px;
    width: 200px;
    padding: 6px;
    background: #fff;
    @include box-shadow(0 1px 2px rgba(0, 0, 0, 0.3));
    img {
      border: 1px solid #d5dbdf;
      display: block;
    }
  }
  &.customer {
    .photo {
      max-width: 100px;
    }
    .essentials {
      margin-left: 140px;
    }
  }
}
.content-tabs {
  @include pie-clearfix;
  position: relative;
  .tab-filler {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.tab,
.tab-filler {
  float: left;
  a {
    border-right: 1px solid #c8d6df;
    position: relative;
    z-index: 2;
    display: block;
    font-size: 13px;
    line-height: 13px;
    @include link-colors(#a5b3c6, #899bb4, #a5b3c6, #a5b3c6, #899bb4);
    text-transform: uppercase;
    text-shadow: 0 1px 0px #fff;
    font-weight: 700;
  }
  span {
    display: block;
    &.border-top {
      height: 6px;
      background: #a9bad1;
      background-image: linear-gradient(to bottom, #adbdd3, #a1b3cd);
      border-bottom: 1px solid #9fb2cc;
    }
    &.link {
      border-top: 1px solid #ffffff;
      background: #f1f3f8;
      background-image: linear-gradient(to bottom, #fbfcfe, #eceef3);
      border-bottom: 1px solid #c9d8e2;
      padding: 0px 25px;
      line-height: 43px;
      min-height: 45px;
    }
  }
  a span.link:hover {
    background: lighten(#f1f3f8, 3%);
    background-image: linear-gradient(to bottom, lighten(#fbfcfe, 3%), lighten(#eceef3, 3%));
  }
  a.current {
    position: relative;
    z-index: 3;
    @include link-colors(#737c93, #737c93, #737c93, #737c93, #737c93);

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 4px;
      background-image: linear-gradient(to left, rgba(#000, 0.1), rgba(#ebedf2, 0));
      left: -5px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 4px;
      background-image: linear-gradient(to right, rgba(#000, 0.1), rgba(#ebedf2, 0));
      right: -5px;
    }
    span {
      &.border-top {
        background: #a9bad1;
        background-image: linear-gradient(to bottom, #6bbbef, #5cb1ec);
        border-bottom: 1px solid #49a5e8;
      }
      &.link {
        background: #fff;
        border-top: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
      }
    }
  }
}

.static-content-tabs {
  .tab {
    text-align: center;
    float: none;
    list-style-type: none;
    margin: 0;
    display: table-cell;
    width: 1%;
  }
}

.content-panes {
  .columns {
    padding: 0 25px;
  }
  .section {
    padding: 25px 0;
  }
}
.settings-title {
  margin-top: 8px;
  font-size: 20px;
  line-height: 20px;
  margin: 0;
  font-weight: 400;
  strong,
  b {
    font-weight: 600;
  }
}
.account-stats {
  margin-bottom: 20px;
  .limit {
    font-style: 90%;
    color: #999;
  }
  .stats-summary {
    margin-bottom: 4px;
  }
  .stats-bar {
    background: #eee;
    background-color: rgb(236, 236, 236);
    height: 18px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.25), inset 0px 1px 3px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.25), inset 0px 1px 3px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.25), inset 0px 1px 3px rgba(0, 0, 0, 0.5);
  }

  .progress {
    display: block;
    background-image: linear-gradient(
      90deg,
      rgb(157, 184, 207) 0%,
      rgb(157, 184, 207) 1%,
      rgb(184, 204, 221) 99%,
      rgb(184, 204, 221) 100%
    );
    height: 18px;
    border-radius: 3px;
    box-shadow: inset 0px 1px 3px rgb(40, 71, 104);
  }
}
.users-list {
  .image {
    width: 60px;
  }
  .name-link {
    font-weight: 600;
  }
  .email a {
    font-size: 12px;
    @include link-colors(#999, #555, #999, #999, #555);
  }
  .user.internal {
    opacity: 0.7;
    a {
      @include link-colors(#999, #555, #999, #999, #555);
    }
  }
  .two-step-verification {
    text-align: center;

    .two-step-status.disabled {
      color: #999;
    }
  }
}
.sites-list {
  .name-link {
    font-weight: 600;
  }
  .primary-domain a {
    font-size: 12px;
    @include link-colors(#999, #555, #999, #999, #555);
  }
}
$photosize: 40px;
.inset-photo {
  @include border-radius($photosize);
  display: block;
  position: relative;
  width: $photosize + 2;
  height: $photosize + 2;
  margin: 0;
  overflow: hidden;
  &:after {
    @include border-radius(4px);
    @include box-shadow(inset 0 0 3px 0px rgba(0, 0, 0, 0.95));
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &:after,
  &:before {
    @include border-radius($photosize);
    width: $photosize + 2;
    height: $photosize + 2;
  }

  img {
    @include border-radius($photosize);
    width: $photosize + 2;
    height: $photosize + 2;
  }
}

.app-list {
  .app {
    padding: 20px;
    border: 1px solid #dddddd;
    background: #fff;
    border-radius: 6px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    position: relative;

    .app-info {
      margin-right: auto;
    }

    .app-shadow {
      bottom: 0;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
      filter: blur(6px);
      height: 94%;
      left: 1.5%;
      position: absolute;
      width: 97%;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.25s ease-out;
    }

    h4 {
      font-size: 15px;
      margin-bottom: 10px;
    }
    .description {
      font-size: 13px;
      color: #999;
      margin-bottom: 10px;
    }
    .app-url {
      font-weight: bold;
      display: block;
    }
    .access-level {
      color: #999;
      font-size: 12px;
      line-height: 15px;
    }
    .stats {
      color: #767676;
      font-size: 12px;
    }
    .links {
      font-size: 13px;
      line-height: 15px;
      white-space: nowrap;
      a {
        padding-left: 10px;
      }
      .revoke {
        @include link-colors(#e8382d, #e8382d, #e8382d, #e8382d, #e8382d);
      }
    }
    .oauth2-badge {
      opacity: 0.2;
      height: 50px;
      width: 50px;
      background: transparent url("~images/oauth2-badge.png") center center no-repeat;
      background-size: 50px 50px;
      transition: opacity 0.25s ease-out;
    }

    &:hover {
      .oauth2-badge {
        opacity: 0.45;
      }

      .app-shadow {
        opacity: 1;
      }
    }
  }
}
.video-list {
  td.thumb {
    width: 120px;
    padding: 10px;
    &:first-child {
      padding-left: 10px;
    }
  }
  .video-thumb {
    height: 58px;
    min-height: 58px;
    overflow: hidden;
    img {
      display: block;
      height: 58px;
      padding: 2px;
      border: 1px solid #ccc;
    }
  }
}
.menus-table {
  td.name {
    font-weight: 600;
    a {
      text-decoration: underline;
    }
  }
  td.target {
    color: #646464;
  }
}
#main {
  .mce-panel {
    border: 0 solid #dddddd;
    background: #fafafa;
  }
  .mce-ico {
    font-size: 14px;
  }
  .mce-btn button {
    border: none;
  }
  .mce-edit-area {
    padding: 15px;
    background: #fff;
  }
  .mce-flow-layout-item {
    padding: 1px;
  }
  .mce-btn-group {
    border-left: 1px solid #dddddd;
  }
  .mce-btn-group .mce-first {
    @include border-radius(0px);
  }
  .mce-btn {
    border: none;
    @include box-shadow(none);
    background: transparent;
    button {
      padding: 3px 6px;
      font-size: 13px;
      &.mce-open {
        padding: 3px 5px 3px 0;
      }
    }
    &:hover {
      background: #ececec;
    }
  }
}
.mce-menu-item-sep,
.mce-menu-item-sep:hover {
  border-bottom: none !important;
}
.mce-tab {
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}
.mce-abs-layout,
.mce-floatpanel {
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}
.mce-textbox.mce-multiline {
  font-family: monospace !important;
}
.subdomain-wrapper {
  position: relative;
  .protocol {
    position: absolute;
    top: 53px;
    left: 11px;
    font-size: 11px;
    color: #7d7d7d;
  }
  .tld {
    position: absolute;
    top: 53px;
    right: 11px;
    font-size: 11px;
    color: #7d7d7d;
  }
}
.status-indicator {
  @include inline-block;
  width: 14px;
  margin: 0 18px;
  height: 14px;
  vertical-align: middle;
  @include border-radius(8px);
  background: #2c323e;

  span {
    display: block;
    margin: 3px;
    vertical-align: middle;
    width: 8px;
    height: 8px;
    @include border-radius(5px);
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    opacity: 1;
    background: #84878f;
    @include single-transition(background-color, 0.7s, ease-out);
  }
  &.live span {
    background-color: #31654b;
  }
  &.hit span {
    background-color: #a9e200;
    @include single-transition(background-color, 0.1s, ease-out);
  }
}
.no-content-yet {
  font-size: 18px;
  color: #999;
}
.backend-order-products-table {
  dl {
    dt {
      float: left;
      clear: left;
      width: 150px;
      color: #777;
      text-align: right;
    }
    dd {
      float: left;
      margin-left: 10px;
    }
  }
  .with-extras {
    td {
      border-bottom: none;
    }
  }
  .item-extras {
    td {
      padding-top: 0;
    }
    .sub-section {
      margin-top: 0;
    }
  }
  .price > span {
    display: inline-block;
    padding-bottom: 2px;
    border-bottom: 1px dotted #333;
    cursor: pointer;
  }
  .price input {
    text-align: center;
  }
  .price,
  .quantity,
  .subtotal {
    text-align: center;
    width: 100px;
  }
  .total {
    &.label {
      font-weight: bold;
      text-align: right;
    }
    &.price {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .links {
    width: 50px;
  }
  .product-variant-field {
    display: none;
  }
  .product.with-variants {
    .product-field {
      float: left;
      width: 63%;
    }
    .product-variant-field {
      display: block;
      width: 35%;
      margin-left: 2%;
      float: left;
    }
  }
  .remove a {
    margin-top: 0px;
  }
}
.backend-order-fieldset {
  padding: 25px;
  background: #fcfcfc;
  border: 1px dotted #ccc;
}
.backend-order-use-billing {
  margin-bottom: 25px;
}
.columns.widescreen.with-table {
  padding: 0 10px;
}
.app-tokens {
  strong {
    display: block;
    color: #999;
  }
  span.token {
    font-family: monospace;
    display: block;
    font-size: 13px;
    margin-bottom: 10px;
  }
}
.application-log-context {
  .tipsy-inner {
    max-width: 999px;
  }
}
.log-entry {
  font-family: monospace;
  &.highlight {
    background-color: #ffffe0;
    padding-left: 25px;
    padding-right: 25px;
    margin-left: -25px;
    margin-right: -25px;
  }
  .timestamp,
  .level,
  .context,
  .data {
    display: block;
  }
  .timestamp,
  .context,
  .level {
    float: left;
  }
  .context {
    opacity: 0.4;
    transition: opacity 0.5s ease-out;
    padding-right: 10px;

    i {
      color: #999;
    }

    &:hover {
      transition: opacity 0.2s ease-out;
      opacity: 1;
    }
  }
  .data {
    margin-left: 270px;
    pre {
      display: inline;
      vertical-align: bottom;
    }
  }
  .timestamp {
    color: #999;
    margin-right: 10px;
  }
  .level {
    min-width: 60px;
    color: #999;
    &.info {
      color: #6fa80c;
    }
    &.warn {
      color: #e86d11;
    }
    &.error {
      color: #e81e11;
    }
  }
}
.debug-data pre {
  display: inline;
}

.ui-datepicker {
  font-family: $primaryFont;
  z-index: 20 !important;
  background: #fff;
  border: 1px solid #66afe9;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
  display: none;
  margin-top: 4px;
  padding: 5px 10px 6px;
  width: 240px;

  td span,
  td a {
    text-align: center;
  }

  .ui-datepicker-prev-hover {
    left: 2px;
    top: 6px;
    background: transparent;
  }

  .ui-datepicker-prev {
    cursor: pointer;
    position: absolute;
    background: transparent;
    border: none;
    top: 6px;
    left: 2px;

    span {
      display: none;
    }

    &:before {
      text-indent: 0px;
      @include fa-icon;
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
      font-size: 18px;
      position: absolute;
      left: 0;
      top: 0;
      line-height: 18px;
      color: #b6b6b6;
      content: fa-content($fa-var-caret-circle-left);
    }

    &.ui-state-hover {
      border: none;

      &:before {
        color: $mainColor;
      }
    }
  }

  .ui-datepicker-next-hover {
    right: 2px;
    background: transparent;
    top: 6px;
  }

  .ui-datepicker-next {
    cursor: pointer;
    position: absolute;
    background: transparent;
    top: 6px;
    right: 2px;
    border: none;

    span {
      display: none;
    }

    &:before {
      text-indent: 0px;
      @include fa-icon;
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
      font-size: 18px;
      position: absolute;
      right: 0;
      top: 0;
      line-height: 18px;
      color: #b6b6b6;
      content: fa-content($fa-var-caret-circle-right);
    }

    &.ui-state-hover {
      border: none;

      &:before {
        color: $mainColor;
      }
    }
  }

  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default {
    color: $mainColor;
    border-color: lighten($mainColor, 45%);
    background: transparent;
  }

  .ui-datepicker-header {
    background: #fff;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
  }

  .ui-datepicker-week-end a {
    color: lighten($mainColor, 20%);
  }

  .ui-datepicker-today a {
    background: lighten($mainColor, 50%);
    color: darken($mainColor, 15%);
    border-color: lighten($mainColor, 40%);
  }

  .ui-datepicker-current-day a {
    background: $mainColor;
    color: #fff;
    border-color: $mainColor;
  }
}

.notification-preview {
  width: 750px;

  iframe {
    border: 1px solid #ccc;
    width: 100%;
    height: 500px;
    margin: auto;
    transition: width 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  }

  .mail-body {
    background-color: #f6f6f6;
    display: flex;
  }

  .responsive-switcher {
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background-color: #fff;
      appearance: none;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;

      i {
        color: rgba(#111111, 0.65);
      }

      &:active,
      &:focus {
        outline: none;
      }

      &.active,
      &:hover {
        background-color: #f6f6f6;

        i {
          color: $mainColor;
        }
      }
    }
  }

  .mail-properties {
    .property {
      border-bottom: 1px solid #ccc;
      display: flex;
      align-items: center;

      label {
        width: 110px;
        text-align: right;
        flex-shrink: 1;
        font-weight: bold;
        padding: 3px;
      }

      .value {
        min-height: 50px;
        padding: 5px;
        display: flex;
        align-items: center;
        flex-grow: 1;

        .selectize-control {
          flex-grow: 1;
        }
      }

      &.preview-object-json {
        label {
          width: 160px;
        }

        .value {
          position: relative;

          input {
            font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
            font-size: 11px;
            padding-right: 30px;
          }
        }

        .refresh-data {
          position: absolute;
          right: 14px;
          top: 14px;
          font-size: 16px;
          color: #000;
          transition: opacity 0.3s ease-out;
          opacity: 0.5;

          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
}
#payment_methods_form {
  min-width: 500px;
  max-width: 500px;
  li {
    position: relative;
    padding: 20px;
    border-top: 1px solid #eaeaea;
    a {
      color: #333233;
      text-decoration: none;
    }
    &:nth-child(odd) {
      background-color: #f9f9f9;
    }
    &:first-child {
      border-top: none;
    }
    form {
      background: transparent !important;
    }
  }
  .submit {
    position: absolute;
    top: 50%;
    margin-top: -15px;
    right: 25px;
  }
  .description {
    padding-right: 110px;
  }
  label {
    display: none;
  }
  h1 {
    font-size: 16px;
    margin: 0 0 5px;
  }
  .short {
    color: #666;
    font-size: 13px;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.payment-method,
.integration {
  position: relative;
  border: 1px solid #dee5eb;
  background: #f9f9f9;
  padding: 15px 20px 0;
  margin-bottom: 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  &:last-child {
    margin-bottom: 0;
  }
  &.test-mode {
    background: #f9f9f9 url("~images/testmode.png") top center repeat-x;
  }
  .description {
    position: relative;

    fieldset {
      background-color: rgba(0,0,0, 0.03);
      padding: 15px;
      border: 1px rgba(0,0,0, 0.3) dotted;
      border-radius: 4px;

      legend {
        font-size: 15px;
        font-weight: 600;
        background-color: #f9f9f9;
        color: rgba(0,0,0, 0.8);
        padding: 3px 10px;
      }

      & + fieldset {
        margin-top: 15px;
      }
    }

    legend.divider {
      display: flex;
      font-size: 15px;
      font-weight: 600;
      color: rgba(0,0,0, 0.8);
      margin-bottom: 25px;
      margin-top: 25px;
      white-space: nowrap;
      align-items: center;

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        min-width: 15px;
        background-color: rgba(0,0,0, 0.1);
        margin-right: 10px;
      }

      &:after {
        content: "";
        flex-grow: 1;
        display: block;
        width: 100%;
        height: 1px;
        background-color: rgba(0,0,0, 0.1);
        margin-left: 10px;
      }
    }

    ol {
      list-style-type: decimal;
      margin-left: 25px;
      li {
        list-style-type: decimal;
      }
    }

    .wrapper {
      .description {
        line-height: 18px;
        margin-bottom: 10px;
      }

      pre {
        display: inline;
        font-size: 90%;
        white-space: pre-wrap;       /* Since CSS 2.1 */
        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
        white-space: -pre-wrap;      /* Opera 4-6 */
        white-space: -o-pre-wrap;    /* Opera 7 */
        word-wrap: break-word;       /* Internet Explorer 5.5+ */
        word-break: break-all;
      }
    }
  }
  .configuration {
    border-top: 1px solid #e6e6e6;
    padding-top: 25px;
    margin-top: 15px;

    .input + .input.vertical {
      margin-top: 15px;
    }
  }
  .heading {
    display: block;
    border-bottom: 1px solid #e6e6e6;
    color: #191919;
    margin: 0 0 12px;
    font-size: 13px;
    padding: 0 0 4px;
    font-weight: 700;
  }
  .fields {
    border: 1px solid #e6ebed;
    background: #fff;
    padding: 15px;
    margin-bottom: 10px;
    &.test-mode {
      background: #fff url("~images/testmode.png") top center repeat-x;
      .input {
        margin-bottom: 0;
      }
    }
  }

  input[type="text"],
  textarea {
    background: #fff;
  }
  form.nimbu {
    label {
      margin-bottom: 5px;
      max-width: calc(180px - 1%);

      &:after {
        content: "";
      }

      i.ss-icon {
        padding-left: 2px;
        font-size: 13px;
        position: relative;
        top: 2px;
        padding-top: 5px;
        color: #bbb;
        &:hover {
          color: #888;
        }
      }
    }
  }
  .actions {
    margin-top: 15px;
  }
  .name {
    font-weight: bold;
    color: #191919;
    font-size: 15px;
    margin-bottom: 15px;
    margin-left: 80px;
  }
  p {
    line-height: 22px;
    font-size: 14px;
    color: #666;
  }
  .links {
    position: absolute;
    top: 0px;
    right: -10px;
  }
  .state {
    float: left;
  }
  .settings a {
    overflow: hidden;
    color: #161616;
    width: 16px;
    height: 16px;
    margin: 4px 5px 0px 10px;
    display: block;
    opacity: 0.5;
    &:hover {
      opacity: 0.8;
    }
  }
  .destroy {
    float: left;
    font-size: 13px;
    line-height: 28px;
    a {
      @include link-colors(#666, #000, #666, #666, #000);
    }
  }
  .actions {
    margin-left: -20px;
    margin-right: -20px;
    @include border-bottom-radius(4px);
  }
}
.article-list {
  .visibility,
  .blog,
  .published_at {
    text-align: center;
  }
  .published_at {
    color: #999;
    font-size: 13px;
  }
}
.backend-order-customer-info {
  .info {
    @include pie-clearfix;
    margin-bottom: 15px;
  }
  .info-item {
    float: left;
    width: 200px;
    margin-right: 25px;
  }
  .item-label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .link {
    font-size: 12px;
  }
}

.image-preview {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px dotted #aaa;
  margin-bottom: 30px;
  img {
    max-height: 50px;
    margin-bottom: 10px;
  }
  .remove {
    font-size: 11px;
    color: #999;
  }
  .txt {
    vertical-align: middle;
  }
}
.preview-link-valid-for {
  width: 50px !important;
  margin-right: 5px !important;
}

#login_history_wrapper {
}

.two-step-status {
  i.two-step-dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 5px;
    border-radius: 6px;
    background: #aaa;
    position: relative;
    top: -1px;
    &.enabled {
      background-color: #48a23e;
    }
    &.disabled {
      background-color: #ff7f62;
    }
  }
  &.enabled {
    b {
      color: #48a23e;
    }
  }
  &.disabled {
    b {
      color: #ff7f62;
    }
  }
}

.encrypted-icon.fas.fa-lock {
  color: #8496a4;
}

.turbolinks-progress-bar {
  background-color: $mainColor;
}

.button-group {
  &:empty {
    margin: 0;
    display: none;
  }

  .fas.fa-angle-left {
    text-align: center;
    margin-right: -6px;
    &:before {
      font-size: 16px;
    }
  }

  .fas.fa-angle-right {
    text-align: center;
    margin-right: -9px;
    margin-left: 3px;

    &:before {
      font-size: 16px;
    }
  }
}

.nested-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    
    & > span {
      display: inline-block;
      min-width: 24px;
      height: 24px;
      color: rgba(0,0,0,.85);
      line-height: 24px;
      text-align: center;
      vertical-align: middle;
      list-style: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all .3s;
      font-weight: 500;
      color: #56636e;

      &.current {
        padding-left: 4px;
        padding-right: 4px;
        cursor: default;
      }

      a {
        display: block;
        min-width: 24px;
        padding-left: 4px;
        padding-right: 4px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        color: #56636e;
      }

      &:hover {
        background: #eff0f5;
        color: darken(#56636e, 15%)
      }

      &.current {
        font-weight: bold;
        color: white;
        background-color: #A7B8D0;
        margin: 0 3px;

        &:hover {
          color: white;
          background-color: #A7B8D0;
        }
      }
    }
  }
}

.field-name-tooltip {
  code {
    background: inherit;
    color: #fff;
    font-weight: bold !important;
  }
}

.channel-page-info {
  code {
    background: #eee;
    color: #000;
    font-size: 90%;
    font-weight: normal;
  }
}


.reference-many-total {
  font-size: 11px;
  opacity: 0.5;
}

.multi-select-item {
  &:after {
    content: ', ';
    display: inline;
    opacity: 0.6;
  }
  &:last-child:after {
    content:'';
  }
}

.selectize-dropdown .page-select-option {
  display: flex;
  align-items: center;
  width: 100%;

  .option-title-wrapper {
    display: block;
    flex-shrink: 2;
    padding: 0 5px;
    min-width: 0;
  }
  
  .page-depth {
    font-family: 'Courier New', Courier, monospace;
    color: #ccc;
    padding-right: 3px;

    &:empty {
      padding-right: 0px;
    }
  }

  .option-title {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0; // hack to make ellipsis work
  }

  .option-url {
    font-size: 11px;
    line-height: 13px;
    opacity: 0.5;
    padding-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0; // hack to make ellipsis work
  }
}

.page-select-item {
  display: flex;
  align-items: center;
  width: 100%;

  .option-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    flex-shrink: 1;
  }
  .option-url {
    flex-shrink: 99;
    line-height: 13px;
    opacity: 0.5;
    min-width: 0;
    white-space: nowrap;
    padding-left: 10px;
    padding-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0; // hack to make ellipsis work
  }
}

.selectize-input {
  .page-select-item {
    width: unset;
  }
}
#new_page {
  .body {
    max-width: 400px;
  }
}

#slug_field {
  position: relative;
  input {
    padding-right: 30px;
  }
  .copy-full-url {
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 10px;
    opacity: 0.4;
    transition: opacity 0.2s ease-out 0s;
    &:hover {
      opacity: 1;
    }
  }
}

.copy-url-wrapper {
  .copy-url {
    cursor: pointer;
    padding-left: 5px;
    opacity: 0.4;
    transition: opacity 0.2s ease-out 0s;
    &:hover {
      opacity: 1;
    }
  }
}
