@import "base";
@import "../vendor/icons/font-awesome-pro/scss/_functions.scss";
@import "../vendor/icons/font-awesome-pro/scss/_variables.scss";
@import "../vendor/icons/font-awesome-pro/scss/_mixins.scss";

@import "~compass-mixins/lib/compass/css3/user-interface";
@import "~compass-mixins/lib/compass/layout/sticky-footer";
@import "~compass-mixins/lib/compass/utilities/general/clearfix";
@import "~compass-mixins/lib/compass/typography/links/link-colors";
@import "~compass-mixins/lib/compass/css3/inline-block";
@import "~compass-mixins/lib/compass/css3/box-sizing";
@import "~compass-mixins/lib/compass/css3/border-radius";
@import "~compass-mixins/lib/compass/css3/box-shadow";
@import "~compass-mixins/lib/compass/css3/transition";
@import "~compass-mixins/lib/compass/css3/opacity";
@import "~compass-mixins/lib/compass/css3/images";

/*
* === Products ===
*/

@-webkit-keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
}

@-moz-keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
}

// Place all the styles related to the Admin/Products controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

form.nimbu .section-content {
  .wrapper.date-time {
    @include pie-clearfix;
    overflow: visible;
    input {
      float: left;
      margin-right: 10px;
      width: 100px;
      &.time-field {
        width: 60px;
      }
    }
    &.with-margin {
      margin-left: 30px;
      margin-bottom: 15px;
    }
  }
}
p.no-content {
  margin-bottom: 30px;
  font-size: 12px;
}
.product-images {
  @include pie-clearfix;
  position: relative;
  .image {
    position: relative;
    float: left;
    border: 1px solid #dbdada;
    padding: 5px;
    margin-right: 15px;
    margin-bottom: 15px;
    background: #fff;
    @include box-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
    img {
      border: 1px solid lighten(#dbdada, 5%);
      max-width: 180px;
      max-height: 180px;
    }
  }
}
.clearfix {
  @include pie-clearfix;
}

.product-collections {
  .collection-list {
    padding: 0px 15px;
    li {
      margin-left: 15px;
      list-style: disc;
    }
  }
}

.product-properties {
  margin-bottom: 30px;
  .property {
    @include pie-clearfix;
    margin-bottom: 8px;
  }
  .label {
    float: left;
    text-align: right;
    font-weight: bold;
    width: 150px;
    padding-right: 15px;
  }
  .value {
    margin-left: 165px;
  }
}

form.product {
  .current-stock {
    input[type="text"] {
      width: 100px;
    }
  }
  .hint + .sub-section-content {
    margin-top: 15px;
  }

  .collection-list {
    padding: 15px 15px 0px;
  }
  span.hint {
    font-size: 11px;
    color: #999;
    display: block;
  }
  span.error {
    font-size: 11px;
    padding-left: 3px;
    display: block;
    color: #f43326;
  }
  .custom-fields-labels {
    margin-left: 25px;
    span {
      display: inline;
      font-weight: bold;
      font-size: 12px;
      padding: 5px 15px 5px 15px;
      &.name {
        margin-right: 110px;
      }
      &.kind {
        margin-right: 50px;
      }
      &.field-hint {
        margin-right: 180px;
      }
    }
  }
  .add-custom-field {
    margin-bottom: 10px;
    border-left: none;
    border-right: none;
    a {
      display: block;
      text-decoration: none;
      font-size: 13px;
      line-height: 13px;
      padding: 10px 25px;
      @include link-colors(#3084ca, #333, #333, #3084ca, #333);
      span.plus {
        position: relative;
        top: 3px;
        font-family: Arial;
        font-size: 20px;
        font-weight: 700;
        color: #417b9f;
        padding-right: 5px;
      }
    }
  }
  footer {
    border-top: 1px solid #d4dde1;
    @include border-bottom-radius(5px);
    @include pie-clearfix;
    @include box-shadow(0 -1px 2px rgba(0, 0, 0, 0.1));
    background-color: #f5f6f7;
    padding: 5px 10px;
    .buttons {
      float: right;
    }
    .cancel,
    .submit {
      float: left;
    }
    .cancel {
      margin-right: 10px;
    }
  }
}

.product-list {
  .price,
  .stock,
  .updated_at,
  .sold,
  .incart {
    text-align: center;
  }
  .actions {
    text-align: right;
  }
  .product-status {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -8px;
  }
  .product-with-status {
    position: relative;
    padding-right: 100px;
  }
  .product {
    .name {
      a {
        color: #2281cf;
      }
    }
    .image {
      padding: 0px 0px 0px 25px;
      overflow: hidden;
      .product-thumb {
        padding: 0px;
        overflow: hidden;
        width: 56px;
        height: 56px;
      }
      img {
        width: 56px;
        height: 56px;
        display: block;
        &.no-compress {
          height: 56px;
          width: auto !important;
          max-width: none !important;
        }
      }
    }
    .price,
    .stock,
    .updated_at,
    .sold,
    .incart {
      padding-top: 18px;
    }
    .stock,
    .sold,
    .incart {
      font-size: 13px;
      &.infinity {
        font-size: 20px;
      }
    }
    .updated_at {
      font-size: 11px;
    }
  }
  //  .stock {
  //    min-width: 150px;
  //  }
  .price,
  .stock {
    text-align: center;
  }
  .attributes {
    display: block;
    font-size: 11px;
    .separator {
      color: #000;
    }
  }
  .edit,
  .delete {
    @include inline-block;
    float: left;
    font-size: 11px;
    line-height: 20px;
    margin-left: 10px;
    a {
      @include link-colors(#bbb, #666, #bbb, #bbb, #666);
    }
  }
}
.custom-field-table {
  margin: 30px 15px 30px;
  .custom-field-table-header {
    background-image: linear-gradient(to bottom, #f7f7f7 0%, #f0f0f0 100%);
    border: 1px solid #e3e3e3;
    color: #666666;
    font-size: 10px;
    padding: 2px 9px;
    position: relative;
    text-shadow: 0 1px 0 #ffffff;
    text-transform: uppercase;
    padding-left: 26px;
    &:after {
      border-top: 1px solid #ffffff;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    span {
      padding: 5px 15px;
      @include inline-block;
      width: 120px;
      &.field-label {
        width: 183px;
      }
      &.field-url {
        width: 350px;
      }
    }
  }
  .custom-field-form-wrapper {
    border-bottom: none;
    overflow: visible;
    padding: 0 !important;
  }
  form.custom-field-form {
    padding: 0px;
    background: #fffef8;
    border-top: 1px solid #d9c9a7;
    border-bottom: 1px solid #d9c9a7;
    & > div {
      padding: 15px 15px 0px;
      &.actions {
        background: #e0e0e0;
        margin-top: 15px;
        padding-bottom: 15px;
        @include border-radius(0px);
      }
    }
    label {
      padding-bottom: 5px;
      margin: 0;
      //float:left;
      font-size: 12px;
      line-height: 12px;
      //text-align: right;
      font-weight: bold;
      color: #666666;
      abbr {
        display: none;
      }
    }

    // Checkboxs and radio buttons
    input[type="checkbox"],
    input[type="radio"] {
      cursor: pointer;
    }

    // Inputs, Textareas, Selects
    input[type="text"],
    input[type="password"],
    textarea,
    select,
    .uneditable-input,
    .selector,
    .uploader {
      display: block;
      margin-bottom: 0;
    }
    .hint {
      font-size: 11px;
      color: #aaa;
      padding-top: 5px;
    }
    input[type="text"],
    input[type="password"] {
      width: 300px;
      padding: 4px;
      font-size: 15px;
      color: #000;
      font-weight: bold;
      &.secondary {
        width: 180px;
        color: #444;
        font-size: 13px;
      }
      &.full-width {
        width: 100%;
        @include box-sizing(border-box);
      }
    }
    .name {
      vertical-align: top;
      .input {
        @include inline-block;
        vertical-align: top;
        margin-right: 10px;
        margin-bottom: 0;
      }
    }
    .kind {
      vertical-align: top;
      .input {
        vertical-align: top;
        @include inline-block;
        margin-right: 10px;
        margin-bottom: 0;
      }
    }
    .help-inline {
      font-weight: normal;
      font-size: 11px;
    }

    .edit-categories,
    .edit-multi-categories {
      display: block;
      padding: 0px 10px;
      margin: 10px 30px 5px 10px;
      font-size: 11px;
      border-left: 2px solid #e1e1e1;
      @include pie-clearfix;
    }
    .add-categories {
      display: block;
      padding: 5px;
      font-size: 11px;
    }
    .category-field {
      z-index: 10;
      position: relative;
      margin-bottom: 5px;
      .input {
        float: left;
      }
      label {
        font-size: 11px;
      }
      .wrapper {
        @include inline-block;
        input {
          font-size: 11px;
          width: 250px;
          margin-left: 10px;
          font-weight: normal;
        }
      }
      .category-drag-handle {
        cursor: move;
        display: block;
        float: left;
        margin: 6px 4px 0 10px;
      }
      .destroy-category a {
        float: left;
        display: block;
        overflow: hidden;
        text-indent: -9999px;
        background: transparent url("~images/trash.gif") center center no-repeat;
        display: block;
        width: 16px;
        height: 16px;
        margin: 4px 0 0;
        @include opacity(0.5);
        @include single-transition(opacity, 0.3s, ease-out, 0s);
        &:hover {
          @include opacity(1);
          @include single-transition(opacity, 0.3s, ease-out, 0s);
        }
      }
      @include pie-clearfix;
    }
    &[disabled] {
      .ajax-loader {
        display: block;
      }
      .button,
      button,
      input[type="submit"] {
        background: none repeat scroll 0 0 #e4e8eb !important;
        border: 1px solid #c2ced3 !important;
        color: #aebac0 !important;
        cursor: default;
        text-shadow: none !important;
        &:active {
          background: none repeat scroll 0 0 #e4e8eb !important;
          border: 1px solid #c2ced3 !important;
          color: #aebac0 !important;
          cursor: default;
          text-shadow: none !important;
        }
      }
    }
  }
  .redirect-list {
    overflow: auto;
    font-size: 12px;
    & > li {
      background: #f7f7f7;
      @include single-transition(background-color, 0.3s, ease-out);
      &:hover {
        background: lighten(#f7f7f7, 2%);
        @include single-transition(background-color, 0.3s, ease-out);
        span {
          color: #888;
          @include single-transition(color, 0.3s, ease-out);
          @include single-transition(opacity, 0.3s, ease-out);
        }
      }
      border-color: #ffffff #e3e3e3 #eaeaea;
      border-style: solid;
      border-width: 1px;
      display: block;
      list-style-type: none;
      padding: 6px 5px;
      span {
        @include single-transition(color, 0.3s, ease-out);
        display: inline-block;
        padding: 2px 4px 2px 5px;
        vertical-align: top;
        white-space: normal;
        word-wrap: break-word;
        z-index: 999;
        font-size: 12px;
        color: #666;
        &.field-url {
          font-size: 13px;
          padding-left: 20px;
          color: #666;
        }
        &.field-url {
          width: 385px;
        }
        &.edit,
        &.delete {
          float: right;
        }
      }
    }
  }
}
#image_fields {
  .explanation {
    font-size: 11px;
    @include inline-block;
  }
  .images-list {
    @include pie-clearfix;
    position: relative;
    &.empty {
      display: none;
    }
    .product-image {
      position: relative;
      cursor: move;
      float: left;
      width: 172px;
      height: 172px;
      margin-right: 15px;
      margin-bottom: 15px;
      background: transparent !important;
      .product-image-wrapper {
        border: 1px solid #dbdada;
        padding: 5px;
        background: #fff;
        @include box-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
      }
      img {
        border: 1px solid lighten(#dbdada, 5%);
        max-width: 160px;
        max-height: 160px;
      }
      .destroy a {
        display: none;
        width: 30px;
        height: 30px;
        text-indent: -9999px;
        background: transparent url("~images/fancy_close.png") center center no-repeat;
        overflow: hidden;
        top: -10px;
        right: -10px;
        cursor: pointer;
        position: absolute;
      }
      &:hover {
        .destroy a {
          display: block;
        }
      }
    }
  }
}
.product-image-upload-progress {
  text-align: center;
}
.product-attachments,
.attachments-list {
  .attachment {
    padding: 8px 15px 8px 25px;
    position: relative;

    &:before {
      @include fa-icon;
      font-family: "Font Awesome 6 Pro";
      font-weight: 300;
      position: absolute;
      top: 12px;
      left: 4px;
      font-size: 15px;
      line-height: 10px;
      color: #ababab;
      content: fa-content($fa-var-file);
    }

    &.edit-mode {
      border-bottom: 1px dashed #ddd;
    }
  }
  .edit-actions {
    float: right;
  }
  .info {
    float: right;
  }
  .actions {
    font-size: 11px;
    padding: 0 5px;
  }
  .separator {
    padding: 0 3px;
  }
  .timestamp {
    font-size: 11px;
  }
  .settings a {
    overflow: hidden;
    color: #333;
    width: 16px;
    height: 16px;
    margin: 4px 5px 0px 10px;
    display: block;
    float: left;
    opacity: 0.5;
    &:hover {
      opacity: 0.8;
    }
  }
  .remove a {
    overflow: hidden;
    color: #333;
    width: 16px;
    height: 16px;
    margin: 4px 0px 0px 0px;
    display: block;
    float: left;
    opacity: 0.5;
    &:hover {
      opacity: 0.8;
    }
  }
  .variant-specific-info {
    font-size: 11px;
    font-style: italic;
    color: #7d7d7d;
    line-height: 24px;
    float: left;
  }
  .change-path {
    float: right;
    font-size: 11px;
    a {
      color: #7d7d7d;
    }
    line-height: 24px;
  }
}
.pricing-scheme-list {
  span.on-sale {
    font-size: 11px;
    text-decoration: line-through;
  }
  span.amount {
    font-weight: bold;
  }
  li {
    margin-bottom: 8px;
  }
}

/*
* === Orders ===
*/

.order-list-wrapper {
  padding: 0px;
}
.order-list {
  .number {
    margin-left: 0px;
    a {
      color: #1f82d5;
      font-weight: 500;
      text-decoration: none;
    }
  }
  .order-cart,
  .order-address,
  .order-paying,
  .order-confirming,
  .order-extra,
  .order-failed {
    a {
      color: #905c03;
    }
  }

  .order-abandoned {
    a {
      color: #aaa;
    }
  }

  .amount,
  .total {
    text-align: center;
  }
  .payment,
  .shipping,
  .state {
    text-align: center;
  }
  .customer {
    a {
      text-decoration: none;
    }
  }
  .placed-by {
    font-size: 12px;
    a {
      font-size: 12px;
      font-weight: normal;
    }
  }
  .ogm {
    color: #777;
  }
}
.order-sidebar {
  & > section {
    margin-bottom: 30px;
  }
  .customer-data {
    margin-bottom: 20px;
    position: relative;
    .content {
      background: #fff6e5;
      border: 1px solid #d9d2c3;
      padding: 15px;
      @include border-radius(2px);
      @include pie-clearfix;
      color: #807b73;
      text-shadow: 0 1px 0 #fff;
      position: relative;
      z-index: 2;
    }
    .footer {
      content: "";
      display: block;
      z-index: 1;
      position: absolute;
      bottom: -2px;
      top: 10px;
      left: 0;
      right: 0;
      background: #fff6e5;
      border: 1px solid #d9d2c3;
      @include border-radius(2px);
    }
    a {
      @include link-colors(#807b73, #66635c, #807b73, #807b73, #66635c);
    }
    .gravatar img {
      float: left;
      padding: 3px;
      background: #fff;
      border: 1px solid #dcd0b8;
      margin-right: 20px;
      min-height: 40px;
      min-width: 40px;
    }
    .name {
      a {
        text-decoration: none;
      }
      font-weight: bold;
      font-size: 18px;
      span {
        letter-spacing: 0;
        padding-top: 5px;
        font-weight: normal;
        display: block;
        font-size: 11px;
      }
    }
  }
  .edit-address-link {
    @include link-colors(#aaa, #666, #aaa, #aaa, #666);
    font-size: 11px;
    font-weight: 400;
    padding-left: 10px;
  }
  .shipping-data,
  .billing-data,
  .invoice-data,
  .marketing-data {
    color: #444;
    line-height: 20px;
    font-size: 13px;
    position: relative;
    .address-text,
    .invoice-text,
    .marketing-text {
      position: relative;
      padding-left: 30px;
    }
    .marketing-data-item {
      margin-bottom: 15px;
      word-break: break-all;
      strong {
        display: block;
      }
    }
    i.section-icon {
      top: 2px;
      left: -2px;
      position: absolute;
      font-size: 18px;
    }
    .icon-file {
      font-size: 120%;
    }
    .invoice-note {
      border-left: 1px solid #ccc;
      padding-left: 15px;
      font-size: 12px;
      color: #999;
      p {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .invoice-field {
      margin-bottom: 10px;
      .label {
        font-weight: 600;
      }
    }
    .name {
      font-style: italic;
    }
    .phone,
    .shipment-tracking-code {
      padding-top: 15px;
      i:before {
        padding-right: 5px;
        font-size: 80%;
      }
    }
  }
}
.order-main {
  .order-status {
    padding: 15px;
    border: 1px solid #eaeaea;
    background: #fafafa;
    @include border-radius(4px);
    margin-bottom: 20px;
    h5 {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
    }
    p {
      color: #777777;
      margin-bottom: 0;
      span.code,
      span.order-comments {
        font-family: Monaco, "Courier New", monospace;
        font-size: 11px;
      }
      span.order-comments {
        white-space: pre-wrap;
      }
    }
    a {
      color: #777777;

      &:hover {
        color: #444444;
      }
    }
  }
  .order-metadata {
    pre {
      font-size: 12px;
      padding-left: 15px;
    }
  }
  .line-items {
    width: 100%;
    margin-bottom: 45px;
    tr {
      vertical-align: middle;
      border-right: 1px solid #e6e6e6;
      border-left: 1px solid #e6e6e6;
      td {
        border-top: none;
      }
      &.table-header {
        border-right: 1px solid #96c4ea;
        border-left: 1px solid #96c4ea;
      }
      &.subtotal,
      &.shipping,
      &.total {
        td {
          border-bottom: none;
        }
        .label {
          text-align: right;
        }
      }
      &.subtotal {
        td {
          padding: 15px 15px 2px;
        }
      }
      &.shipping {
        td {
          padding: 2px 15px 15px;
        }
      }
      &.total {
        font-weight: bold;
        td {
          padding: 15px 15px 30px;
        }
      }
      &.summary td {
        background: #f9f9f9;
      }
      &.group-header {
        background-color: #f9f9f9;
      }
      .total {
        white-space: nowrap;
      }
    }
    a {
      text-decoration: none;
      font-weight: bold;
    }
    .summary {
      .label:after {
        content: ':'
      }

      .label * {
        display: inline
      }

      .coupon-code {
        display: inline;
        font-size: 12px;
        &:before {
          content: '('
        }
        &:after {
          content: ')'
        }
      }
    }
    span.highlight {
      background: #fbf1cb;
    }
    .product {
      width: 100%;
      text-align: left;
      span.variant {
        font-size: 11px;
        &:before {
          content: "(";
        }
        &:after {
          content: ")";
        }
      }
    }
    .total {
      min-width: 80px;
      text-align: center;
    }
    .quantity,
    .price {
      min-width: 50px;
      text-align: center;
      white-space: nowrap;
    }
    .subscription-info {
      white-space: nowrap;
      opacity: 0.8;
    }
    .attachments {
      text-align: right;
      padding: 5px 0;
    }
    .additional {
      margin-top: 10px;
      margin-left: 15px;
      table {
        border: none;
        margin-top: 10px;
        margin-left: 15px;
        tr {
          border: none;
        }
        td {
          border: none;
          font-size: 12px;
          line-height: 12px;
          padding: 2px 5px;

          &.label {
            font-weight: bold;
            text-align: right;
          }
        }
      }
    }
    tr.order-comments {
      $bgcolor: darken(#fafafa, 3%);
      background: $bgcolor;
      td {
        padding-top: 15px;
        padding-bottom: 15px;
      }
      form {
        position: relative;
      }
      .autogrow-wrapper {
        margin-bottom: 0;
        .wrapper {
          line-height: 16px;
          padding: 0;
          border: none;
          margin-right: 90px;
        }
        textarea {
          font-size: 14px;
          line-height: 1.6;
          min-height: 16px;
          height: auto;
          background: $bgcolor;
        }
      }
      .submit {
        position: absolute;
        right: 0;
        bottom: 0;
        line-height: 26px;
        height: 26px;
        padding: 0px 12px;
        text-transform: uppercase;
        &:active,
        &:focus {
          outline: none;
        }
      }
    }
  }

  #grouped-by-extras {
    &.hidden {
      display: none;
    }
    width: 100%;
    margin-bottom: 45px;
    tr {
      vertical-align: middle;
      border-right: 1px solid #e6e6e6;
      border-left: 1px solid #e6e6e6;
      td {
        border-top: none;
      }
      &.table-header {
        border-right: 1px solid #96c4ea;
        border-left: 1px solid #96c4ea;
      }
    }
    a {
      text-decoration: none;
      font-weight: bold;
    }
    .product {
      width: 70%;
      text-align: left;
      span.variant {
        font-size: 11px;
        &:before {
          content: "(";
        }
        &:after {
          content: ")";
        }
      }
    }
    .additional {
      margin-top: 10px;
      margin-left: 15px;
      table {
        border: none;
        margin-top: 10px;
        margin-left: 15px;
        tr {
          border: none;
        }
        td {
          border: none;
          font-size: 12px;
          line-height: 12px;
          padding: 2px 5px;

          &.label {
            font-weight: bold;
            text-align: right;
          }
        }
      }
    }
  }
}
/*
* === Product Collections ===
*/

.search-product-wrapper {
  display: block;
  position: relative;
  label {
    position: absolute;
    top: 10px;
    left: 17px;
    font-size: 13px;
    @include single-transition(all, 0.3s, ease-out);
    @include opacity(0.5);
  }
  input {
    padding: 10px 15px;
  }
  &.focus,
  &:focus {
    input {
    }
    label {
      @include opacity(0.3);
    }
  }
  &.hastext {
    label {
      @include opacity(0);
    }
  }
  ul.ui-autocomplete {
    background: #fff;
    border: 1px solid #8f8f8f;
    border-top: none;
    width: 99% !important;
    @include border-radius(0px);
    padding: 0px;
    margin: 0px 10px;
    li {
      cursor: pointer;
      img {
        float: left;
        width: 32px;
        height: 32px;
        border: 3px solid #fff;
        @include box-shadow(0 0 1px rgba(0, 0, 0, 0.3));
      }
      span.match {
        font-weight: bold;
      }
      .name,
      .extra {
        margin-left: 50px;
        text-shadow: 0 1px 0 #fff;
      }
      .name {
        color: #333333;
        font-size: 14px;
        font-weight: bold;
        .match {
          background: #feea9b;
        }
      }
      .extra {
        font-size: 11px;
        color: #666666;
        .match {
          background: #feea9b;
        }
      }
      &:nth-child(odd) a {
        background: #fafbfb;
      }
      &:first-child a {
        border-top: none;
      }
      a {
        @include border-radius(0px);
        @include pie-clearfix;
        padding: 12px 11px;
        margin: 0px;
        border: none;
        border-top: 1px solid #dbdad9;
        z-index: 0;
        &.ui-state-hover {
          padding: 12px 11px;
          margin: 0px;
          background: #e1e9f3;
          border-color: #b3bcca;
          position: relative;
          z-index: 1;
          @include box-shadow(0 1px 0 #b3bcca);
        }
      }
    }
  }
}

p.no-collections {
  margin-bottom: 30px;
  font-size: 12px;
}
section.collection-list {
  padding: 0px;
}

.collection {
  .name {
    position: relative;
    margin-left: 0px;
    .status {
      position: absolute;
      top: 16px;
      right: 12px;
    }
    a {
      color: #1f82d5;
      font-size: 16px;
      line-height: 16px;
      font-weight: bold;
      text-decoration: none;
    }
    .url {
      color: #989ea9;
      display: block;
      font-size: 11px;
    }
  }
  .counter {
    line-height: 33px;
    font-weight: 600;
    a {
      color: #222;
    }
  }
}

/*
* === Customers ===
*/

section.profile {
  background-color: #f6f6f8;
  border-bottom: 1px solid #eaeaea;
  @include box-shadow(0 -1px 0px #fff);
  .gravatar img {
    float: left;
    padding: 3px;
    background: #fff;
    border: 1px solid #dddddd;
    margin-right: 20px;
    min-height: 100px;
    min-width: 100px;
  }
  .name {
    float: left;
    h3 {
      font-size: 16px;
      line-height: 16px;
    }
  }
  .mail {
    font-size: 12px;
    margin-bottom: 10px;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .status {
    font-size: 11px;
    margin-bottom: 5px;
    .label {
      font-weight: bold;
    }
    td {
      padding: 0px 15px 0px 0;
      height: 18px;
      vertical-align: middle;
    }
    tr {
      height: 18px;
      vertical-align: middle;
    }
  }
  .activate {
    float: left;
    font-size: 11px;
    line-height: 18px;
    code {
      background-color: transparent;
      padding: 3px 0;
      font-weight: bold;
      font-size: 11px;
      border: none;
      padding: 0;
      display: block;
      color: #484848;
    }
  }
  .status {
    span {
      @include inline-block;
      @include border-radius(4px);
      padding: 1px 4px;
      font-weight: bold;
      text-transform: lowercase;
      &.active {
        background: #d1e5c0;
        color: #335e10;
      }
      &.unconfirmed {
        background: #af9c7e;
        color: #fff;
      }
      &.suspended {
        background: #eeeeee;
        color: #00003c;
      }
      &.locked {
        background: #cc0000;
        color: #fff;
      }
    }
  }
}
.info {
  position: relative;
}
section.blank-slate {
  padding: 0px;
}
.customer-list {
  .name {
    min-width: 200px;
    margin-left: 35px;
    width: 40%;
  }
  .email {
    min-width: 200px;
    width: 40%;
  }
  .location {
  }
  .signed-up,
  .last-activity {
    min-width: 190px;
  }
  .centered {
    text-align: center;
  }
  .amount-spent,
  .order-count {
    min-width: 80px;
    text-align: center;
  }
  .last-order {
    min-width: 130px;
    text-align: right;
  }
}
tr.customer {
  //background:transparent url('<%= asset_path "icon/customer.png" %>') 20px center no-repeat;
  .name {
    margin-left: 0px;
    a {
      color: #1f82d5;
      font-size: 14px;
      font-weight: bold;
      text-decoration: none;
    }
  }
  .email {
    font-size: 12px;
    a {
      text-decoration: none;
      color: #777a7c;
    }
  }
  &.unconfirmed {
    //background:transparent url('<%= asset_path "icon/customer-unconfirmed.png" %>') 20px center no-repeat;
    background-color: #fafafa;
    .name {
      a {
        color: #999;
      }
    }
  }
  &.suspended {
    text-decoration: line-through;
  }
  .buttons {
    float: left;
    position: relative;
    margin: 5px 0;
    .counter,
    .smart,
    .inactive,
    .active,
    .edit {
      float: left;
      display: block;
      @include border-radius(3px);
      padding: 4px 8px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 11px;
      line-height: 11px;
      margin: 0 5px;
    }
    .counter {
      position: absolute;
      right: 300px;
      text-align: right;
      width: 100px;
      span {
        color: #444444;
        font-weight: bold;
        font-size: 22px;
        margin: -2px 7px 0;
        @include inline-block;
        line-height: 20px;
      }
    }
    .inactive {
      background: #f5f6f2;
      color: #aeb0ab;
      text-shadow: 0 1px 0 #fff;
    }
    .active {
      background: #a8dcab;
      color: #1d5026;
    }
    .smart {
      color: #4d1d4b;
      background: #d8a6d8;
    }
    .edit {
      border: 1px solid #b8bdc0;
      border-color: #b8bdc0 #a8aeb2 #99a0a5;
      background-image: linear-gradient(#ffffff, #ebeced, #dadcde);
      color: #8c949a;
      padding: 3px 8px;
      font-weight: normal;
      font-size: 9px;
      @include link-colors(#7e878e, #5e666c, #7e878e, #7e878e, #5e666c);
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
      text-decoration: none;
      position: relative;
      white-space: nowrap;
      display: block;
    }
  }
}
.customer-list {
  .list {
    @include border-radius(5px);
    background-image: linear-gradient(to bottom, #f9fafb, #e5ebef 100%);
    @include box-shadow(0px 0px 2px #fff inset, 0px 1px 2px rgba(0, 0, 0, 0.2));
    @include pie-clearfix;
    display: block;
    margin: 15px;
    border: 1px solid #bdc3c5;
    border-color: #b9c8ce #b9c8ce #b9c8ce;
    padding: 15px;
    &.with-description {
      padding: 9px 15px;
      .list-links {
        padding-top: 8px;
      }
    }
  }

  .list-data {
    float: left;
    .list-title a {
      color: #0c1010;
      text-decoration: none;
      display: block;
      font-size: 16px;
      text-shadow: 0 1px 0 #fff;
    }
    .list-description a {
      color: #2b2d2e;
      text-decoration: none;
      display: block;
      font-size: 11px;
      text-shadow: 0 1px 0 #fff;
    }
  }
  .list-links {
    float: right;
    .entry-count,
    .add-new-entry,
    .edit-list {
      float: left;
      font-size: 11px;
      padding: 2px 14px;
      color: #365372;
      border-right: 1px solid #c2cad2;
      border-left: 1px solid #ffffff;
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
      a {
        @include link-colors(#2d7292, #005279, #005279, #2d7292, #005279);
      }
    }
  }
}
.executive-summary {
  position: relative;
  @include pie-clearfix;
  .content {
    background: #fff6e5;
    //background-image: linear-gradient(to bottom, #f5fafe 0%, #f0f8fe 100%);
    border: 1px solid #d9d2c3;
    padding: 30px;
    @include border-radius(2px);
    @include pie-clearfix;
    color: #807b73;
    text-shadow: 0 1px 0 #fff;
    position: relative;
    z-index: 2;
  }
  .footer {
    content: "";
    display: block;
    z-index: 1;
    position: absolute;
    bottom: -2px;
    top: 10px;
    left: 0;
    right: 0;
    background: #fff6e5;
    border: 1px solid #d9d2c3;
    @include border-radius(2px);
  }
  a {
    @include link-colors(#807b73, #66635c, #807b73, #807b73, #66635c);
  }
  h1 {
    color: #66635c;
    text-shadow: 0 1px 0 #fff;
    font-weight: 600;
    font-size: 28px;
    span.status {
      @include inline-block;
      @include border-radius(3px);
      letter-spacing: 0;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      margin-left: 10px;
      padding: 3px;
      &.active {
        background: #d0e4c8;
        color: #3e6330;
        border: 1px solid #77b261;
      }
      &.hidden,
      &.coming-soon,
      &.sold-out,
      &.unconfirmed {
        background: #aaaaaa;
        color: #fff;
      }
    }
  }
  .description {
    @include pie-clearfix;
    margin-left: 250px;
    margin-right: 180px;
    &.no-image {
      margin-left: 0px;
    }
    .text {
      display: block;
      text-align: left;
    }
  }
  .automation,
  .extra {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #ebdfc7;
    font-size: 12px;
    .code {
      padding-top: 5px;
    }
    .checkout-profile {
      .value {
        font-weight: bold;
      }
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .field {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0px;
      }
      .label {
        @include inline-block;
      }
      .value {
        @include inline-block;
        font-weight: bold;
      }
    }
  }
  .essentials {
    width: 150px;
    float: right;
    color: #57636d;
    text-align: center;
    .price,
    .inventory,
    .sold,
    & > div {
      padding: 15px;
      background: #f0e6d4;
      @include border-radius(5px);
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0px;
      }
      .amount {
        color: #66635c;
        font-weight: 800;
        font-size: 22px;
        @include inline-block;
      }
      .label {
        padding: 3px 0 3px 5px;
        @include inline-block;
      }
      .on-sale {
        padding: 3px 0 3px 5px;
        @include inline-block;
        text-decoration: line-through;
      }
    }
  }
  .photo {
    float: left;
    margin-right: 40px;
    width: 200px;
    padding: 5px;
    background: #fff;
    border: 1px solid #dcd0b8;
    @include box-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
  }
}
.no-value {
  color: #aaa;
  font-size: 12px;
}
.custom-product-field,
.section-content .attribute,
.custom-field {
  border-bottom: 1px dotted #e6e6e6;
  padding: 25px 0;
  &.break-all {
    word-break: break-all;
  }
  .label {
    font-weight: bold;
    margin-bottom: 2px;
    // &:after {
    //   content: ":";
    // }
  }
  &:first-child {
    padding-top: 0px;
  }
  &:last-child {
    padding-bottom: 0px;
    border: none;
  }
  .field-name {
    color: #aaa;
    font-size: 11px;
    padding: 0 3px;
    cursor: pointer;
  }
  .channel-page {
    margin-bottom: 15px;
  }
  .page-url {
    color: #999;
    font-size: 11px;

    a {
      color: #555;
      font-size: 11px;
    }
  }

  .warning {
    margin-top: 10px;
    display: block;
    opacity: 0.6;

    pre {
      display: inline-block;
    }
  }
  .wysiwyg {
    margin-top: 10px;
    padding: 5px 15px;
    border-left: 1px dotted #aaa;
    
    *:last-child {
      margin-bottom: 0px;
    }
  }

  .fancy-image-link {
    display: inline-block;
    margin-top: 2px;
    background-color: #eee;
    background-image:
      linear-gradient(45deg, #ccc 25%, transparent 25%, 
        transparent 75%, #ccc 75%, #ccc),
      linear-gradient(45deg, #ccc 25%, transparent 25%, 
        transparent 75%, #ccc 75%, #ccc);
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
  }
}
.upload-button {
  overflow: hidden;
  cursor: pointer !important;
  position: relative;
  cursor: pointer;
  @include inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  padding: 0px 15px;
  text-align: center;
  &.hidden {
    display: none;
  }
  input {
    cursor: pointer !important;
    @include opacity(0);
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 25px;
  }
}
.first-upload {
  display: flex;
  flex-direction: row;
  padding: 20px 0 0;
  i {
    font-size: 60px;
    color: #d9d9d9;
    &:before {
      color: #d9d9d9;
    }
  }
  .label {
    flex-grow: 1;
    padding: 16px 25px;
    width: 200px;
    text-align: left;
    color: #a6a6a6;
    font-weight: 600;
  }
}
.order-attachments {
  margin-bottom: 45px;
  .attachment {
    margin-left: 15px;
    @include pie-clearfix;
    i {
      color: #5e7077;
      font-size: 11px;
      margin-right: 5px;
    }
    .info {
      float: right;
      font-size: 11px;
    }
    a {
      font-size: 12px;
      @include link-colors(#303030, #000, #303030, #303030, #000);
      &:hover {
        border-bottom: 1px dotted #5b5b5b;
      }
    }
    .external a {
      border-bottom: 1px solid transparent;
      @include link-colors(#aaa, #5b5b5b, #aaa, #aaa, #5b5b5b);
      &.zeroclipboard-is-hover {
        color: #5b5b5b;
        border-bottom: 1px dotted #5b5b5b;
      }
    }
  }
}

h3.subheading {
  border-bottom: 1px solid #e6e6e6;
  margin: 0 0 30px;
  padding: 0 0 4px 0;
  color: #000;
  font-size: 13px;
  font-weight: 700;
}
.order-history {
  margin-bottom: 45px;
  .history-list {
    position: relative;
    margin-left: 15px;
    margin-bottom: 30px;
  }
  .external-link {
    display: inline-block;
    margin-left: 5px;
    opacity: 0.8;
    font-size: 12px;

    &:hover {
      opacity: 1;
    }
  }
  .timeline-content {
    padding: 20px 0 20px 26px;
    margin-left: 13px;
    border-bottom: solid 1px #ececec;
    .time {
      float: left;
      font-size: 12px;
      color: #999;
      padding-top: 2px;
      margin: 0 15px 10px 0;
    }
  }
  .timeline-ico {
    background: #5e7077;
    border: solid 2px #fff;
    position: absolute;
    top: 14px;
    left: -1px;
    z-index: 2;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 30px;
    text-align: center;
    font-size: 12px;
    &.failed {
      background: #d55129;
    }
    &.success {
      background: #94c140;
    }
    &.closed {
      background: #1a7bbf;
    }
    &.warnings {
      background: #f7cd00;
    }
    &.pending {
      background: #f4b43e;
    }
    &.canceled {
      background: #b3b3b3;
    }
    i {
      color: rgba(#fff, 0.85);
      position: relative;
      font-size: 13px;
      top: -1px;
    }
  }
  .history-item {
    position: relative;
  }
  .timeline-header {
    font-size: 12px;
    text-transform: uppercase;
    color: #999;
    background: #f5f5f5;
    padding: 5px 26px 5px 26px;
    margin: 0px 0 3px 13px;
    border: none;
    @include inline-block;
  }
  .timeline-line {
    position: absolute;
    top: 0;
    left: 13px;
    width: 3px;
    height: 100%;
    background: #eee;
  }
  .table-dropdown {
    margin: 0 0 20px 15px;
    outline: none;
    border-top: solid 1px #ddd;
    position: relative;
    background: #f9f9f9;
    padding: 25px 28px !important;
    outline: 1px solid #dcdcdc;
    @include box-shadow(0 1px 3px rgba(0, 0, 0, 0.09) inset);
    display: none;
    &.active {
      display: block;
    }
  }
  .dropdown-icon {
    display: block;
    width: 25px;
    height: 9px;
    position: absolute;
    left: 20px;
    top: -3px;
    background: transparent url("~images/dropdown-icon.png") top left no-repeat;
  }
  .transaction-table {
    font-weight: 400;
    font-size: 12px;
    color: #666;
    text-transform: none;
    th {
      line-height: 17px;
      font-weight: 700;
      text-align: right;
      color: #444;
      font-size: 12px;
      text-transform: uppercase;
      padding: 7px 15px 7px 0;
      border-bottom: 1px solid #e3e3e3;
    }
    td {
      border-bottom: 1px solid #e3e3e3;
      vertical-align: middle;
      color: #666;
      font-size: 12px;
      line-height: 17px;
    }
  }
}

.product-order-list {
  .order {
    border-bottom: 1px dotted #ccc;
    padding: 5px;
  }
  .order-number {
    @include inline-block;
  }
  .order-date {
    font-size: 12px;
    color: #666;
    @include inline-block;
    float: right;
    padding-right: 25px;
  }
  .order-quantity {
    float: right;
    background: #e9eff4;
    @include border-radius(3px);
    color: #343c41;
    padding: 1px 5px;
    @include inline-block;
  }
}
.finish-order-modal,
.cancel-order-modal,
.cancel-subscription-modal {
  max-width: 500px;

  .checkbox {
    @include clearfix;
  }

  label {
    text-align: center;
    width: auto;
    float: none;
    margin: 0 0 15px 0px;
    &:after {
      display: none;
    }
  }
}
.colored-label {
  white-space: nowrap;
  display: inline-block;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 700;
  vertical-align: middle;
  zoom: 1;
  padding: 5px;
  line-height: 7px;
  text-align: center;
  text-transform: uppercase;
  background-color: #47a4e4;
  color: #717a8f;
  border: 1px solid #adb2be;
  text-shadow: 0px 1px 0px #fff;
  letter-spacing: -0.5px;
  @include box-shadow(inset 0px 1px 2px 0px #fff);
  background-image: linear-gradient(to bottom, #ffffff 0%, #e4e7eb 100%);

  &.green {
    background-color: #79be00;
    border: 1px solid #156c00;
    color: #fff;
    text-shadow: 0px 1px 0px #2d7800;
    @include box-shadow(inset 0px 1px 2px 0px #55ad07);
    background-image: linear-gradient(to bottom, #79be00 0%, #449700 100%);
  }
  &.black {
    background-color: #2f3541;
    border: 1px solid #2e343e;
    color: #fff;
    text-shadow: 0px 1px 0px #2e343e;
    @include box-shadow(inset 0px 1px 2px 0px #999);
    background-image: linear-gradient(to bottom, #272d38 0%, #393f4c 100%);
  }
  &.brown {
    background-color: #894e26;
    border: 1px solid #562d18;
    color: #fff;
    text-shadow: 0px 1px 0px #562d18;
    @include box-shadow(inset 0px 1px 2px 0px #999);
    background-image: linear-gradient(to bottom, #894e26 0%, #6b381e 100%);
  }
  &.darkgrey {
    background-color: #696969;
    border: 1px solid #343434;
    color: #fff;
    text-shadow: 0px 1px 0px #000;
    @include box-shadow(inset 0px 1px 2px 0px #5c5c5c);
    background-image: linear-gradient(to bottom, #696969 0%, #5c5c5c 100%);
  }
  &.orange {
    color: #fff;
    background-color: #ee9422;
    border: 1px solid #d62600;
    text-shadow: 0px 1px 0px #d83c08;
    @include box-shadow(inset 0px 1px 2px 0px #e1630a);
    background-image: linear-gradient(to bottom, #ee9422 0%, #e05f08 100%);
  }
  &.red {
    color: #fff;
    background-color: #ee6422;
    border: 1px solid #bf0029;
    text-shadow: 0px 1px 0px #c80720;
    @include box-shadow(inset 0px 1px 2px 0px #e1100a);
    background-image: linear-gradient(to bottom, #ee6422 0%, #e00908 100%);
  }
  &.blue {
    color: #fff;
    background-color: #47a4e4;
    border: 1px solid #015398;
    text-shadow: 0px 1px 0px #1851c6;
    @include box-shadow(inset 0px 1px 2px 0px #4598ec);
    background-image: linear-gradient(to bottom, #47a4e4 0%, #1a73d0 100%);
  }
  &.purple {
    color: #fff;
    background-color: #8647e4;
    border: 1px solid #490198;
    text-shadow: 0px 1px 0px #630f79;
    @include box-shadow(inset 0px 1px 2px 0px #af61ef);
    background-image: linear-gradient(to bottom, #8647e4 0%, #801ad0 100%);
  }
  &.grey {
    background-color: #47a4e4;
    color: #717a8f;
    border: 1px solid #adb2be;
    text-shadow: 0px 1px 0px #fff;
    @include box-shadow(inset 0px 1px 2px 0px #fff);
    background-image: linear-gradient(to bottom, #ffffff 0%, #e4e7eb 100%);
  }
  &.label-small {
    @include box-shadow(none);
    font-size: 8px;
    text-transform: uppercase;
    padding: 3px 4px;
    position: relative;
    top: -1px;
    left: 5px;
    @include opacity(0.8);
  }
}
#collection_criteria_list {
  .condition {
    position: relative;
    margin-bottom: 10px;
    .input {
      line-height: 25px;
      
      margin-bottom: 0;
      // float: left;
      &.string input {
        height: 27px;
      }
    }
  }
  .remove {
    position: absolute;
    top: 50%;
    right: -15px;
    transform: translateY(-60%);
  }
  .fields {
    display: grid;
    grid-template-columns: 25% 25% auto;
    grid-auto-flow: column;
  }

  .type-selector {
    grid-column: 1;
    padding-right: 2%;
  }
  .condition-selector {
    grid-column: 2;
    padding-right: 2%;
  }
  .special-selector,
  .regular-selector {
    grid-column: 3;
    padding-right: 2%;
  }
}
.product-variants-subsection {
  .variant {
    padding: 0px;
    &:last-child {
      margin-bottom: 5px;
    }
  }
  .sub-section-header {
    .price,
    .stock,
    .sku {
      float: left;
      margin-right: 2%;
    }
    .price {
      width: 16%;
    }
    .stock {
      width: 10%;
    }
    .sku {
      width: 14%;
    }
  }
  .input {
    float: left;
    width: 18%;
    margin-right: 2%;
    &.price {
      width: 16%;
    }
    &.stock {
      width: 10%;
    }
    &.sku {
      width: 14%;
    }
  }
  .label {
    float: left;
    margin-right: 2%;
    width: 28%;
  }
  .input {
    margin-bottom: 10px !important;
  }
  .settings a {
    overflow: hidden;
    text-indent: -9999px;
    background: transparent url("~images/cog.png") center center no-repeat;
    width: 16px;
    height: 16px;
    margin: 7px 5px 0px -5px;
    display: block;
    float: left;
    @include opacity(0.5);
    &:hover {
      @include opacity(0.8);
    }
  }
  .remove a {
    overflow: hidden;
    text-indent: -9999px;
    background: transparent url("~images/trash.gif") center center no-repeat;
    width: 16px;
    height: 16px;
    margin: 7px 0px 0px 0px;
    display: block;
    float: left;
  }
}
.inventory-policy-fields {
  @include pie-clearfix;
  .input {
    float: left;
    margin-right: 2%;
    margin-bottom: 10px !important;
  }
  input[type="text"] {
    height: 28px;
    line-height: 28px;
  }
}
.product-pricing-schemes-subsection {
  .pricing-scheme {
    background: #fcfcfc;
    border: solid 1px #ddd;
    padding: 25px 28px;
    @include box-shadow(0 1px 3px rgba(0, 0, 0, 0.09) inset);
    @include pie-clearfix;
    margin-bottom: 25px;
    margin-top: 15px;
    position: relative;
  }
  .remove a {
    overflow: hidden;
    text-indent: -9999px;
    background: transparent url("~images/trash.gif") center center no-repeat;
    width: 16px;
    height: 16px;
    margin: 0px 0px 0px 0px;
    display: block;
    float: right;
  }
  .price-points-title {
    margin-top: 16px !important;
  }
  .price-points-subsection {
    .sub-section-header {
      .qty,
      .price,
      .on-sale-price {
        float: left;
        width: 18%;
        margin-right: 2%;
      }
    }
    .input {
      float: left;
      width: 18%;
      margin-right: 2%;
    }
    .input label {
      display: none;
    }
    .input {
      margin-bottom: 10px !important;
    }
    .remove-pp a {
      overflow: hidden;
      text-indent: -9999px;
      background: transparent url("~images/trash.gif") center center no-repeat;
      width: 16px;
      height: 16px;
      margin: 7px 0px 0px 0px;
      display: block;
      float: left;
    }
  }
}
.current-product-stock {
  white-space: nowrap;
  .alert {
    margin: 0 2px;
    padding: 0 4px;
    background: #fef1e9;
    color: #cf0d15;
  }
  .warning {
    margin: 0 2px;
    padding: 0 4px;
    background: #fdfde7;
    color: #e85b0f;
  }
}
.amount-product-sold-cart {
  font-size: 11px;
  color: #777;
  white-space: nowrap;
}
.coupons-list {
  .coupon {
    .summary {
      font-size: 14px;
      font-weight: 700;
    }
    .more {
      color: #999;
      font-weight: 500;
      a {
        @include link-colors(#888, #888, #888, #888, #888);
      }
    }
    .status {
      width: 100px;
    }
    .code {
      font-family: monospace;
      text-transform: uppercase;
      font-size: 14px;
      color: #6a6667;
    }
    .buttons {
      text-align: right;
      padding: 15px;
      width: 60px;
      white-space: nowrap;
      span {
        display: none;
        width: 15px;
        font-size: 11px;
      }
    }
    .usage {
      font-size: 15px;
      line-height: 18px;
      .count {
        font-weight: 700;
        color: #666;
      }
      .limit {
        color: #aaa;
        font-weight: 500;
      }
    }
    .limits {
      .icon {
        display: inline-block;
        width: 25px;
        font-size: 18px;
        text-align: center;
        color: #a0ba4d;
        i {
          text-align: center;
        }
      }
      .verbally {
        color: #555;
      }
      .expires-label {
        color: #888;
      }
    }
    .infinity-icon {
      font-size: 20px;
    }
    .ss-clock {
      color: #afd5d1;
    }
    &.coupon-expired {
      .limits {
        .icon {
          color: #aaa;
        }
      }
      .summary {
        color: #8e96a5;
        a {
          @include link-colors(#727b8e, #727b8e, #727b8e, #727b8e, #727b8e);
        }
      }
      .more {
        color: #aaa;
        a {
          @include link-colors(#aaa, #aaa, #aaa, #aaa, #aaa);
        }
      }
    }
    &:hover {
      .buttons span {
        display: block;
        @include inline-block;
      }
    }
  }
}
.sticky-input .wrapper {
  position: relative;
}
.random-code {
  position: absolute;
  left: 8px;
  font-size: 18px;
  @include link-colors(#8496a4, #353c48, #353c48, #8496a4, #353c48);
  top: 4px;
  height: 18px;
}
input#coupon_code {
  padding-left: 35px;
  font-family: monospace;
  text-transform: uppercase;
}
.lifespan-time-sub-section {
  .shortcuts {
    text-transform: lowercase;
    font-size: 11px;
    color: #8496a4;
    a {
      @include link-colors(#8496a4, #353c48, #353c48, #8496a4, #353c48);
      &:after {
        content: "•";
      }
      &:last-child:after {
        display: none;
      }
    }
  }
}
#region_modal {
  width: 500px;
}
#shipping_rate_modal {
  width: 650px;
  .lowerbound {
    float: left;
    width: 45%;
  }
  .upperbound {
    float: right;
    width: 45%;
  }
  .with-unit input[type="text"] {
    width: 85% !important;
  }
  .unit {
    float: right;
    width: 15%;
    text-align: center;
  }
  .separator {
    float: left;
    width: 10%;
    text-align: center;
  }
  .help-inline {
    float: left;
  }
  .shipping_rate_order_min,
  .shipping_rate_weight_min {
    &.error input.optional {
      border: 1px solid #cccccc;
      border-top-color: #bbbbbb;
      background-color: #fcfcfc;
      &:focus {
        @include box-shadow(none);
      }
    }
  }
}
#regions_list {
  h5 {
    border-bottom: 1px dotted #ddd;
    padding-bottom: 10px;
    margin-bottom: 15px;
    line-height: 28px;
    a {
      @include link-colors(#333, #000, #333, #333, #000);
      span {
        line-height: 28px;
        font-size: 12px;
        font-weight: 500;
        @include link-colors(#aaa, #666, #aaa, #aaa, #666);
      }
    }
  }
  .region {
    margin-bottom: 30px;
  }
  .countries {
    padding-left: 40px;
  }
  .flag {
    @include inline-block;
    position: relative;
    top: -2px;
    margin-right: 8px;
  }
  td {
    vertical-align: top;
  }
  .country {
    padding: 0 0 5px;
  }
  .add-shipping-method {
    a {
      @include link-colors(#777, #444, #777, #777, #444);
    }
  }
}
.tax-schemes-table {
  .destination {
    width: 250px;
  }
}
.tax-rates-table {
  .flag {
    @include inline-block;
    position: relative;
    top: -2px;
    margin-right: 8px;
  }
  .destination {
    width: 250px;
  }
}
.shipping-rates-list {
  margin-bottom: 15px;
}
.shipping-rate {
  @include pie-clearfix;
  padding: 0px 0 5px;
  margin-bottom: 5px;
  border-bottom: 1px dashed #ddd;
  .name,
  .price,
  .criteria {
    float: left;
    width: 33%;
  }
  .price,
  .criteria {
    text-align: center;
  }
  .price {
    font-weight: bold;
  }
  .preferred {
    font-size: 11px;
    color: #999;
  }
}
.tax-scheme-id-subsection {
  padding-left: 30px;
  padding-right: 30px;
  .label-wrapper {
    display: none;
  }
}
.retail-icons {
  margin-left: 5px;
  i {
    color: #d5dee6;
    font-size: 11px;
    margin-left: 2px;
    top: 1px;
    position: relative;
  }
}
table.widescreen-table tr:hover {
  .retail-icons i {
    color: #e1d2ba;
  }
}

.customers {
  .address-title {
    .actions {
      display: inline-block;
      a {
        font-weight: normal;
        color: #999;
        &:hover {
          color: #555;
        }
      }
    }
  }
  .single-address + .single-address {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px dotted #ccc;
  }
}

#subscriptions {
  .amount {
    text-align: center;
    .frequency {
      font-size: 12px;
      color: #999;
    }
  }
}

.report-spam {
  color: #999;
}

#collections {
  .collection {
    .criteria {
      max-width: 25vw;
    }
  }
}
