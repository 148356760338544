// Settings file containing Foundation defaults
$primaryFont: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
  'Helvetica Neue', sans-serif;

// Grid Settings
$row_width: 1000px;
$column_gutter: 50px;
$total_columns: 12;
$mobile_total_columns: 4;
$sidebarWidth: 210px;
$mainBarWidth: 50px;
$staffWidth: 35px;

// Colors Settings

$mainColor: #1a76c9;
$alertColor: #c60f13;
$successColor: #5da423;
$secondaryColor: #e9e9e9;
$highlightColor: #ffff99;
$txtColor: #222;
$black: #000;
$white: #fff;
$shinyEdge: rgba(#fff, 0.5);
$darkEdge: rgba(#000, 0.2);

// Button Settings

$buttonRadius: 3px;
$btnBase: 10px;

$tinyBtnBase: $btnBase - 5;
$smallBtnBase: $btnBase - 3;
$largeBtnBase: $btnBase + 5;

// Form Settings

$formSpacing: 12px;

// Tab Settings

$tabHeight: 40px;

// Nav Bar Settings

$navBarHeight: 40px;
$navFlyoutBaseWidth: 250px;

// Off Canvas Width Settings

$mainWidth: 80%;
$complementaryWidth: 20%;

// Modular Scale Settings
$base-size: 14px 44px;
// Produced the following list of values: 14, 17, 23, 27, 37, 44, 59, 71, 95, 115;
// http://www.modularscale.com by Tim Brown
// https://github.com/scottkellum/modular-scale by scottkellum

// Do not include in CSS download customizer below this comment

// Media Queries

$screen-small: 700px;
$screen-medium: 1279px;
$screen-xlarge: 1441px;

$media-small: 'only screen and (max-width: 767px)';
$media-small-only: 'only screen and (max-width: 767px)';
$media-medium: 'only screen and (max-width: 1279px) and (min-width: 768px)';
$media-medium-up: 'only screen and (min-width: 768px)';
$media-xlarge: 'only screen and (min-width: 1441px)';
$media-landscape: 'screen and (orientation: landscape)';
$media-portrait: 'screen and (orientation: portrait)';
$media-all: 'only screen and (max-device-width: 800px), only screen and (device-width: 1024px) and (device-height: 600px), only screen and (width: 1280px) and (orientation: landscape), only screen and (device-width: 800px), only screen and (max-width: 767px)';
