@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@keyframes fadeOut {
    from { opacity: 1; }
    to   { opacity: 0; }
}

.redactor-animate-hide { display: none !important; }
.redactor-fadeIn  { opacity: 0; animation: fadeIn .5s cubic-bezier(0.2, 0.8, 0.2, 1); }
.redactor-fadeOut { opacity: 1; animation: fadeOut .5s cubic-bezier(0.2, 0.8, 0.2, 1); }
