.redactor-image-manager-pagination {
  margin-top: 5px;
  text-align: center;

  button {
    margin-right: 5px;
    &:last-child {
      margin-right: 0px;
    }
  }
}
