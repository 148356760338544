@function divide($dividend, $divisor, $precision: 10) {
  $sign: if($dividend > 0 and $divisor > 0, 1, -1);
  $dividend: abs($dividend);
  $divisor: abs($divisor);
  $quotient: 0;
  $remainder: $dividend;
  @if $dividend == 0 {
    @return 0;
  }
  @if $divisor == 0 {
    @error "Cannot divide by 0";
  }
  @if $divisor == 1 {
    @return $dividend;
  }
  @while $remainder >= $divisor {
    $quotient: $quotient + 1;
    $remainder: $remainder - $divisor;
  }
  @if $remainder > 0 and $precision > 0 {
    $remainder: divide($remainder * 10, $divisor, $precision - 1) * .1;
  }
  @return ($quotient + $remainder) * $sign;
}

// Power Function
@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }

  @return $value;
}

// Strip Units
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// Clearfix
@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

// Scale Font Size
@mixin font-scaled-size-up($scale) {
    font-size: 1em * pow($font-scale, $scale);
}

@mixin font-scaled-size-down($scale) {
    font-size: divide(1em, pow($font-scale, $scale));
}
