.redactor-dropdown-fontcolor {
  width: 306px;

  .re-dropdown-box-textcolor,
  .re-dropdown-box-backcolor {
    span {
      border: 1px solid #fff;
      transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
      position: relative;
      z-index: 1;

      &:hover {
        transform: scale(2);
        border-radius: 3px;
        z-index: 2;
        border-width: 1px;

        &:before {
          opacity: 1;
        }
      }
    }
  }
}

.redactor-statusbar {
  padding: 6px 10px;
  background: #f8f9fa;

  & li {
    font-size: 11px;

    &:first-child {
      padding-left: 0;
    }
  }
}

.redactor-fadeIn {
  opacity: 0;
  animation: fadeIn 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.redactor-fadeOut {
  opacity: 1;
  animation: fadeOut 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.redactor-toolbar a,
.redactor-air a {
  width: 4.525%; // 20 knoppen -> (100 - 0.5× 19) / 20
  margin-right: 0.5%;
  margin-bottom: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }

  @media only screen and (min-width: 1024px) {
    padding-top: 7px;
    padding-bottom: 6px;
  }

  @media only screen and (min-width: 1281px) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

#redactor-filemanager-list span.r-file-name {
  display: none;
}

.redactor-box.redactor-styles-on {
  &.redactor-source-view {
    background: #252525;
  }
}

.page-fields {
  .redactor-box {
    &.redactor-styles-on {
      border: none !important;
    }
  }
  .redactor-focus,
  .redactor-focus:focus {
    &.redactor-styles-on {
      border: none !important;
    }
  }
}

.upload-redactor-private-files-hint {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3)
}

.upload-redactor-private-files-toggle {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .upload-redactor-private-files-input {
    display: inline-flex;

    .description {
      font-size: 15px;
      color: rgba(0, 0, 0, 0.5)
    }
  }

  input[type=checkbox] {
    display: none;
  
    // add default box-sizing for this scope
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    & + label {
      box-sizing: border-box;
      &::selection {
        background: none;
      }
    }
    
    + label {
      outline: 0;
      display: block;
      width: 4em;
      height: 22px;
      position: relative;
      cursor: pointer;
      user-select: none;
      margin-left: 10px;
      margin-top: 2px;
      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }
      
      &:after {
        left: 0;
      }
      
      &:before {
        display: none;
      }
    }
    
    &:checked + label:after {
      left: 50%;
    }

    & + label {
      overflow: hidden;
      transform: skew(-10deg);
      backface-visibility: hidden;
      transition: all .2s ease;
      // font-family: sans-serif;
      background: #5da423;
      &:after,
      &:before {
        transform: skew(10deg);
        display: inline-block;
        transition: all .2s ease;
        width: 100%;
        text-align: center;
        position: absolute;
        line-height: 22px;
        font-weight: 600;
        color: #fff;
        text-shadow: 0 1px 0 rgba(0,0,0,.2);
      }
      
      &:after {
        left: 100%;
        content: attr(data-tg-on);
      }
      
      &:before {
        left: 0;
        content: attr(data-tg-off);
      }
      
      &:active {
        background: darken(#5da423, 5%);
        &:before {
          left: -10%;
        }
      }
    }
    
    &:checked + label {
      background: #ee9422;
      &:before {
        left: -100%;
      }
  
      &:after {
        left: 0;
      }
  
      &:active {
        background: darken(#ee9422, 5%);
        &:after {
          left: 10%;
        }
      }
    }
  }
}
