@import 'base';
@import '~compass-mixins/lib/compass/utilities/general/clearfix';
@import '~compass-mixins/lib/compass/typography/links/link-colors';
@import '~compass-mixins/lib/compass/css3/inline-block';
@import '~compass-mixins/lib/compass/css3/box-sizing';
@import '~compass-mixins/lib/compass/css3/border-radius';
@import '~compass-mixins/lib/compass/css3/box-shadow';
@import '~compass-mixins/lib/compass/css3/transition';
@import '~compass-mixins/lib/compass/css3/opacity';
@import '~compass-mixins/lib/compass/css3/images';

#client-document {
  background: white;
  border: 0px;
  color: #222222;
  font-size: 18px;
  margin: 0px;
  padding: 20px;
  position: relative;
  z-index: 1;
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
  hr {
    background: #cccccc;
    color: #cccccc;
    width: 100%;
    height: 2px;
    margin: 2em 0;
    padding: 0;
    border: none;
    &.space {
      background: white;
      color: white;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }
  code {
    font-size: 0.9em;
    font-family: 'andale mono', 'lucida console', monospace;
  }
  a img {
    border: none;
  }
  p {
    margin: 0 0 1em;
    img.top {
      margin-top: 0;
    }
  }
  blockquote {
    margin: 1.5em;
    padding: 1em;
    font-style: italic;
    font-size: 0.9em;
  }
  .small {
    font-size: 0.9em;
  }
  .large {
    font-size: 1.1em;
  }
  .quiet {
    color: #999999;
  }
  .hide {
    display: none;
  }
}

.client-doc-header {
  padding: 0px 5px;
  position: relative;
}

.client-doc-doc-type {
  float: right;
  font-size: 1.8em;
  font-weight: bold;
  line-height: 1em;
  margin: 0 0 15px;
  width: 200px;
}

.client-doc-name {
  float: left;
  margin-bottom: 30px;
  h1 {
    font-size: 3em;
    font-weight: bold;
    line-height: 1.1em;
    margin: 0px;
    min-height: 80px;
    padding: 0px;
    width: 300px;
  }
  img {
    display: block;
  }
}

.client-doc-from {
  float: right;
  margin-bottom: 30px;
}

.client-doc-for {
  float: right;
}

.client-doc-address {
  h3 {
    color: #555;
    float: left;
    font-size: 1em;
    font-weight: normal;
    margin: 0;
    padding-top: 4px;
    text-align: right;
    width: 120px;
  }
  div {
    line-height: 18px;
    border-left: 1px solid #cccccc;
    margin-left: 135px;
    padding: 4px 0 4px 10px;
    width: 160px;
  }
  strong {
    display: block;
    font-size: 1.2em;
    padding-bottom: 2px;
  }
}

.client-doc-details {
  float: left;
  table {
    text-align: left;
    width: 380px;
    td {
      &.label {
        color: #555;
        font-size: inherit;
        padding: 4px 10px 4px 0px;
        min-height: 17px;
        width: 70px;
        white-space: nowrap;
        vertical-align: top;
      }
      &.definition {
        border-left: 1px solid #cccccc;
        min-height: 17px;
        padding: 4px 10px;
        vertical-align: top;
      }
    }
  }
}

.client-doc-items {
  border-collapse: collapse;
  margin: 50px 0;
  width: 100%;
  th {
    border-bottom: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    font-size: 0.9em;
    font-weight: bold;
    line-height: 1.1em;
    padding: 3px 10px 2px;
    text-align: left;
    vertical-align: top;
  }
  td {
    border-bottom: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    float: none;
    font-weight: normal;
    padding: 10px;
    text-align: left;
    vertical-align: top;
  }
  .first {
    padding-left: 5px;
  }
  .last {
    border-right: 0px;
    padding-right: 5px;
  }
  tr.row-even td {
    background: #f6f6f6;
  }
  .item-type {
    width: 70px;
  }
}

#client-document p:last-child {
  margin: 0px;
}

.client-doc-items {
  .item-qty {
    text-align: right;
    width: 50px;
  }
  .item-unit-price {
    text-align: right;
    white-space: nowrap;
    width: 75px;
  }
  .item-vat_pct {
    white-space: nowrap;
    text-align: right;
  }
  .item-amount {
    font-weight: bold;
    text-align: right;
    white-space: nowrap;
    width: 85px;
  }
}

tr,
td,
th,
thead,
tfoot {
  page-break-inside: avoid !important;
}

.client-doc-summary {
  td {
    border: 0px;
    color: #555555;
    padding: 2px 10px;
    text-align: right;
    &.subtotal {
      font-size: 1.1em;
      font-weight: bold;
      color: #222222;
      padding-right: 5px;
      white-space: nowrap;
    }
  }
  tr {
    &:first-child td {
      padding-top: 20px;
    }
    &.total td {
      color: #222222;
      font-size: 1.3em;
      font-weight: bold;
      padding-top: 1.1em;
      &.total {
        padding-right: 5px;
        white-space: nowrap;
      }
    }
  }
}
tr.discount {
  td {
    padding-top: 1.1em;
  }
}
tr.tax-note {
  font-size: 0.8em;
  td.subtotal {
    font-weight: normal;
  }
}

td.item-description {
  width: 80%;
}

.item-sku {
  margin-top: 5px;
  display: block;
  font-size: 10px;
  font-style: italic;
}

.client-doc-notes {
  border-top: 1px solid #cccccc;
  padding: 0 5px;
  margin: 0;
}
.client-notes {
  line-height: 18px;
  padding: 0 5px;
  margin: 0;
}

* .client-doc-notes {
  overflow-x: hidden;
}

.client-doc-notes h3 {
  font-size: 0.9em;
  font-weight: bold;
  margin: 5px 0 10px;
}

/* Hidden Columns */

.hide-type-column .item-type,
.hide-quantity-column .item-qty,
.hide-unit_price-column .item-unit-price,
.hide-description-column .item-description,
.hide-amount-column .item-amount {
  display: none;
}

/* Address-on-left Template */

#client-document.address-on-left {
  .client-doc-details {
    float: right;
    table {
      width: 307px;
      td.label {
        text-align: right;
        width: 105px;
      }
    }
  }
  .client-doc-for {
    float: left;
    h3 {
      width: 70px;
      text-align: left;
    }
    div {
      margin-left: 80px;
      width: 200px;
    }
  }
  .subject-address-on-right {
    display: none;
  }
  .subject-address-on-left {
    display: block !important;
  }
}

.subject-address-on-left {
  margin: 20px 0 -10px;
  h3 {
    width: 70px;
    text-align: left;
  }
  div {
    margin-left: 80px;
    width: auto;
  }
}

@media print {
  .do-not-print {
    display: none;
  }
  #client-document a {
    &:link,
    &:visited {
      background: transparent;
      font-weight: 700;
      text-decoration: underline;
    }
  }
}
