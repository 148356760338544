@import "base";
@import "~compass-mixins/lib/compass/css3/user-interface";
@import "~compass-mixins/lib/compass/utilities/general/clearfix";
@import "~compass-mixins/lib/compass/typography/links/link-colors";
@import "~compass-mixins/lib/compass/css3/inline-block";
@import "~compass-mixins/lib/compass/css3/box-sizing";
@import "~compass-mixins/lib/compass/css3/border-radius";
@import "~compass-mixins/lib/compass/css3/box-shadow";
@import "~compass-mixins/lib/compass/css3/transition";
@import "~compass-mixins/lib/compass/css3/opacity";
@import "~compass-mixins/lib/compass/css3/images";
@import "~compass-mixins/lib/compass/css3/transform";

button,
input {
  *overflow: visible;
  line-height: normal;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

button {
  cursor: pointer;
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border: none;
}

::-webkit-file-upload-button,
::-webkit-file-upload-text {
  cursor: pointer;
}

::-webkit-input-placeholder,
::-moz-placeholder {
  color: #999999;
  font-family: $primaryFont;
}

input {
  &.placeholder,
  &[type="text"].placeholder {
    color: #999999;
    font-family: $primaryFont;
  }
}

textarea.placeholder {
  color: #999999;
  font-family: $primaryFont;
}

input {
  &[type="email"].placeholder,
  &[type="tel"].placeholder,
  &[type="url"].placeholder {
    color: #999999;
    font-family: $primaryFont;
  }
  &.placeholder:focus,
  &[type="text"].placeholder:focus {
    color: #cccccc !important;
    outline: none !important;
  }
}

textarea.placeholder:focus {
  color: #cccccc !important;
  outline: none !important;
}

input {
  &[type="email"].placeholder:focus,
  &[type="tel"].placeholder:focus,
  &[type="url"].placeholder:focus {
    color: #cccccc !important;
    outline: none !important;
  }
  &:focus {
    &::-webkit-input-placeholder,
    ::-moz-placeholder {
      color: #cccccc !important;
      outline: none !important;
    }
  }
  &[type="text"]:focus {
    &::-webkit-input-placeholder,
    ::-moz-placeholder {
      color: #cccccc !important;
      outline: none !important;
    }
  }
  &[type="search"]:focus {
    &::-webkit-input-placeholder,
    ::-moz-placeholder {
      color: #cccccc !important;
      outline: none !important;
    }
  }
  &[type="email"]:focus {
    &::-webkit-input-placeholder,
    ::-moz-placeholder {
      color: #cccccc !important;
      outline: none !important;
    }
  }
  &[type="tel"]:focus {
    &::-webkit-input-placeholder,
    ::-moz-placeholder {
      color: #cccccc !important;
      outline: none !important;
    }
  }
  &[type="url"]:focus {
    &::-webkit-input-placeholder,
    ::-moz-placeholder {
      color: #cccccc !important;
      outline: none !important;
    }
  }
  &[type="number"]:focus {
    &::-webkit-input-placeholder,
    ::-moz-placeholder {
      color: #cccccc !important;
      outline: none !important;
    }
  }
}
textarea {
  &:focus {
    &::-webkit-input-placeholder,
    ::-moz-placeholder {
      color: #cccccc !important;
    }
  }
  &.placeholder {
    color: #999999 !important;
    font-family: $primaryFont !important;
    &.active,
    &:-moz-placeholder {
      color: #999999 !important;
      font-family: $primaryFont !important;
    }
  }
  &.active.placeholder:-moz-placeholder {
    color: #999999 !important;
    font-family: $primaryFont !important;
  }
}

textarea.autogrow {
  min-height: 18px;
  resize: none;
  box-sizing: content-box;
  width: 100%;
  padding: 0;
  border: none;
}

.autogrow-wrapper {
  .wrapper {
    padding: 15px;
    border: 1px solid #cccccc;
    border-top-color: #bbbbbb;
  }
}
/* STANDARD FORM ELEMENTS */

input {
  // &[type=number] {
  //   &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
  //     -webkit-appearance: none;
  //     margin: 0; } }
  &[type="text"],
  &[type="email"],
  &[type="password"],
  &[type="tel"],
  &[type="url"],
  &[type="number"] {
    //@include border-radius(4px);
    //@include box-shadow(inset 0px 1px 1px 0 rgba(0, 0, 0, 0.12));
    @include single-transition(box-shadow, 0.1s, ease-out);
    border: 1px solid #cccccc;
    min-height: 24px;
    outline: none;
    border-top-color: #bbbbbb;
    font-family: $primaryFont;
    font-weight: normal;
    color: #555;
    background-color: #fcfcfc;
    width: 100%;
    min-height: 36px;
    margin: 0;
    padding: 0 8px;
    font-size: 13px;

    &:autofill {
      border: 1px solid #cccccc !important;
      border-top-color: #bbbbbb !important;
      background-color: #fcfcfc !important;
      color: #555 !important;
    }
  }
}

textarea {
  //@include box-shadow(inset 0px 1px 1px 0 rgba(0, 0, 0, 0.12));
  @include single-transition(box-shadow, 0.1s, ease-out);
  border: 1px solid #cccccc;
  min-height: 24px;
  outline: none;
  border-top-color: #bbbbbb;
  font-family: $primaryFont;
  font-weight: normal;
  color: #333;
  background-color: #fcfcfc;
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 8px;
  font-size: 13px;
  min-height: 100px;
  &.raw {
    font-family: Monaco, "Courier New", monospace;
  }
}

.title-field {
  input {
    &[type="text"],
    &[type="email"],
    &[type="password"],
    &[type="tel"],
    &[type="url"] {
      min-height: 36px;
      outline: none;
      border-top-color: #bbbbbb;
      font-family: $primaryFont;
      font-weight: normal;
      color: #333;
      background-color: #fcfcfc;
      width: 100%;
      height: 36px;
      margin: 0;
      padding: 0 8px;
      font-size: 16px;
    }
  }
  label {
    font-size: 16px;
  }
}
.standard-input,
.standard-textarea {
  @include border-radius(4px);
  border: 1px solid #cccccc;
  //@include box-shadow(inset 0px 1px 1px 0 rgba(0, 0, 0, 0.12));
  outline: none;
  border-top-color: #bbbbbb;
  font-family: $primaryFont;
  font-weight: normal;
  color: #888888;
  background-color: #fcfcfc;
}

input {
  &[type="number"]:focus,
  &[type="text"]:focus,
  &[type="email"]:focus,
  &[type="password"]:focus,
  &[type="tel"]:focus,
  &[type="url"]:focus {
    //@include box-shadow(inset 0px 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 4px 0 #5ca5e8, inset 0 0 2px 0 rgba(92, 165, 232, 0.66));
    border-color: #20affd;
    color: #333333;
  }
}

textarea:focus {
  //@include box-shadow(0 0 4px 0 #5ca5e8, inset 0 0 2px 0 rgba(92, 165, 232, 0.66));
  border-color: #20affd;
  color: #333333;
}

input {
  &[type="number"]:focus {
    //@include box-shadow(inset 0px 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 4px 0 #5ca5e8, inset 0 0 2px 0 rgba(92, 165, 232, 0.66));
    border-color: #20affd;
    color: #333333;
  }
  &[type="text"][readonly="readonly"]:focus,
  &[type="email"][readonly="readonly"]:focus,
  &[type="password"][readonly="readonly"]:focus,
  &[type="tel"][readonly="readonly"]:focus,
  &[type="url"][readonly="readonly"]:focus {
    //@include box-shadow(inset 0px 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 4px 0 #999999, inset 0 0 2px 0 rgba(153, 153, 153, 0.66));
    border-color: #999999;
  }
}

input {
  &[type="number"][readonly="readonly"]:focus {
    //@include box-shadow(inset 0px 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 4px 0 #999999, inset 0 0 2px 0 rgba(153, 153, 153, 0.66));
    background-color: white;
    border-color: #999999;
  }
  &[type="text"].error,
  &[type="email"].error,
  &[type="password"].error,
  &[type="tel"].error,
  &[type="url"].error {
    border: 1px solid #dbaaab;
    //padding-right: 88px;
    border-top-color: #d79494;
    border-bottom-color: #dbaaab;
    background-color: #faf0f0;
  }
}

.input.error input,
.input.error textarea {
  border: 1px solid #dbaaab;
  //padding-right: 88px;
  border-top-color: #d79494;
  border-bottom-color: #dbaaab;
  background-color: #faf0f0;
}

input {
  &[type="number"].error {
    border: 1px solid #dbaaab;
    //padding-right: 88px;
    border-top-color: #d79494;
    border-bottom-color: #dbaaab;
    background-color: #faf0f0;
  }
  &[type="text"].error:focus,
  &[type="email"].error:focus,
  &[type="password"].error:focus,
  &[type="tel"].error:focus,
  &[type="url"].error:focus {
    @include box-shadow(
      inset 0px 1px 3px 0 rgba(0, 0, 0, 0.12),
      0 0 4px 0 #dbaaab,
      inset 0 0 2px 0 rgba(219, 170, 171, 0.66)
    );
  }
}

input[type="number"].error:focus {
  @include box-shadow(
    inset 0px 1px 3px 0 rgba(0, 0, 0, 0.12),
    0 0 4px 0 #dbaaab,
    inset 0 0 2px 0 rgba(219, 170, 171, 0.66)
  );
}

.react-select__control {
  input[type="text"] {
    min-height: 18px;
  }
}

form.inline,
.inline-form {
  .input {
    position: relative;
    @include pie-clearfix;
  }
  .wrapper {
    background: #fff;
  }
  input {
    position: relative;
    z-index: 2;
    background: transparent;
  }
  .input {
    margin: 0;
    label {
      font-weight: normal;
      margin-bottom: 0px;
      position: absolute;
      z-index: 1;
      color: #999;
      @include user-select(none);
      top: $formSpacing * 0.5 + 4;
      left: $formSpacing * 0.5 + 4;
      @include single-transition(opacity, 0.3s, ease-out);
    }
    &.focus label {
      @include opacity(0.45);
      @include single-transition(opacity, 0.3s, ease-out);
    }
    &.hastext label {
      @include opacity(0);
      @include single-transition(opacity, 0.3s, ease-out);
      z-index: 0 !important;
    }
    &.select,
    &.country {
      label {
        display: none;
      }
    }
    &.radio_buttons {
      label {
        position: relative;
      }
    }
    &.boolean {
      .wrapper {
        float: left;
      }
      label {
        font-weight: bold;
        position: relative;
        top: 0px;
        left: 0px;
        @include inline-block;
      }
    }
  }
}
form {
  abbr {
    display: none;
  }
}

/*
* === BUTTONS && BUTTON GROUPS ===
*/

button,
input[type="button"] {
  font-family: $primaryFont;
}

.button {
  // @include border-radius(4px);
  @include box-sizing(border-box);
  padding: 0 10px;
  //border: 1px solid;
  cursor: pointer;
  font-weight: bold;
  font-size: 11px;
  font-family: $primaryFont;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  strong {
    font-weight: 800;
  }
}

input.button,
a.button,
button.button {
  color: #555;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  padding: 0 15px;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  line-height: 30px;
  padding-left: 18px;
  padding-right: 18px;
  height: 30px;
  i:before {
    display: inline-block;
    margin: 0px 7px 0px -2px;
    font-size: 13px;
    line-height: 13px;
    position: relative;
    top: 0px;
  }
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}

.button {
  // border: 1px solid #b2b7c5;
  border: 0;
  border-radius: 3px;
  font-weight: bold;
  color: #555;
  outline-offset: -2px;
  font-size: 11px;
  background: #e9ebf0;
  background: linear-gradient(#fff, #eee);

  &:hover {
    background: #f7f7f7;
    background: linear-gradient(#f7f7f7, #e6e6e6);
    text-decoration: none;
  }

  &::-moz-focus-inner {
    border: none;
  }
  &.left,
  &.right,
  &.middle {
    float: left;
    position: relative;
    margin: 0;
    &.green {
      @include box-shadow(inset 0px 1px 0px 0px #a9e200);
    }
  }
  &.middle {
    @include border-radius(0);
    border-right: 0;
    border-left: 0;
    margin-left: -1px;
    &:after {
      display: block;
      width: 1px;
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(
        to bottom,
        rgba(49, 119, 0, 0) 0%,
        rgba(49, 119, 0, 0.8) 50%,
        rgba(49, 119, 0, 0.8) 50%,
        rgba(49, 119, 0, 0.8) 80%,
        rgba(49, 119, 0, 0) 100%
      );
    }
    &:before {
      display: block;
      width: 1px;
      content: "";
      position: absolute;
      height: 100%;
      left: -1px;
      top: 0;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0.1) 80%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    &.active,
    &:active,
    &:focus {
      //background: #e4e4e4;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 3px rgba(0, 0, 0, 0.15);
      outline: none;
    }
  }
  &.left {
    @include border-top-right-radius(0);
    @include border-bottom-right-radius(0);
    border-right-width: 0px;
    &:active,
    &.active,
    &:focus {
    }
  }
  &.right {
    @include border-top-left-radius(0);
    @include border-bottom-left-radius(0);
    border-left: 0;
    margin-left: -1px;
    &:before {
      display: block;
      width: 1px;
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(
        to bottom,
        rgba(182, 196, 207, 0) 0%,
        #b6c4cf 50%,
        #b6c4cf 50%,
        #b6c4cf 80%,
        rgba(182, 196, 207, 0) 100%
      );
    }
    &.green:before {
      display: block;
      width: 1px;
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(
        to bottom,
        rgba(49, 119, 0, 0) 0%,
        rgba(49, 119, 0, 0.8) 50%,
        rgba(49, 119, 0, 0.8) 50%,
        rgba(49, 119, 0, 0.8) 80%,
        rgba(49, 119, 0, 0) 100%
      );
    }
    &:after {
      display: block;
      width: 1px;
      content: "";
      position: absolute;
      height: 100%;
      left: -1px;
      top: 0;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0.1) 80%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    &:active,
    &.active,
    &:focus {
      // @include box-shadow(
      //   inset 0 1px 4px 0 rgba(0, 41, 3, 0.5),
      //   inset 1px 0 0 0px rgba(0, 41, 3, 0.5)
      // );
    }
    &.middle:active + &.right:before,
    &.left + &.middle:active:before,
    &.left:active + &.middle:before,
    &.middle + &.right:active:before {
      display: none;
    }
    &.middle:active + &.right:after,
    &.left + &.middle:active:after,
    &.left:active + &.middle:after,
    &.middle + &.right:active:after {
      display: none;
    }
  }

  &.hollow {
    background: #fff;

    &:hover {
      background: #f7f7f7;
      background: linear-gradient(#f7f7f7, #e6e6e6);
      text-decoration: none;
    }
  }

  &.disabled,
  &[disabled] {
    &,
    &:hover,
    &:active,
    &.active {
      background: #eee !important;
      border: 0 !important;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1) !important;
      color: #888 !important;
      cursor: not-allowed !important;
      opacity: 0.8 !important;
      text-decoration: none !important;
      text-shadow: none !important;
    }
    opacity: 0.5;
    color: rgba(47, 47, 47, 0.5);
    cursor: inherit;
    &:active.disabled,
    &.active.disabled {
      opacity: 0.5;
      color: rgba(47, 47, 47, 0.5);
      cursor: inherit;
    }
  }
  &:active,
  &.active {
    text-shadow: none;
    outline: none;
    background: #e4e4e4;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 3px rgba(0, 0, 0, 0.15),
      rgba(#424257, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(#424257, 0.12) 0px 2px 5px 0px,
      rgba(#3a97d4, 0.28) 0px 0px 0px 3px;
  }
  &.transparent {
    border-color: #e9e9e9;
    color: #888888;
    background: white;
  }
  &.no-caps {
    text-transform: none;
  }
  &.blue-grey {
    font-weight: bold;
    color: #fff;
    background-color: #80b7f2;
    @include link-colors(#fff, #fff, #fff, #fff, #fff);
    background: linear-gradient(to top, #3179ca 0%, #66b5ff 100%);
    &:active {
      @include box-shadow(inset 0 1px 5px 0 darken(#3179ca, 15%));
      text-shadow: none;
      background: linear-gradient(to bottom, darken(#3179ca, 0%) 0%, darken(#66b5ff, 10%) 100%);
    }
  }
  &.dark-grey {
    background-color: #72777b;
    background: linear-gradient(to bottom, #72777b 0%, #474c50 50%, #404348 50%, #323538 100%);
  }
  &.black {
    text-shadow: 0px 1px 0px black;
    color: white;
    border-color: black;
    background-color: #272727;
    background: linear-gradient(to top, #222222 0%, #272727 48%, #333333 48%, #555555 100%);
    &:active {
      @include box-shadow(0 1px 0 0 #2e2e2e, inset 0 1px 2px 0 black);
      background: linear-gradient(to bottom, #1a1a1a 0%, #474747 100%);
    }
  }
  &.green,
  &.primary {
    color: white;
    background: #499c00;
    background: linear-gradient(#4fb840, #14a000);

    &:hover {
      background: linear-gradient(#4aad3c, #129100);
    }

    &:active,
    &.active {
      color: white;
      background: #118700;
    }
  }
  &.red,
  &.danger {
    color: white;
    background-color: #f10000;
    background: linear-gradient(#eb5252, #eb0000);
    &:hover {
      color: white;
      background: linear-gradient(darken(#eb5252, 5%), darken(#eb0000, 5%));
    }
    &:active,
    &.active {
      color: white;
      background: linear-gradient(darken(#eb5252, 10%), darken(#eb0000, 10%));
    }
  }
  &.navy {
    border: 1px solid black;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.75);
    color: white;
    background-color: #5d6879;
    @include link-colors(white, white, white, white, white);
    background: linear-gradient(to bottom, #5d6879 0%, #445065 50%, #333f51 50%, #283242 99%);
    &:active,
    &.active {
      background-color: #283242;
      background: linear-gradient(to bottom, #283242 0%, #384151 100%);
    }
  }
  &.blue {
    background-color: #2e9cd0;
    background: linear-gradient(to bottom, #54caed 0%, #3199d9 100%);
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
    color: white;
    &:active,
    &.active {
      background-color: #2c98d0;
      background: linear-gradient(to bottom, darken(#54caed, 5%) 0%, darken(#3199d9, 5%) 100%);
      color: white;
    }
    &.disabled,
    &[disabled="disabled"] {
      text-shadow: #2478ff 0px -1px 0px;
      color: #97c9ff;
      -webkit-font-smoothing: antialiased;
    }
    &.disabled {
      &:active,
      &.active {
        @include box-shadow(0px 1px 2px 0px rgba(0, 0, 0, 0.2), inset 0px 1px 0px 0px #85d0ff);
        background-color: #66baff;
        background: linear-gradient(to top, #1778fe 0%, #1778fe 50%, #2a90ff 50%, #66baff 100%);
        border-top-color: #035bee;
        border-right-color: #0145b6;
        border-left-color: #0247bb;
        border-bottom-color: #002666;
      }
    }
    &[disabled="disabled"] {
      &:active,
      &.active {
        @include box-shadow(0px 1px 2px 0px rgba(0, 0, 0, 0.2), inset 0px 1px 0px 0px #85d0ff);
        background-color: #66baff;
        background: linear-gradient(to top, #1778fe 0%, #1778fe 50%, #2a90ff 50%, #66baff 100%);
        border-top-color: #035bee;
        border-right-color: #0145b6;
        border-left-color: #0247bb;
        border-bottom-color: #002666;
      }
    }
    &.disabled {
      &:active.dark_background,
      &.active.dark_background {
        border-color: black;
      }
    }
    &[disabled="disabled"] {
      &:active.dark_background,
      &.active.dark_background {
        border-color: black;
      }
    }
  }
  &.mini {
    overflow: hidden;
    cursor: pointer !important;
    display: inline-block;
    line-height: 28px;
    font-size: 11px;
    padding: 1px 12px 0;
    margin-right: 10px;
    text-align: center;
    margin-bottom: 10px;
    white-space: nowrap;
    i::before {
      margin: 0px 3px 0px -5px;
      font-size: 11px;
      line-height: 11px;
    }
  }
  &.uppercase {
    text-transform: uppercase;
    font-size: 15px;
    line-height: 40px;
    height: 40px;
  }
}

.button {
  transform: background-color 240ms, box-shadow 240ms;
  box-shadow: rgba(#424257, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
    rgba(#424257, 0.12) 0px 2px 5px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;

  &.blue,
  &.red,
  &.green,
  &.orange {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), rgba(#424257, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(#424257, 0.12) 0px 2px 5px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }

  &:hover {
    box-shadow: rgba(#424257, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(#424257, 0.12) 0px 2px 5px 0px, rgba(#424257, 0.12) 0px 3px 9px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;

    &.blue {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), rgba(#424257, 0.16) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(#424257, 0.12) 0px 2px 5px 0px, rgba(60, 66, 87, 0.12) 0px 3px 9px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    }

    &.blue,
    &.red,
    &.green,
    &.orange {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.12) 0px 2px 5px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    }
  }

  &:active,
  &:hover:active,
  &:focus {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 3px rgba(0, 0, 0, 0.15),
      rgba(#424257, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(#424257, 0.12) 0px 2px 5px 0px,
      rgba(#424257, 0.12) 0px 3px 9px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(#3a97d4, 0.28) 0px 0px 0px 3px;

    &.red {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3), rgba(#424257, 0.16) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(#424257, 0.12) 0px 2px 5px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(#d43939, 0.28) 0px 0px 0px 3px;
    }

    &.green {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3), rgba(#424257, 0.16) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(#424257, 0.12) 0px 2px 5px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(#60d439, 0.28) 0px 0px 0px 3px;
    }

    &.orange {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3), rgba(#424257, 0.16) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(#424257, 0.12) 0px 2px 5px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(#d49e39, 0.28) 0px 0px 0px 3px;
    }

    &.blue {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), rgba(#424257, 0.16) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(#424257, 0.12) 0px 2px 5px 0px, rgba(#424257, 0.12) 0px 3px 9px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(#3a97d4, 0.28) 0px 0px 0px 3px;
    }
  }
}

.button-group:not(.pagination-info):not(.disabled) {
  border-radius: 3px;
  transform: background-color box-shadow 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  box-shadow: rgba(#424257, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
    rgba(#424257, 0.12) 0px 2px 5px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;

  &:hover {
    box-shadow: rgba(#424257, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(#424257, 0.12) 0px 2px 5px 0px, rgba(#424257, 0.12) 0px 3px 9px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }

  .button {
    box-shadow: none;

    &.blue,
    &.red,
    &.green,
    &.orange {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    }

    &:hover {
      box-shadow: none;

      &.blue,
      &.red,
      &.green,
      &.orange {
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.25);
      }
    }

    &:active,
    &:hover:active,
    &:focus {
      text-shadow: none;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 3px rgba(0, 0, 0, 0.15),
        rgba(#424257, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(#424257, 0.12) 0px 2px 5px 0px,
        rgba(#424257, 0.12) 0px 3px 9px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(#3a97d4, 0.28) 0px 0px 0px 3px;

      &.red {
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3), rgba(#424257, 0.16) 0px 0px 0px 1px,
          rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(#424257, 0.12) 0px 2px 5px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
          rgba(#d43939, 0.28) 0px 0px 0px 3px;
      }

      &.green {
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3), rgba(#424257, 0.16) 0px 0px 0px 1px,
          rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(#424257, 0.12) 0px 2px 5px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
          rgba(#60d439, 0.28) 0px 0px 0px 3px;
      }
    }
  }

  &.open .button {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 3px rgba(0, 0, 0, 0.15),
      rgba(#3a97d4, 0.28) 0px 0px 0px 3px;
    background: #e4e4e4;
  }
}

.button-group:not(.pagination-info):not(.disabled) .button-group {
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}

.blue-form-element {
  border: 1px solid #b2b7c5;
  @include box-shadow(0 1px 2px 0 rgba(0, 0, 0, 0.1));
  @include border-radius(4px);
  text-shadow: 0 1px 0 white;
  border-top-color: #adb2be;
  border-left-color: #a3a7b3;
  border-right-color: #a3a7b3;
  border-bottom-color: #8e92a4;
  font-weight: bold;
  color: #313235;
  font-size: 11px;
  background-color: #e9ebf0;
  @include link-colors(#313235, #313235, #313235, #313235, #313235);
  background: linear-gradient(to top, #e4e7eb 0%, #e9ebf0 50%, #f2f6fa 50%, white 100%);
  &.button:active {
    @include box-shadow(inset 0 1px 5px 0 #868fa4);
    text-shadow: none;
    background-color: #c0c8d4;
    background: linear-gradient(to bottom, #c0c8d4 0%, #d6dde7 100%);
    border-color: #898d98;
    border-top-color: #7d818c;
    border-bottom-color: #9498a4;
  }
  &.button {
    &:active,
    &.active {
      @include box-shadow(inset 0 1px 5px 0 #868fa4);
      text-shadow: none;
      background-color: #c0c8d4;
      background: linear-gradient(to bottom, #c0c8d4 0%, #d6dde7 100%);
      border-color: #898d98;
      border-top-color: #7d818c;
      border-bottom-color: #9498a4;
    }
  }
  &.focus {
    @include box-shadow(
      inset 0px 1px 3px 0 rgba(0, 0, 0, 0.12),
      0 0 4px 0 #5ca5e8,
      inset 0 0 2px 0 rgba(92, 165, 232, 0.66)
    );
    border-color: #699dce;
  }
  &.fancy-select {
    position: relative;
    select {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
    }
    .text {
      display: block;
      margin-right: 6px;
      padding: 0px 12px 0 12px;
      color: #333333;
      font-size: 12px;
    }
  }
}
.blue-select {
  position: relative;
  cursor: pointer;
  select {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 84px;
    cursor: pointer;
  }
  .text {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 84px;
    font-weight: 700;
    color: #0062bb;
    font-size: 11px;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    &:after {
      position: relative;
      display: inline-block;
      content: "";
      width: 0;
      height: 0;
      top: -2px;
      left: 4px;
      border-left: 2px solid transparent;
      border-right: 2px solid transparent;
      border-top: 2px solid #0062bb;
    }
  }
}

.grey-select {
  @include border-radius(4px);
  border: 1px solid #b1b1b1;
  @include box-shadow(0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 0 100px 2px rgba(255, 255, 255, 0.5));
  background-color: #fdfdfd;
  background: linear-gradient(to bottom, #fdfdfd 0%, #f8f8f8 51%, #ededed 51%, #ededed 100%);
  position: relative;
  border-top-color: #b1b1b1;
  border-bottom-color: #a2a2a2;
  &.focus {
    @include box-shadow(
      inset 0px 1px 3px 0 rgba(0, 0, 0, 0.12),
      0 0 4px 0 #5ca5e8,
      inset 0 0 2px 0 rgba(92, 165, 232, 0.66)
    );
    border-color: #699dce;
  }
  &.error {
    border-color: #dbaaab;
    border-top-color: #d79494;
    border-bottom-color: #dbaaab;
    .text {
      color: #b41515;
    }
    &.focus {
      @include box-shadow(
        inset 0px 1px 3px 0 rgba(0, 0, 0, 0.12),
        0 0 4px 0 #dbaaab,
        inset 0 0 2px 0 rgba(219, 170, 171, 0.66)
      );
    }
  }
  select {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
  }
  .text {
    display: block;
    margin-right: 6px;
    padding: 0 12px;
    color: black;
    font-size: 11px;
  }
}

.button-group {
  position: relative;
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
  /* IE hacks */
  zoom: 1;
  *display: inline;

  &.mini {
    display: inline-flex;
    span {
      white-space: nowrap;
    }
  }
}

.button-group {
  + {
    .button,
    .button-group {
      margin-left: 15px;
    }
  }
}
.pagination-info {
  line-height: 32px;
}

/* -------------------------------------------------------- BUTTON CONTAINER */
/* For mixing buttons and button groups, e.g., in a navigation bar */

.button-container {
  .button,
  .button-group {
    vertical-align: top;
  }
}

.button-group.open {
  *z-index: 1000;
  .dropdown-menu {
    display: block;
    margin-top: 1px;
  }
  .dropdown-toggle {
    box-shadow: rgba(#3a97d4, 0.28) 0px 0px 0px 3px;
  }
}
.dropdown {
  position: relative;
}

.dropdown-toggle {
  *margin-bottom: -3px;
  &:active {
    outline: 0;
  }
}

.open .dropdown-toggle {
  outline: 0;
}

.caret {
  @include inline-block;
  width: 0;
  height: 0;
  text-indent: -99999px;
  *text-indent: 0;
  vertical-align: top;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid black;
  opacity: 0.5;
  filter: alpha(opacity = 50);
  content: "\2193";
}

.dropdown {
  .caret {
    margin-top: 8px;
    margin-left: 2px;
  }
  &:hover .caret {
    opacity: 1;
    filter: alpha(opacity = 100);
  }
}

.dropdown-toggle {
  .caret {
    margin-top: 12px;
    margin-left: 0px;
  }
}

.open.dropdown .caret {
  opacity: 1;
  filter: alpha(opacity = 100);
}

.dropdown-menu {
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 1000;
  min-width: 115px;
  display: block;
  text-align: left;
  padding: 0px 2px !important;
  list-style: none;
  background-color: #fdfdfd;
  border: 1px solid #c5cbce;
  @include opacity(0);
  margin: -10px 0 0;
  padding: 10px 0 0;
  @include translate3d(0, 0, 0);
  pointer-events: none;
  @include transition(opacity 0.2s ease-out, margin 0.2s ease-out);
  @include border-radius(3px);
  @include box-shadow(
    inset 0 1px 0 rgba(255, 255, 255, 0.95),
    0 1px 8px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(0, 0, 0, 0.04)
  );
  font-size: 13px;
  &:after,
  &:before {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-bottom-color: #fff;
    border-width: 6px;
    left: 90%;
    margin-left: -6px;
  }
  &:before {
    border-bottom-color: #c5cbce;
    border-width: 7px;
    left: 90%;
    margin-left: -7px;
  }

  &.bottom-up {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
  }
  .divider {
    border-top: 1px solid #ebebeb;
    margin-top: 9px;
    margin-bottom: 10px;
    padding: 0;
    cursor: default;
  }
  li {
    float: none;
    padding: 5px 0 0;
    margin: 0 0 5px;
    white-space: nowrap;
    @include box-shadow(inset 0 1px 0 rgba(255, 255, 255, 0.45));
    border-top: 1px solid #eee;
    &:first-child {
      border-top: none;
      @include box-shadow(none);
    }

    &.custom {
      border-top: 1px solid #d1e7ff;
    }
  }
  a {
    position: relative;
    padding: 5px 20px 5px 10px;
    margin: 0 2px;
    @include border-radius(2px);
    border: 1px solid transparent;
    @include link-colors(#636b6e, #636b6e, #636b6e, #636b6e, #636b6e);
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.9);
    text-decoration: none;
    display: block;
    clear: both;
    font-weight: 600;
    white-space: nowrap;
    font-size: 12px;
    line-height: 18px;
    [class^="icon-"] {
      font-size: 125%;
      margin-right: 7px;
    }
    &:hover {
      text-decoration: none;
      background-color: #f5f5f5;
      border: 1px solid #eee;
      color: #222;
      @include box-shadow(inset 0 1px 0 rgba(255, 255, 255, 0.5));
    }
    &:active {
      border: 1px solid #ddd;
      border-top-color: #d6d6d6;
      background: #eaeaea;
      @include box-shadow(inset 0 0 3px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.5));
    }

    &.custom {
      @include link-colors(#3989d9, #3989d9, #3989d9, #3989d9, #3989d9);

      &:hover {
        border: 1px solid #d5e4f2;
        background: #e6f3ff;
        @include link-colors(#3072b5, #3072b5, #3072b5, #3072b5, #3072b5);
      }
    }
  }
  .active a {
    text-decoration: none;
    background-color: #eee;
    &:hover {
      text-decoration: none;
      background-color: #eee;
    }
  }
}

html.no-csstransitions .dropdown-menu {
  display: none;
}

.open {
  *z-index: 1000;
  .dropdown-toggle {
    border-color: #c6c6c6;
  }
  .dropdown-menu {
    display: block;
    @include opacity(0.99);
    pointer-events: all;
    margin: 0;
  }
}

/*
* === Legacy ===
*/

input[type="checkbox"] {
  vertical-align: top;
}
input[type="radio"] {
  vertical-align: top;
}

.closing-form-actions {
  @include pie-clearfix;
  padding: 30px 25px 0;
  .delete {
    float: left;
  }
  .submit {
    float: right;
  }
}

form.nimbu {
  select {
    margin: 0;
    font-size: 13px;
    display: inline-block;
    color: #222;
    vertical-align: top;
    height: 28px;
    line-height: 28px;
    padding: 2px;
    padding: initial;
    border: 1px solid #ccc;
    border-radius: 1px;
    max-width: 100%;
    min-width: 200px;
    &.full-width {
      width: 100%;
    }
  }
  .field {
    @include pie-clearfix;
    overflow: visible;
  }
  .fieldset {
    @include pie-clearfix;
    overflow: visible;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 30px;
    padding-bottom: 30px;
    &:last-child {
      border: none;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
  .horizontal {
    @include pie-clearfix;
    .input {
      float: left;
      margin-right: 15px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .with-two {
    @include pie-clearfix;
    .input {
      float: left;
      margin-right: 2%;
      width: 49%;
      select {
        width: 100%;
      }
      &:last-child {
        margin-right: 0px;
      }
      &.force-right {
        float: right;
      }
    }
  }
  .with-three {
    @include pie-clearfix;
    .input {
      float: left;
      margin-right: 2%;
      width: 32%;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .with-four {
    @include pie-clearfix;
    .input {
      float: left;
      margin-right: 2%;
      width: 23.5%;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  label {
    float: left;
    font-weight: bold;
    line-height: 16px;
    margin-top: 4px;
    margin-right: 1%;
    text-align: left;
    em {
      color: #828282;
      font-style: normal;
    }
    display: block;
    &:after {
      content: ":";
    }
  }
  .no-semicolon label:after {
    content: "";
  }
  .note {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #555;
    text-transform: none;
    position: relative;
    top: -1px;
    &.blocknote {
      display: block;
    }
  }

  .no-colon label:after {
    content: none;
  }

  .disabled label {
    color: #b4b4b4;
  }

  abbr {
    display: none;
  }
  &.show-required {
    abbr {
      display: block;
      font-size: 20px;
      line-height: 16px;
      margin-right: 3px;
      position: relative;
      border-bottom: none;
      cursor: pointer;

      &[title="minus"] {
        color: #bbbbbb;
        top: 1px;
      }

      &[title="required"] {
        color: #a0c1dc;
        top: 6px;
      }
    }
  }

  .input {
    @include pie-clearfix;
    overflow: visible;
    line-height: 30px;
    margin-bottom: 10px;
    &.with-separator {
      border-top: 1px solid #f2f2f2;
      padding-top: 15px;
    }
    &.radio_buttons {
      div.radio {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-top: 4px;
      }
      span.radio {
        margin-bottom: 0px;
        &.wide {
          margin-bottom: 10px;
        }
        &.first {
          margin-top: 25px;
        }
      }
      .wrapper > span.radio {
        display: flex;
        align-items: center;
        label {
          text-align: left;
          margin-top: 0;
          padding: 0px;
          line-height: 18px;
          margin-left: 5px;
          font-weight: normal;
          &:after {
            content: "";
          }
        }
      }
    }
    &.radio-with-notes {
      .wrapper > span.radio label {
        margin-bottom: 5px;
        font-weight: bold;
      }
      span.note {
        font-weight: normal;
      }
    }
    &.no-label {
      & > label {
        display: none;
      }
    }
    &.date-time {
      input {
        @include inline-block;
        margin-right: 3px;
        &.date-field {
          width: 103px;
        }
        &.time-field {
          width: 55px;
        }
      }
    }
    &.boolean {
      label {
        line-height: 32px;
      }
      .hint {
        @include inline-block;
        margin-left: 5px;
      }
      .inline {
        label {
          line-height: 28px;
        }
        .checker {
          margin-top: 4px;
        }
      }
    }
    &.check_boxes {
      .checkbox {
        display: block;
        @include pie-clearfix;
        padding-left: 0px;
        label {
          width: auto;
          font-weight: normal;
          &:after {
            content: "";
          }
        }
        .checker {
          float: left;
        }
      }
    }
    &.text {
      table.mceLayout {
        border: 1px solid #bbbbbb;
        border-color: #bbbbbb #dddddd #dddddd #bbbbbb;
      }
    }
    &.file {
      .flex-wrapper {
        @include display-flex;
        @include flex-direction(row);
        @include align-items(center);
      }

      .hint {
        margin-bottom: 5px;
      }

      .uploader-info {
        margin-left: 15px;

        &:first-child {
          margin-left: 0;
        }
      }

      .link-wrapper {
        margin-left: 15px;
        display: inline-block;
      }

      .remove-wrapper {
        margin-left: 15px;
        display: inline-block;

        label {
          font-weight: normal;
          display: inline-block;
          font-size: 12px;
          user-select: none;
          margin-bottom: 0;
        }
        .checker {
          display: inline-block;
        }
      }

      .warning {
        margin-top: 5px;
        font-size: 11px;
        color: #f10000;
      }
    }
    &.fifty-fifty {
      float: left;
      input {
        width: 200px;
      }
    }
    &.geo-point {
      .lat-lng-wrapper {
        padding-top: 15px;
        padding-left: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .lat-wrapper,
        .lng-wrapper {
          width: calc(50% - 5px);
        }
      }
    }
  }
  .fifty-fifty-wrapper {
    @include pie-clearfix;
    overflow: visible;
  }
  .wrapper {
    //@include pie-clearfix;
    margin-left: 180px;

    label {
      width: auto;
    }
  }

  .inline-label {
    @include inline-block;
    .wrapper {
      margin-left: 0;
    }
    label {
      width: auto;
      text-align: left;
      font-weight: normal;
      float: left;
      &:after {
        content: none;
      }
    }
    .checker {
      float: left;
    }
  }

  .hint {
    font-size: 11px;
    line-height: 17px;
    color: #777;
    margin: 5px 0px 0;
  }
  .help-inline + .hint {
    margin-top: 0px;
  }
  &.fixed-width {
    input {
      &[type="text"],
      &[type="email"],
      &[type="password"] {
        width: 450px;
      }
    }
    textarea {
      width: 450px;
    }
  }

  .fieldWithErrors {
    input,
    textarea,
    select {
      // background: none repeat scroll 0 0 #FFEEEE !important;
      border-color: #ffe5e9 !important;
    }
  }

  .error {
    //background: none repeat scroll 0 0 #ffe5e9;
    font-size: 13px;
    line-height: 20px;
    text-shadow: none;
    margin-bottom: 0px;
    //padding-top:15px;
    //padding-bottom:15px;
    label {
      color: darken(#9d261d, 10%);
      z-index: 3;
    }
    input {
      $red: #9d261d;
      $error-text: desaturate(lighten($red, 25%), 25%);
      border-color: $error-text;
      @include box-shadow(0 0 3px rgba(171, 41, 32, 0.25));
      //background:#fff;
      &:focus {
        border-color: darken($error-text, 10%);
        @include box-shadow(0 0 6px rgba(171, 41, 32, 0.5));
      }
    }
    .hint {
      color: #d68181;
    }
    abbr {
      color: #de9194;
    }
    h5 {
      padding: 5px 10px;
    }
    p {
      padding: 5px 10px;
    }
    & + .error {
      //padding-top:0px;
    }
  }

  input.error {
    background: none repeat scroll 0 0 #ffdddd;
  }

  &.inline,
  .inline {
    @include pie-clearfix;
    overflow: visible;
    .input {
      @include pie-clearfix;
      label {
        position: absolute;
        text-align: left;
        font-weight: normal;
        @include opacity(0.6);
        @include single-transition(opacity, 0.3s, ease-out, 0s);
        &:after {
          content: "";
        }
      }
      input[type="text"] {
        width: 100%;
      }
      &.focus {
        label {
          @include opacity(0.3);
          @include single-transition(opacity, 0.3s, ease-out, 0s);
        }
      }
      &.hastext {
        label {
          @include opacity(0);
          @include single-transition(opacity, 0.3s, ease-out, 0s);
        }
      }
      &.title-field {
        input {
          background: transparent;
        }
      }
      .wrapper {
        margin-left: 0;
      }
      &.boolean {
        .wrapper {
          float: left;
          height: 15px;
          .checker {
            top: -5px;
          }
        }
        label {
          margin-right: 5px;
          float: right;
          padding-top: 3px;
          font-weight: bold;
          position: inherit;
          width: auto;
          top: 0px;
        }
      }
    }
    .drag-handle {
      float: left;
      margin: 7px 10px 0px 0;
      cursor: move;
    }
  }
  .add-item {
    line-height: 42px;
    .plus {
      padding: 0 10px;
      font-size: 18px;
      font-weight: bold;
    }
    a {
      text-decoration: none;
    }
  }
  &.basic {
    label {
      float: none;
      width: auto;
      text-align: left;
      margin-bottom: 10px;
      &:after {
        content: none;
      }
    }
    header > label {
      margin-bottom: 0px;
    }
    .wrapper {
      margin-left: 0px;
    }
  }
  .normal {
    .wrapper {
      margin-left: 180px;
    }
    label {
      float: left;
      font-weight: bold;
      line-height: 16px;
      margin-right: 1%;
      text-align: right;
      width: 150px;
      em {
        color: #828282;
        font-style: normal;
      }
      display: block;
      &:after {
        content: ":";
      }
    }
  }
  .edit-custom-field {
    @include pie-clearfix;
  }
  .edit-categories,
  .edit-multi-categories {
    display: block;
    padding: 0px 10px;
    margin: 10px 30px 5px;
    font-size: 11px;
    border-left: 2px solid #e1e1e1;
    @include pie-clearfix;
  }
  .add-categories {
    display: block;
    padding: 5px;
    font-size: 11px;
  }
  .category-field {
    z-index: 10;
    position: relative;
    display: flex;
    flex-direction: row;

    .input {
      flex-grow: 1;
      margin: 3px 5px 3px 0px;
      input {
        padding: 3px 5px;
        font-size: 11px;
      }
    }
    label {
      display: none;
    }
    .category-drag-handle {
      cursor: move;
      display: block;
      margin: 12px 5px 0;
      color: #333;
      opacity: 0.5;

      &:hover {
        opacity: 0.8;
      }
    }
    .destroy-category a {
      display: block;
      overflow: hidden;
      color: #333;
      margin: 11px 0px 0px 3px;
      opacity: 0.5;

      &:hover {
        opacity: 0.8;
      }
    }
    @include pie-clearfix;
  }
  .ui-sortable {
    position: inherit;
    .ui-sortable-helper {
      background: #fff;
      z-index: 9999;
    }
  }
  .section-content {
    label {
      float: none;
      text-align: left;
      width: auto;
      margin: 0 0 5px 0;
      @include pie-clearfix;
    }
    .input {
      margin-bottom: 25px;
      &.no-margin {
        margin-bottom: 0px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
      &.with-margin {
        margin-bottom: 25px;
      }
      &.radio,
      &.boolean {
        label {
          margin-bottom: 0px;
        }
      }
    }
    .wrapper {
      margin-left: 0;
      margin-bottom: 0px;
    }
  }
  .vertical {
    .wrapper {
      margin-left: 0px;
    }
    .label-wrapper {
      margin-bottom: 10px;
    }
    .hint,
    .fieldset-description {
      margin: 5px 0 0px;
      font-size: 12px;
      line-height: 15px;
      color: #777;
    }
    .bigger {
      font-size: 14px;
    }
    label,
    .fieldset-title {
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      color: #222;
      margin: 0px;
      float: none;
      text-align: left;
      max-width: 100%;
    }
    .fieldset-description {
      margin-bottom: 15px;
    }
  }
  .wide {
    input {
      width: 100%;
    }
  }
  .full-witdh {
    select {
      width: 100%;
    }
  }
  .no-margin {
    .input {
      margin-bottom: 0px;
    }
  }
  .little-margin {
    .input {
      margin-bottom: 5px;
    }
  }
  .check_element_wrapper {
    @include pie-clearfix;
    display: block;
    margin-bottom: 30px;
  }
  .check_element {
    position: relative;
    padding-left: 34px;
    cursor: pointer;
    min-height: 39px;
    background-position: 7px 14px;
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url("../vendor/icons/font-awesome-pro/svgs/square-light.svg");

    .field-title,
    .field-description {
      user-select: none;
      transition: color 0.1s ease-out;
    }
    .field-title {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #888;
      padding-top: 4px;
    }
    &.without-description {
      .field-title {
        padding-top: 10px;
      }
    }
    .field-description {
      font-size: 12px;
      line-height: 15px;
      color: #aaa;
      padding-top: 0;
    }
    &.active {
      background-position: 6px 12px;
      background-size: 16px 16px;
      background-image: url("../vendor/icons/font-awesome-pro/svgs/check-solid.svg");
      .field-title {
        color: #000;
      }
      .field-description {
        color: #444;
      }

      &.disabled {
        opacity: 0.3;
      }
    }
  }
  .notification-attachment {
    div.check_element {
      display: inline-block;
      margin-left: 2em;
    }
  }
}
.sub-section {
  background: #fafafa;
  border: solid 1px #ddd;
  padding: 25px 28px;
  @include box-shadow(0 1px 3px rgba(0, 0, 0, 0.09) inset);
  @include pie-clearfix;
  margin-bottom: 25px;
  margin-top: 15px;
  position: relative;
  &:last-child {
    margin-bottom: 0px;
  }
  &:after {
    display: block;
    width: 25px;
    height: 9px;
    position: absolute;
    left: 20px;
    top: -3px;
    background: transparent url("~images/dropdown-icon.png") top left no-repeat;
  }
  &.white-inputs {
    input {
      background: #fff;
    }
  }
  &.no-margin {
    margin-bottom: 0;
  }
}
.sub-section-header {
  @include pie-clearfix;
  & > div {
    line-height: 17px;
    font-weight: 700;
    text-align: left;
    color: #444;
    font-size: 11px;
    text-transform: uppercase;
    padding: 0 10px 10px 0px;
    border-bottom: 1px solid #ececec;
    @include user-select(none);
    margin-bottom: 15px;
  }
  .centered {
    text-align: center;
  }
}
.sub-sub-section {
  padding: 22px;
  margin-left: 12px;
  border-left: 2px solid #dddddc;
  background-color: #f4f4f4;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }
}
.with-note {
  .input {
    margin-bottom: 10px;
  }
}

.nimbu-modal {
  form.nimbu:not(.vertical):not(.basic):not(.inline) {
    .input:not(.vertical) {
      label {
        margin-top: 8px;
      }
    }
    .radio_buttons .wrapper {
      label {
        margin-top: 0;
      }
    }
  }
  fieldset + fieldset {
    margin-top: 15px;
  }
}

form .footer,
form .actions,
.nimbu-modal .actions {
  @include border-bottom-radius(5px);
  background: #eef0f2;
  background: linear-gradient(#eef0f2, #e5e8ea);
  box-shadow: inset 0 1px 0px #fff;
  @include clearfix;
  border-top: 1px solid #d1d7e0;
  overflow: hidden;
  padding: 13px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  p {
    margin-right: auto;
    margin-bottom: 0;
  }
  & > a,
  & > div {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
  .delete-action {
    color: $alertColor;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
    i {
      margin-right: 10px;
    }
  }
  input,
  button,
  .button {
    margin-right: 15px;
    margin-bottom: 0px;
    padding: 0 18px;
    line-height: 28px;
    height: 28px;
    font-size: 11px;
    text-transform: uppercase;
    &.no-caps {
      text-transform: none;
    }

    &:active {
      outline: none;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.sub-section .actions {
  margin: 28px -28px -25px;
  @include border-bottom-radius(0px);
}

/* Tabs ---------------------- */

dl.tabs {
  border-bottom: solid 1px darken($white, 10%);
  display: block;
  height: $tabHeight;
  padding: 0;
  margin-bottom: 20px;
  &.contained {
    margin-bottom: 0;
  }

  dt {
    color: darken($white, 30%);
    cursor: default;
    display: block;
    float: left;
    font-size: ms(0) - em(2);
    height: $tabHeight;
    line-height: $tabHeight;
    padding: 0 9px 0 20px;
    width: auto;
    text-transform: uppercase;
    &:first-child {
      padding: 0 9px 0 0;
    }
  }

  dd {
    display: block;
    float: left;
    padding: 0;
    margin: 0;
    a {
      color: lighten($txtColor, 30%);
      display: block;
      font-size: ms(0);
      height: $tabHeight;
      line-height: $tabHeight;
      padding: 0px ms(1) * 1.4;
    }
    &.active {
      border-top: 3px solid $mainColor;
      margin-top: -3px;
      a {
        cursor: default;
        color: lighten($txtColor, 10%);
        background: #fff;
        border-left: 1px solid darken($white, 10%);
        border-right: 1px solid darken($white, 10%);
        font-weight: bold;
      }
    }
    &:first-child {
      margin-left: 0;
    }
  }

  &.vertical {
    height: auto;
    border-bottom: 1px solid darken($white, 10%);
    dt,
    dd {
      float: none;
      height: auto;
    }

    dd {
      border-left: 3px solid darken($white, 20%);
      a {
        background: darken($white, 5%);
        border: none;
        border: 1px solid darken($white, 10%);
        border-width: 1px 1px 0 0;
        color: #555;
        display: block;
        font-size: ms(0);
        height: auto;
        line-height: 1;
        padding: 15px 20px;
        @include box-shadow(0 1px 0 $shinyEdge inset);
      }
      &.active {
        margin-top: 0;
        border-top: 1px solid lighten($black, 30%);
        border-left: 4px solid lighten($black, 10%);
        a {
          background: lighten($black, 30%);
          border: none;
          color: #fff;
          height: auto;
          margin: 0;
          position: static;
          top: 0;
          @include box-shadow(0 0 0);
        }
      }
      &:first-child a.active {
        margin: 0;
      }
    }
  }

  &.pill {
    border-bottom: none;
    margin-bottom: 10px;
    dd {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      a {
        @include border-radius(1000px);
        background: darken($white, 10%);
        height: $tabHeight - 14;
        line-height: $tabHeight - 14;
        color: #666;
      }

      &.active {
        border: none;
        margin-top: 0;
        a {
          background-color: $mainColor;
          border: none;
          color: #fff;
        }
      }
    }
    &.contained {
      border-bottom: solid 1px #eee;
      margin-bottom: 0;
    }
  }

  &.two-up,
  &.three-up,
  &.four-up,
  &.five-up {
    dt a,
    dd a {
      padding: 0 ms(1);
      text-align: center;
      overflow: hidden;
    }
  }
  &.two-up dt,
  &.two-up dd {
    width: 50%;
  }
  &.three-up dt,
  &.three-up dd {
    width: 33.33%;
  }
  &.four-up dt,
  &.four-up dd {
    width: 25%;
  }
  &.five-up dt,
  &.five-up dd {
    width: 20%;
  }
}

ul.tabs-content {
  display: block;
  margin: 0 0 20px;
  padding: 0;
  & > li {
    display: none;
    &.active {
      display: block;
    }
  }
  &.contained {
    padding: 0;
    & > li {
      border: solid 0 darken($white, 10%);
      border-width: 0 1px 1px 1px;
      padding: 20px;
    }
    &.vertical > li {
      border-width: 1px 1px 1px 1px;
    }
  }
}

.no-js ul.tabs-content > li {
  display: block;
}

#product {
  .sub-section {
    .radio label {
      font-weight: normal;
    }
  }
}

.edit-address-modal {
  .name-fields,
  .street-fields,
  .city-fields,
  .phone-fields {
    @include pie-clearfix;
  }
  .input {
    margin-bottom: 15px !important;
  }
  .radio_buttons .wrapper {
    min-width: 300px;
  }
}

.edit-invoice-modal {
  width: 500px;

  .inline {
    @include clearfix;
    margin-bottom: 5px;
    .input {
      width: 45%;
      float: left;
      margin: 0 !important;
      padding: 0 !important;
    }
    span.separator {
      float: left;
      display: block;
      width: 15px;
      line-height: 30px;
      text-align: center;
    }
    .remove_nested_fields {
      float: left;
      margin-left: 10px;
      color: #333;
      overflow: hidden;
      display: block;
      margin-top: 8px;
      opacity: 0.7;
      transition: opacity 0.3s ease-out;
      &:hover {
        opacity: 1;
        transition: opacity 0.3s ease-out;
      }
    }
  }
}

// .custom-field {
//   @include pie-clearfix;
//   .drag-handle-wrapper {
//     float: right;
//     width: 25px;
//     .drag-handle {
//       cursor: move;
//       width: 25px;
//       display: block;
//       height: 25px;
//       margin-top: 2px;
//       background: transparent url("<%= asset_path 'v2/move.png' %>") center center no-repeat;
//       @include opacity(0.7);
//       @include single-transition(opacity,0.3s,ease-out,0s);
//       &:hover {
//         @include opacity(1);
//         @include single-transition(opacity,0.3s,ease-out,0s);
//       }
//     }
//   }
//   .delete-handle-wrapper {
//     float: right;
//     width: 25px;
//     .remove a {
//       text-indent: -9999px;
//       overflow: hidden;
//       display: block;
//       background: transparent url("<%= asset_path 'v2/close.png' %>") center center no-repeat;
//       width: 13px;
//       height: 13px;
//       margin-top: 8px;
//       @include opacity(0.7);
//       @include single-transition(opacity,0.3s,ease-out,0s);
//       &:hover {
//         @include opacity(1);
//         @include single-transition(opacity,0.3s,ease-out,0s);
//       }
//     }
//   }
//   .edit-custom-field {
//     float: left;
//     width: 85%;
//     @include pie-clearfix;
//     .basics {
//       @include pie-clearfix;
//       .input {
//         margin-bottom: 5px;
//         float: left;
//         width: 20%;
//         margin-right: 2%;
//         position:relative;
//         label {
//           font-weight: normal;
//           color: #999;
//           margin-bottom:0px;
//           position:absolute;
//           z-index: 1;
//           @include user-select(none);
//           top:$formSpacing * 0.5+2;
//           left:$formSpacing * 0.5+4;
//           @include single-transition(opacity,0.3s,ease-out);
//         }
//         &.focus label {
//           @include opacity(0.45);
//           @include single-transition(opacity,0.3s,ease-out);
//         }
//         &.hastext label {
//           @include opacity(0);
//           @include single-transition(opacity,0.3s,ease-out);
//           z-index: 0 !important;
//         }
//         &.select, &.country {
//           label { display:none; }
//         }
//         &.radio_buttons {
//           label {
//             position: relative;
//           }
//         }
//       }
//     }
//     .options {
//       @include pie-clearfix;
//       .input {
//         float: left;
//         margin-right: 2%;
//         margin-bottom: 5px;
//         &.boolean {
//           .wrapper {
//             float:left;
//           }
//           label {
//             font-weight:bold;
//             position:relative;
//             top:0px;
//             left:0px;
//             @include inline-block;
//           }
//         }
//       }
//     }
//     .field-label-wrapper {
//       width: 34%;
//     }
//     .field-type-wrapper {
//       margin-right: 0;
//     }
//     input {
//       position: relative;
//       z-index: 2;
//       background:transparent;
//     }
//   }
//   .help-inline {
//     display: block;
//   }
//   .category-list {
//     .category-field .input {
//       margin-bottom: 2px;
//       input {
//         height: auto;
//       }
//     }
//   }
// }
.custom-gallery {
  .images-list {
    @include pie-clearfix;
    position: relative;
    padding: 30px 30px 0;
    &.empty {
      display: none;
    }
    .gallery-image {
      @include pie-clearfix;
      .input {
        float: left;
      }
      .image {
        float: left;
        position: relative;
        cursor: move;
        border: 1px solid #dbdada;
        padding: 5px;
        margin-right: 15px;
        margin-bottom: 15px;
        background: #fff;
        @include box-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
        img {
          border: 1px solid lighten(#dbdada, 5%);
          margin-bottom: -3px;
          max-width: 100px;
          max-height: 100px;
        }
        .destroy a {
          display: none;
          width: 30px;
          height: 30px;
          text-indent: -9999px;
          background: transparent url("~images/fancy_close.png") center center no-repeat;
          overflow: hidden;
          top: -10px;
          right: -10px;
          cursor: pointer;
          position: absolute;
        }
        &:hover {
          .destroy a {
            display: block;
          }
        }
      }
    }
  }
}
.fluid-container {
  display: table;
  .fixed-container {
    display: table-cell;
    white-space: nowrap;
  }
  .fluid-object {
    display: block;
    float: left;
  }
  .variable-container {
    display: table-cell;
    width: 100%;
  }
}
.input-prefix {
  background: #eee;
  color: #666;
  border: 1px solid #ccc;
  display: inline-block;
  font-size: 13px;
  height: 18px;
  line-height: 18px;
  padding: 4px;
  border-right: none;
  padding: 2px 5px 0px 5px;
  @include border-left-radius(2px);
}

ul.tagit {
  padding: 1px 5px;
  overflow: auto;
  margin-left: inherit; /* usually we don't want the regular ul margins. */
  margin-right: inherit;
  @include box-shadow(inset 0px 1px 1px 0 rgba(0, 0, 0, 0.12));
  @include border-radius(0px);
  border: 1px solid #cccccc;
  min-height: 24px;
  outline: none;
  border-top-color: #bbbbbb;
  font-family: $primaryFont;
  font-weight: normal;
  color: #555;
  background: #fcfcfc;
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0 8px;
  font-size: 13px;
}
ul.tagit li {
  display: block;
  float: left;
  margin: 3px 3px 3px 0;
}
ul.tagit li.tagit-choice {
  position: relative;
  line-height: inherit;
}

ul.tagit li.tagit-choice-read-only {
  padding: 1px 5px 1px 5px;
}

ul.tagit li.tagit-choice-editable {
  padding: 1px 18px 1px 5px;
}

ul.tagit li.tagit-new {
  padding: 0px;
  margin: 0px;
}

ul.tagit li.tagit-choice a.tagit-label {
  cursor: pointer;
  text-decoration: none;
}
ul.tagit li.tagit-choice .tagit-close {
  cursor: pointer;
  position: absolute;
  right: 0.1em;
  top: 50%;
  margin-top: -8px;
  line-height: 17px;
}

/* used for some custom themes that don't need image icons */
ul.tagit li.tagit-choice .tagit-close .text-icon {
  display: none;
}

ul.tagit li.tagit-choice input {
  display: block;
  float: left;
  margin: 2px 5px 2px 0;
}
ul.tagit input[type="text"] {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;

  border: none;
  margin: 0;
  padding: 0;
  width: inherit;
  background-color: inherit;
  outline: none;
}

/* Optional scoped theme for tag-it which mimics the zendesk widget. */
ul.tagit li.tagit-choice {
  @include border-radius(2px);
  border: 1px solid #cbdbee;
  background: none;
  background-color: #e9f3ff;
  font-weight: normal;
  font-size: 13px;
}
ul.tagit li.tagit-choice .tagit-label:not(a) {
  color: #555;
}
ul.tagit li.tagit-choice a.tagit-close {
  text-decoration: none;
}
ul.tagit li.tagit-choice .tagit-close {
  right: 0.4em;
}
ul.tagit li.tagit-choice .ui-icon {
  display: none;
}
ul.tagit li.tagit-choice .tagit-close .text-icon {
  display: inline;
  font-family: arial, sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #777;
}
ul.tagit li.tagit-choice:hover,
ul.tagit li.tagit-choice.remove {
}
ul.tagit li.tagit-choice a.tagLabel:hover,
ul.tagit li.tagit-choice a.tagit-close .text-icon:hover {
  color: #222;
}
ul.tagit input[type="text"] {
  color: #333333;
  background: none;
}
.ui-widget {
  font-size: 1.1em;
}
.remove a {
  float: left;
  overflow: hidden;
  display: block;
  color: #000;
  width: 16px;
  margin: 8px 0px 0px 0px;
}
.manage-attributes-link {
  position: absolute;
  top: 0;
  right: 5%;
  font-size: 13px;
  @include link-colors(#8f8f8f, #676767, #8f8f8f, #8f8f8f, #676767);
}
.selectize-control.subproducts {
  .selectize-dropdown {
    [data-selectable] {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      height: 50px;
      position: relative;
      overflow: hidden;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      padding: 10px 10px 10px 70px;
      &:last-child {
        border-bottom: 0 none;
      }
    }
    .by {
      font-size: 11px;
      opacity: 0.8;
      &::before {
        content: "by ";
      }
    }
    .name {
      font-weight: bold;
      font-size: 15px;
      margin-right: 5px;
    }
    .sub-product-wrapper {
      overflow: hidden;
    }
    .description {
      font-size: 11px;
      color: #a0a0a0;
    }
    .actors,
    .description,
    .title {
      display: block;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .actors {
      font-size: 10px;
      color: #a0a0a0;
      span {
        color: #606060;
      }
    }
    img {
      height: 50px;
      width: 50px;
      left: 10px;
      position: absolute;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.04);
    }
    .meta {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 10px;
      li {
        margin: 0;
        padding: 0;
        display: inline;
        margin-right: 10px;
        span {
          font-weight: bold;
        }
      }
    }
  }
  &::before {
    -moz-transition: opacity 0.2s;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    content: " ";
    z-index: 2;
    position: absolute;
    display: block;
    top: 12px;
    right: 34px;
    width: 16px;
    height: 16px;
    background: url("~images/spinner.gif");
    background-size: 16px 16px;
    opacity: 0;
  }
  &.loading::before {
    opacity: 0.4;
  }
}
.selectize-control.custom-selectize {
  .selectize-dropdown {
    [data-selectable] {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      position: relative;
      overflow: hidden;
      box-sizing: content-box;
      padding: 8px;
      &:last-child {
        border-bottom: 0 none;
      }
    }
    .option-name {
      font-weight: 500;
      font-size: 15px;
    }
  }
}
.selectize-customer-wrapper,
.selectize-role-wrapper {
  overflow: hidden;
  display: flex;
  align-items: center;

  .option-name {
    margin-right: 5px;
  }

  .option-email,
  .option-description {
    font-size: 11px;
    color: #a0a0a0;
  }
}
.multi {
  .selectize-customer-wrapper,
  .selectize-role-wrapper {
    .option-email,
    .option-description {
      color: #fff;
    }
  }
}
.selectize-control.products {
  .selectize-dropdown {
    [data-selectable] {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      height: 50px;
      position: relative;
      overflow: hidden;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      padding: 10px 10px 10px 70px;
      &:last-child {
        border-bottom: 0 none;
      }
    }
    .name {
      font-weight: bold;
      font-size: 15px;
      margin-right: 5px;
      white-space: nowrap;
    }
    .sub-product-wrapper {
      overflow: hidden;
    }
    .description {
      font-size: 11px;
      color: #a0a0a0;
      display: block;
      white-space: nowrap;
    }
    img {
      height: 50px;
      width: 50px;
      left: 10px;
      position: absolute;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.04);
    }
  }
}
.selectize-control.tags {
  .selectize-dropdown {
    [data-selectable] {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      position: relative;
      overflow: hidden;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      padding: 10px;
      &:last-child {
        border-bottom: 0 none;
      }
    }
  }
}
.selectize-control.product-variants {
  .selectize-dropdown {
    [data-selectable] {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      position: relative;
      overflow: hidden;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      padding: 10px 10px 10px 10px;
      &:last-child {
        border-bottom: 0 none;
      }
    }
    .name {
      font-size: 13px;
      white-space: nowrap;
    }
    .sub-product-wrapper {
      overflow: hidden;
    }
  }
}
.input-inside-radio {
  float: left;
}
#custom_field_list {
  .item-header {
    border-bottom: 1px solid #ececec;
    padding-left: 30px;
    padding-right: 50px;
    @include clearfix;
    & > div {
      float: left;
      width: 32%;
      margin-right: 2%;
      padding: 0px 15px 7px 0;
      font-weight: 700;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .drag-handle-wrapper {
    width: 30px;
    padding: 11px 0px;
  }
  .characteristics {
    width: 50px;
  }
  .links {
    width: 50px;
    position: absolute;
    top: 13px;
    right: 0;
  }
  .options {
    margin: 9px 50px 15px 30px;
  }
  .drag-handle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 24px;
    font-size: 12px;
    cursor: move;
    margin: 0;
    float: none;
    opacity: 0.3;
    &:hover {
      opacity: 0.8;
    }
  }
  .settings {
    color: #333;
    display: inline-block;
    a {
      color: #333;
      padding: 5px 0;
      overflow: hidden;
      width: 16px;
      margin: 0px 0px 0px 10px;
      display: block;
      opacity: 0.5;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .remove {
    color: #333;
    display: inline-block;
    a {
      padding: 5px 0;
      font-size: 12px;
      color: #333;
      margin: 0px 0px 0px 0px;
      float: none;
      opacity: 0.5;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .icons {
    text-align: right;
    width: 50px;
    position: absolute;
    z-index: 19;
    top: -14px;
    right: 12px;
  }
  .required-icon,
  .translated-icon {
    cursor: pointer;
    width: 16px;
    font-size: 13px;
    margin-top: 17px;
    display: inline-block;
    color: #e0e0e0;
  }
  .required-icon.active {
    color: #d35400;
  }
  .required-icon.conditional {
    color: #ed8e00;
  }
  .translated-icon.active {
    color: #8ebe00;
  }
  .drag-handle-wrapper {
    float: left;
  }
  .basics {
    @include pie-clearfix;
    position: relative;
  }
  .required-fields {
    position: relative;
    padding: 10px 0;
    margin: 0 50px 0 30px;
    .input {
      margin: 0;
      float: left;
      width: 32%;
      &.string {
        margin-right: 2%;
      }
    }
  }
}

.asset-form {
  label {
    width: 100px;
  }
  .help-inline {
    max-width: 250px;
  }
}

.iti__country-list {
  z-index: 10;
}

.settings-consent {
  .react-select__menu {
    z-index: 100;
  }
  #privacy-policy-link {
    @include pie-clearfix;
    .input {
      float: left;
      &.kind {
        width: 25%;
        margin-right: 1%;
      }
      &.url {
        width: 74%;
        margin: 0;
      }
      &.page {
        width: 74%;
        margin: 0;
      }
    }
  }
  #consent-purposes-list {
    .input {
      margin: 0;
    }
    .purposes-header {
      padding-bottom: 5px;
      border-bottom: 1px solid #ececec;
      .label {
        font-weight: 700;
      }
    }
    .purpose-wrapper {
      margin-top: 7px;
    }
    .purposes-header,
    .purpose {
      @include clearfix;
      & > div {
        float: left;
        padding: 0px 15px 0px 0;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  #consent-purposes-list {
    .purposes-header,
    .purpose {
      & > div {
        &.purpose-name {
          width: 25%;
        }
        &.purpose-description {
          width: calc(75% - 12px);
        }
        &.remove {
          width: 12px;
          padding-right: 0;
          color: #333;
          display: inline-block;
          padding-top: 8px;
          a {
            cursor: pointer;
            width: 11px;
            padding: 0;
            font-size: 12px;
            color: #333;
            margin: auto;
            float: none;
            opacity: 0.5;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
  #consent-applications-list {
    .application {
      position: relative;
      @include clearfix;
      border: 1px solid #e5e6e7;
      background-color: #f8f9fa;
      border-radius: 4px;
      margin-bottom: 15px;
      padding: 20px 15px 15px 15px;
      overflow: visible;

      .application-name {
        float: left;
        width: 20%;
        margin-right: 1%;
      }
      .application-title {
        float: left;
        width: 25%;
        margin-right: 1%;
      }
      .application-purposes {
        float: left;
        z-index: 10;
        width: 53%;
      }
      .application-description {
        width: 100%;
        @include clearfix;
      }
      .checkboxes-wrapper {
        @include clearfix;
        display: flex;
        gap: 1%;
      }
      .application-required,
      .application-default,
      .application-opt-out {
        flex: 1;
      }
      .advanced {
        border-top: 1px solid #e5e6e7;
        padding-top: 10px;
      }
      .icon-buttons {
        position: absolute;
        top: 0;
        right: 4px;
        display: flex;
        align-self: center;

        .remove,
        .deprecated-settings,
        .settings {
          color: #333;
          margin: 4px;
          a {
            cursor: pointer;
            width: 11px;
            padding: 0;
            font-size: 12px;
            color: #333;
            margin: auto;
            float: none;
            opacity: 0.5;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
  #consent-cookies-list {
    .input {
      margin: 0;
    }
    ul {
      margin-top: 5px;
    }
    .cookies-header {
      padding-bottom: 5px;
      border-bottom: 1px solid #ececec;
      .label {
        font-weight: 700;
      }
    }
    .cookie-wrapper {
      margin-top: 7px;
      display: inline-block;
      width: 100%;
    }
    .cookies-header,
    .cookie {
      @include clearfix;
      & > div {
        float: left;
        padding: 0px 15px 0px 0;
        &:last-child {
          margin-right: 0;
        }
        &.cookie-name {
          width: calc(25% - 5px);
        }
        &.cookie-path {
          width: calc(25% - 5px);
        }
        &.cookie-domain {
          width: calc(25% - 5px);
        }
        &.cookie-application {
          width: calc(25% - 5px);
          overflow: visible;
        }
        &.remove {
          width: 12px;
          padding-right: 0;
          color: #333;
          display: inline-block;
          padding-top: 8px;
          a {
            cursor: pointer;
            width: 11px;
            padding: 0;
            font-size: 12px;
            color: #333;
            margin: auto;
            float: none;
            opacity: 0.5;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
    .cookie {
      overflow: visible;
    }
  }
}

.file-info {
  font-size: 12px;
  margin-bottom: 5px;

  .file-size {
    color: #999;
  }
}
