/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
  -webkit-pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -moz-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  -o-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 9999;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 14px;
  height: 14px;

  border:  solid 2px transparent;
  border-top-color:  #29d;
  border-left-color: #29d;
  border-radius: 10px;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  -moz-animation:    nprogress-spinner 400ms linear infinite;
  -ms-animation:     nprogress-spinner 400ms linear infinite;
  -o-animation:      nprogress-spinner 400ms linear infinite;
  animation:         nprogress-spinner 400ms linear infinite;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-moz-keyframes nprogress-spinner {
  0%   { -moz-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
@-o-keyframes nprogress-spinner {
  0%   { -o-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@-ms-keyframes nprogress-spinner {
  0%   { -ms-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}
