#peek {
  background: #000;
  height: 35px;
  line-height: 35px;
  color: #999;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);

  .hidden {
    display: none;
    visibility: visible;
  }

  &.production {
    background: url('production.gif') repeat 0 0;
  }

  &.staging {
    background: url('staging.gif') repeat 0 0;
  }

  &.development {
    background: url('development.gif') repeat 0 0;
  }

  .wrapper {
    width: 800px;
    margin: 0 auto;
  }

  // UI Elements
  .bucket {
    background: #111;
    display: inline-block;
    padding: 4px 6px;
    font-family: Consolas, 'Liberation Mono', Courier, monospace;
    line-height: 1;
    color: #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2), inset 0 1px 2px rgba(0, 0, 0, 0.25);

    .hidden {
      display: none;
    }

    &:hover .hidden {
      display: inline;
    }
  }

  strong {
    color: #fff;
  }

  .view {
    margin-right: 15px;
    float: left;

    &:last-child {
      margin-right: 0;
    }
  }

  .css-truncate {
    &.css-truncate-target,
    .css-truncate-target {
      display: inline-block;
      max-width: 125px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: top;
    }

    &.expandable:hover .css-truncate-target,
    &.expandable:hover.css-truncate-target {
      max-width: 10000px !important;
    }
  }
}
