@import "settings";

@import "~compass-mixins/lib/compass/utilities/general/clearfix";
@import "~compass-mixins/lib/compass/typography/links/link-colors";
@import "~compass-mixins/lib/compass/css3/inline-block";
@import "~compass-mixins/lib/compass/css3/box-sizing";
@import "~compass-mixins/lib/compass/css3/border-radius";
@import "~compass-mixins/lib/compass/css3/box-shadow";
@import "~compass-mixins/lib/compass/css3/transition";
@import "~compass-mixins/lib/compass/css3/opacity";
@import "~compass-mixins/lib/compass/css3/images";

body#minimal #four-oh-four {
  text-align: center;
  max-width: 900px;
  padding-left: 30px;
  padding-right: 30px;
  margin: auto;

  h1 {
    font-family: $primaryFont;
    color: #3d555f;
    text-align: center;
    font-size: 32px;
    line-height: 40px;
  }

  h2 {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    font-family: $primaryFont;
    font-weight: 500;
    color: #608796;
    margin-bottom: 5px;
  }
}
