@import 'base';
@import '~compass-mixins/lib/compass/css3/user-interface';
@import '~compass-mixins/lib/compass/layout/sticky-footer';
@import '~compass-mixins/lib/compass/utilities/general/clearfix';
@import '~compass-mixins/lib/compass/typography/links/link-colors';
@import '~compass-mixins/lib/compass/css3/inline-block';
@import '~compass-mixins/lib/compass/css3/box-sizing';
@import '~compass-mixins/lib/compass/css3/border-radius';
@import '~compass-mixins/lib/compass/css3/box-shadow';
@import '~compass-mixins/lib/compass/css3/transition';
@import '~compass-mixins/lib/compass/css3/opacity';
@import '~compass-mixins/lib/compass/css3/images';

#system {
  span.limit {
    font-size: 11px;
    color: #999;
  }
  span.deleting {
    float: right;
  }
  .mail-history-item {
    @include pie-clearfix;
    .date {
      float: left;
      width: 100px;
      text-align: center;
    }
    .event {
      float: left;
      width: 100px;
      text-align: center;
    }
    .message {
      margin-left: 200px;
      span {
        padding: 0 10px 0 0;
      }
    }
  }
  figure {
    margin-bottom: 30px;
    margin-top: 15px;
  }

  &.spam {
    .time {
      white-space: nowrap;
    }

    .site a {
      display: block;
      width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .deleted {
      color: #999;
      font-size: 11px;
      font-style: italic;
    }

    .description {
      font-size: 12px;
      color: #999;
    }

    th.ip, td.ip {
      width: 100px;
    }

    .content {
      width: 60%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .feedback {
      width: 20%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
