@import 'base';
@import '~compass-mixins/lib/compass/css3/user-interface';
@import '~compass-mixins/lib/compass/layout/sticky-footer';
@import '~compass-mixins/lib/compass/utilities/general/clearfix';
@import '~compass-mixins/lib/compass/typography/links/link-colors';
@import '~compass-mixins/lib/compass/css3/inline-block';
@import '~compass-mixins/lib/compass/css3/box-sizing';
@import '~compass-mixins/lib/compass/css3/border-radius';
@import '~compass-mixins/lib/compass/css3/box-shadow';
@import '~compass-mixins/lib/compass/css3/transition';
@import '~compass-mixins/lib/compass/css3/opacity';
@import '~compass-mixins/lib/compass/css3/images';

.block-list {
  .block {
    @include border-radius(5px);
    @include clearfix;
    background: #f5f5f5;
    background-image: linear-gradient(to top, #fdfdfd, #e9edf0);
    @include box-shadow(0 1px 1px 0px rgba(0, 0, 0, 0.05));
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #c7c7c7;
    display: block;
    margin: 15px;
    border: 1px solid #bdc3c5;
    border-color: #b9c8ce #b9c8ce #b9c8ce;
    padding: 15px;
    &.with-description {
      padding: 9px 15px;
      .block-links {
        padding-top: 8px;
      }
    }
  }

  .block-data {
    float: left;
    .block-title a {
      color: #0c1010;
      text-decoration: none;
      display: block;
      font-size: 16px;
      text-shadow: 0 1px 0 #fff;
    }
    .block-description a {
      color: #2b2d2e;
      text-decoration: none;
      display: block;
      font-size: 11px;
      text-shadow: 0 1px 0 #fff;
    }
  }
  .block-links {
    float: right;
    & > div {
      float: left;
      font-size: 11px;
      padding: 2px 14px;
      color: #365372;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: -1px;
        width: 1px;
        height: 100%;
        margin-top: 0;
        top: 0;
        background-image: linear-gradient(
          to bottom,
          rgba(182, 196, 207, 0) 0%,
          #b6c4cf 50%,
          #b6c4cf 50%,
          #b6c4cf 80%,
          rgba(182, 196, 207, 0) 100%
        );
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: -2px;
        width: 1px;
        height: 100%;
        margin-top: 0;
        top: 0;
        background-image: linear-gradient(
          to bottom,
          rgba(#fff, 0) 0%,
          #fff 50%,
          rgba(#fff, 0) 100%
        );
      }
      &:first-child {
        &:after {
          display: none;
        }
        &:before {
          display: none;
        }
      }
      a {
        @include link-colors(#2d7292, #005279, #005279, #2d7292, #005279);
      }
    }
  }
}
.app-data-table {
  td.small {
    font-size: 11px;
    color: #999;
  }
}

.cm-panels-bottom {
  font-size: 20px;
}
