@import 'base';
@import 'compass-mixins/lib/compass/css3/_box-sizing.scss';
@import 'compass-mixins/lib/compass/typography/links/_link-colors.scss';

/* HTML5 Boilerplate  */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
audio:not([controls]) {
  display: none;
}
[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
html,
button,
input,
select,
textarea {
  font-family: sans-serif;
  color: #222;
}
body {
  margin: 0;
  font-size: 1em;
  line-height: 1.4;
}

::-moz-selection {
  background: #008adb;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #008adb;
  color: #fff;
  text-shadow: none;
}

a:hover,
a:active {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
blockquote {
  margin: 1em 40px;
}
dfn {
  font-style: italic;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
}
q:before,
q:after {
  content: '';
  content: none;
}
small {
  font-size: 85%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

ul,
ol {
  margin: 1em 0;
  padding: 0 0 0 40px;
}
dd {
  margin: 0 0 0 40px;
}
nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0;
}

form {
  margin: 0;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

label {
  cursor: pointer;
}
legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
  white-space: normal;
}
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}
button,
input {
  line-height: normal;
}
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
  *width: 13px;
  *height: 13px;
}
input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}
input:valid,
textarea:valid {
}
input:invalid,
textarea:invalid {
  background-color: #f0dddd;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
td {
  vertical-align: top;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: black;
  padding: 0.2em 0;
}

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
  *line-height: 0;
}
.ir br {
  display: none;
}
.hidden {
  display: none !important;
  visibility: hidden;
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.invisible {
  visibility: hidden;
}
.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}

@media print {
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: ' (' attr(href) ')';
  }
  abbr[title]:after {
    content: ' (' attr(title) ')';
  }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

/* Base Type Styles Using Modular Scale ---------------------- */

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
  font-size: ms(0);
}

p {
  font-size: ms(0);
  line-height: 1.6;
  margin-bottom: ms(1);
  &.lead {
    font-size: ms(0) * 1.25;
    line-height: 1.6;
    margin-bottom: ms(1);
  }
  img.left,
  img {
    margin: ms(1);
    margin-left: 0;
  }
  img.right {
    margin: ms(1);
    margin-right: 0;
  }
}
aside p {
  font-size: ms(0) - 1;
  line-height: 1.35;
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: optimizeLegibility;
  line-height: 1.1;
  margin-bottom: ms(0);
  //margin-top: ms(0);
  small {
    font-size: 60%;
    color: #888;
    line-height: 0;
  }
}

h1 {
  font-size: ms(5);
}
h2 {
  font-size: ms(4);
}
h3 {
  font-size: ms(3);
}
h4 {
  font-size: ms(2);
}
h5 {
  font-size: ms(1);
}
h6 {
  font-size: ms(0);
}

hr {
  border: solid #ddd;
  border-width: 1px 0 0;
  clear: both;
  margin: 22px 0 21px;
  height: 0;
}

.subheader {
  line-height: 1.3;
  color: #777;
  font-weight: 300;
  margin-bottom: ms(1);
}

em,
i {
  font-style: italic;
  line-height: inherit;
}
strong,
b {
  font-weight: bold;
  line-height: inherit;
}
code {
  font-weight: bold;
  background: $highlightColor;
}

/* Lists ---------------------- */
ul,
ol {
  font-size: ms(0);
  line-height: 1.6;
  margin-bottom: ms(1);
  list-style-position: outside;
}
ul.square,
ul.circle,
ul.disc {
  margin-left: ms(1);
}
ul.square {
  list-style-type: square;
}
ul.circle {
  list-style-type: circle;
}
ul.disc {
  list-style-type: disc;
}
ul.large li {
  line-height: 21px;
}

/* Blockquotes ---------------------- */
blockquote,
blockquote p {
  line-height: 1.5;
  color: #777;
}
blockquote {
  margin: 0 0 ms(1);
  padding: 9px 20px 0 19px;
  border-left: 1px solid #ddd;
}
blockquote cite {
  display: block;
  font-size: ms(0) - 1;
  color: #555;
}
blockquote cite:before {
  content: '\2014 \0020';
}
blockquote cite a,
blockquote cite a:visited {
  color: #555;
}

abbr,
acronym {
  text-transform: uppercase;
  font-size: 90%;
  color: #222;
  border-bottom: 1px solid #ddd;
  cursor: help;
}
abbr {
  text-transform: none;
}

/*
 * Print styles.
 *
 * Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
 * Credit to Paul Irish and HTML5 Boilerplate (html5boilerplate.com)
*/
.print-only {
  display: none !important;
}
@media print {
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  } /* Black prints faster: h5bp.com/s */
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: ' (' attr(href) ')';
  }
  abbr[title]:after {
    content: ' (' attr(title) ')';
  }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  } /* Don't show links for images, or javascript/internal links */
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  } /* h5bp.com/t */
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .hide-on-print {
    display: none !important;
  }
  .print-only {
    display: block !important;
  }
}

* {
  @include box-sizing(border-box);
}
body {
  position: relative;
  -webkit-font-smoothing: antialiased;
}

/* Links ---------------------- */

a {
  text-decoration: none;
  line-height: inherit;
  @include link-colors(
    $mainColor,
    darken($mainColor, 10%),
    $mainColor,
    $mainColor,
    darken($mainColor, 10%)
  );
}
a:focus {
  outline: none;
}
p a,
p a:visited {
  line-height: inherit;
}

/* Misc ---------------------- */

.left {
  float: left;
}
.right {
  float: right;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.hide {
  display: none;
}
.highlight {
  background: $highlightColor;
}

#googlemap img,
object,
embed {
  max-width: none;
}

#map_canvas embed {
  max-width: none;
}
#map_canvas img {
  max-width: none;
}
#map_canvas object {
  max-width: none;
}
